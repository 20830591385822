import React, { useState } from 'react'
import { assets } from '../../../utils'

function FeedbackCards({feedback, setSelectMsg, selectMsg}) {
  return (
    <div
      className={`${(selectMsg === feedback)?'selected-card':''} p-3`}
    >
      <div className="d-flex flex-row">
        <img className={`image ${!feedback.user.image?'apply-border-img':''}`} src={feedback.user.image || assets.profileDefaultIcon} />
        <h6 className="ms-2">{feedback.user.name}</h6>
      </div>
      <h6 className="mail mt-0">{feedback.user.email}</h6>
      <h6 className="subject">{feedback.title}</h6>
      <p className="message mt-0">{feedback.message}</p>
      <div className="reply-container">
        {feedback?.reply?.length ?
          <img src={assets.repliedIcon} /> :
          null}
      <h6
        className="reply-text mb-3"
        onClick={() => {
          setSelectMsg(feedback)
        }}
      >
        Reply
      </h6>
      </div>
    </div>
  )
}
export default React.memo(FeedbackCards)
