import React, { useState } from "react"
import { Controller } from "react-hook-form"
import { Form, FormGroup, InputGroup } from "react-bootstrap"
import "./inputField.scss"

const InputField = ({
  label,
  labelClass,
  name,
  type,
  placeholder,
  disabled,
  defaultValue,
  className,
  rules,
  compType,
  control,
  trigger,
  onBlurHandler = () => {},
  onFocusHandler = () => {},
  ...moreProps
}) => {
  const [touched, setTouched] = useState(false)

  const getRender = formField => {
    return ({ field, fieldState: { invalid, isDirty, error, isTouched } }) => (
      <FormGroup
        className={`${
          formField?.className || ""
        } dv-default-input-container position-relative`}
      >
        <Form.Label className={labelClass || ""}>{formField.label}</Form.Label>
        <InputGroup>
          {formField?.prefix && (
            <InputGroup.Text className="dv-input-prefix">
              {formField?.prefix}
            </InputGroup.Text>
          )}
          {type === "date" ? (
            <>date</>
          ) : (
            <Form.Control
              className={`${invalid && isDirty ? "is-invalid" : ""} ${
                formField?.prefix ? "input-with-prefix" : ""
              } dv-form-input`}
              {...moreProps}
              {...field}
              onBlur={onBlurHandler}
              onChange={val => {
                field.onChange(val)
                formField?.onChange?.(val)
                if (!!val) {
                  moreProps?.onChange?.(val)
                }
                trigger(formField.name)
              }}
              onKeyUp={val => {
                if (!!val) {
                  moreProps?.onKeyup?.(val)
                }
              }}
              type={formField.type || "text"}
              placeholder={formField?.placeholder || ""}
              disabled={formField?.disabled || ""}
              onFocus={() => {
                setTouched(true)
                onFocusHandler()
              }}
              {...(!!moreProps?.value ? { value: moreProps?.value } : {})}
            />
          )}
        </InputGroup>
        {error && (isTouched || touched) && (
          <Form.Control.Feedback className="d-block" type="invalid">
            {error?.message}
          </Form.Control.Feedback>
        )}
      </FormGroup>
    )
  }

  return (
    <Controller
      key={name}
      defaultValue={defaultValue || ""}
      disabled={true || ""}
      name={name}
      control={control}
      rules={rules}
      render={getRender({
        compType,
        label,
        type,
        placeholder,
        className,
        disabled
      })}
    />
  )
}

export default InputField
