import React from "react"
import { Col, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import InputField from "./inputField"

export const SearchInput = ({ onChangeText, onBlurHandler=()=>{}, onFocusHandler=() =>{} }) => {
  const { control, trigger } = useForm()

  return (
    <Row>
      <Col>
        <InputField
          label=""
          name="searchText"
          placeholder="Search"
          control={control}
          trigger={trigger}
          onBlurHandler={onBlurHandler}
          onFocusHandler={onFocusHandler}
          className="applicationScreenInputAdmin searchbox"
          onChange={({ target: { value } }) => onChangeText(value)}
        />
      </Col>
    </Row>
  )
}
