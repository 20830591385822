import React, { useEffect } from "react"
import "./loader.scss"

const Loader = ({ isLoading, isCentered=true }) => {
  return (
    <div className={`${isLoading?'loader-body-fix':'display-none'}`}>
    <div
      className={`${isLoading ? "loader" : ""} ${isCentered ? "centered" : ""}`}
    ></div>
    </div>
  )
}
export default Loader
