import React, { useState } from 'react';
import { API } from '../../api';
import { useAuthContext } from '../AuthContext';

export default function Feedback() {
    const [feedbacks, setFeedbacks] = useState([])
    const [feedbackPagination, setFeedbackPagination] = useState({})
    const { setIsLoading } = useAuthContext();

    const getFeedbacks = (config, loadMoreUrl = null) => {
        setIsLoading(true)
        API.getFeedback(config, loadMoreUrl)
          .then(response => {
            const paginationObj = {
              next: response.data.payload.next
                ? response.data.payload.next.replace(new URL(response.data.payload.next).origin, "")
                : "",
              prev: response.data.payload.previous
                ? response.data.payload.previous.replace(
                    new URL(response.data.payload.previous).origin,
                    ""
                  )
                : "",
              count: response.data.payload.count
            }
            setFeedbackPagination(paginationObj)
            if(loadMoreUrl){
              setFeedbacks([...feedbacks,...response.data.payload.results])
            }else {
              setFeedbacks(response.data.payload.results)
            }
          })
          .catch(error => console.log(error))
          .finally(() => setIsLoading(false))
      }

      const sendMail = (id, payload) => {
        if (payload?.reply) {
          payload.reply = payload.reply
        }
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.sendMail(id, payload)
            .then(response => {
              resolve(response.data.data)
            })
            .catch(error => reject(error))
            .finally(() => setIsLoading(false))
        })
      }

    const feedbackContext = { feedbacks, getFeedbacks, feedbackPagination, sendMail }
    return feedbackContext;
}