import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './createProduct.scss';
import { assets } from '../../../../utils';
import Field from '../custom-field/field';
import { useAdminContext } from '../../../../contexts/AdminContext';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ALLROUTES } from '../../../../adminRoutes';
import CompatibleProducts from './compatibleProducts';

export default function CreateProduct() {
  const nav = useNavigate();
  const [categoryToggle, setCategoryToggle] = useState(false);
  const [detailsToggle, setDetailsToggle] = useState(false);
  const [productToggle, setProductToggle] = useState(false);
  const [compatibleProductsToggle, setCompatibleProductsToggle] = useState(false);
  const [compatibleProducts, setCompatibleProducts] = useState([]);
  const [categoryList, setCategoryList] = useState([])
  const [staticFields, setStaticFields] = useState({})
  const [variations, setVariations] = useState([])
  const [finalVariations, setFinalVariations] = useState([])
  const [savedProduct, setSavedProduct] = useState({})
  const [defaultVariation, setDefaultVariation] = useState({})

  const [selectedCategory, setSelectedCategory] = useState({})
  const [subCategoryList, setSubCategoryList] = useState([])
  const [selectedSubCategory, setSelectedSubCategory] = useState({})
  const [brandList, setBrandList] = useState([])
  const [attributeList, setAttributeList] = useState([]);
  const [showProfit, setShowProfit] = useState('');
  const [numberofVariants, setNumberofVariants] = useState([])
  const [submitErrorMessage, setSubmitErrorMessage] = useState('')
  const [disableCreateProduct, setDisableCreateProduct] = useState(false)
  const [productNoVariant, setProductNoVariant] = useState(false)

  const { fetchCategories, fetchAttributesById, fetchDDNValuesbyID, deleteProductVariation,
    getAllBrands, addNewCategory, createAProduct, getVaritionDDNValues, saveProductVariation } = useAdminContext();
  const [formFields, setFormFields] = useState();

  const masterMutationObject = useRef({});
  const profitRef = useRef('');
  const variationObjectRef = useRef({});
  const memoizedAttributeList = useMemo(() => { return attributeList }, [attributeList])
  const memoizedStaticFields = useMemo(() => { return staticFields }, [staticFields])
  let productValidations = {
    Brand: { isError: false, errorMsg: 'Required!', alias: 'brand' },
    Category: { isError: false, errorMsg: 'Required!', alias: 'category' },
    // subCategory:{errorMsg:'Required!'},
    Cost: { isError: false, errorMsg: 'Required!', alias: 'cost' },
    'Product Name': { isError: false, errorMsg: 'Required!', alias: 'name' },
    'Selling Price': { isError: false, errorMsg: 'Required!', alias: 'price' }
  }
  const [validations, setValidations] = useState(productValidations);
  const [showNotification, setShowNotification] = useState('')

  const ShowError = ({ errorMsg }) => <span>{errorMsg}</span>

  useEffect(() => {
    getCategoryList({});
  }, [])

  const getCategoryList = (config) => {
    fetchCategories(config).then(res => {
      setCategoryList(res)
    });
    setStaticFieldsInDetail();
  }

  const setStaticFieldsInDetail = () => {
    //call brand api
    const config = { params: { limit: 500 } }
    getAllBrands(config).then(res => {
      setBrandList(res);
    })

  }
  useEffect(() => {
    const staticFields = {
      top: [
        {
          id: 'brand',
          type: 'DDN',
          name: 'Brand',
          dropDownList: brandList,
          accessor: 'name',
          isApiLevelSearch:true
        },
        {
          id: 'productName',
          type: 'STR',
          name: 'Product Name',
          accessor: 'name',
          isApiLevelSearch:false
        },
        {
          id: 'textArea',
          type: 'TXTAREA',
          name: 'Description',
          accessor: 'name',
          isApiLevelSearch:false
        }
      ],
      bottom: [
        {
          id: 'costPrice',
          type: 'INT',
          name: 'Cost',
          accessor: 'name'
        },
        {
          id: 'sellingPrice',
          type: 'INT',
          name: 'Selling Price',
          accessor: 'name'
        }
      ]
    }
    setStaticFields(staticFields)
  }, [brandList])


  const createProductAPICall = (payload) => {
    createAProduct(payload).then(res => {
      console.log('create successfully', res)
      setSavedProduct(res);
      displayNotification('Product Created Successfully')
      setDisableCreateProduct(true)
      
    })
  }

  const displayNotification = (message) => {
    setShowNotification(message)
    setTimeout(() => {
      setShowNotification('')
    }, [3000])
  }

  useEffect(() => {
    if (variations.length) {
      // set Default variation 
      if(variations[0].variation.name==='Default'){
        getVaritionDDNValues({ params: { variation: variations[0].variation.id } }).then(res => {
          setDefaultVariation(res[0])
        })
      }
      let sortedVariations = variations?.sort((a, b) => a.ordering > b.ordering);
      let final = []
      sortedVariations = sortedVariations.map((variation) => {
        const current = variation;
        if (current?.classification) {
          //fetch dropdown values later
          current.classification = { ...current.classification, type: 'DDN', dropDownList: [], typeAs: 'classificationvalue', orderring: current.ordering }
          final.push(current.classification)
        }
        if (current?.variation && current.variation.id !== 8 && current.variation.name !== 'Default') {
          // fetch dropdown values later
          current.variation = { ...current.variation, type: 'DDN', dropDownList: [], typeAs: 'variationvalue', orderring: current.ordering }
          final.push(current.variation)
        }
        return current;
      })
      final = final.map(variant => {
        let obj = { ...variant };
        // Fixing the limit as all flavours needs to be fetched
        getVaritionDDNValues({ params: { variation: variant.id, limit: 500 } }).then(res => {
          obj.dropDownList = res;
        })
        return obj
      })
      let newVariant = { key: new Date().getTime(), list: final }
      setNumberofVariants(variants => [...variants, newVariant])
      setFinalVariations(final)
    } else {

      setFinalVariations([])
    }
  }, [variations])

  const fetchAttributes = (id) => {
    fetchAttributesById(id).then(res => {
      //clear previous variations
      setNumberofVariants([])
      let attributes = res.attribute;
      setVariations(res.variations);
      let newResp = attributes.map(attr => {
        let obj = { ...attr }
        if (attr.type === "DDN") {
          const config = {
            params: {
              attribute: attr.id
            }
          }
          fetchDDNValuesbyID(config).then(res => {
            obj.dropDownList = res.map(el => {
              const obj = { ...el };
              obj.name = obj.value
              return obj
            })
          })
        }
        return obj
      })
      setAttributeList(new Array(...newResp))
      setShowProfit('');
      masterMutationObject.current = {}
    })
  }

  const handleCategoryToggle = () => {
    setCategoryToggle((state) => !state)
  }
  const handleDetailsToggle = () => {
    setDetailsToggle((state) => !state)
  }
  const handleproductToggle = () => {
    setProductToggle((state) => !state)
  }
  const handleCompProducts = () => {
    setCompatibleProductsToggle((state) => !state)
  }

  //Main state maintain function
  const handleFieldValueChange = (name, e) => {
    const newValidations = { ...validations }
    for (const key of Object.keys(validations)) {
      newValidations[key].isError = false
    }
    setValidations(newValidations);
    setSubmitErrorMessage('')
    switch (name) {
      case 'Category':
        handleCategory(JSON.parse(e));
        setAttributeList([]);
        setSelectedSubCategory({})
        break;
      case 'Sub-Category':
        handleSubCategory(JSON.parse(e));
        break;
      default:
        console.log(e)
        handleDynamicfields(name, JSON.parse(e))

    }
  }
  const cachedHandleFieldValueChange = useCallback(handleFieldValueChange, [])

  const handleCategory = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
    if (selectedCategory.sub_category.length === 0) {
      fetchAttributes(selectedCategory.id);
    }
    setSubCategoryList(selectedCategory.sub_category);
  }

  const handleSubCategory = (selectedSubCategory) => {
    setSelectedSubCategory(selectedSubCategory);
    // fetch attributes
    fetchAttributes(selectedSubCategory.id);
    setDetailsToggle(false)
  }

  const handleDynamicfields = (name, field) => {
    // console.log('field', field);
    const newFormFieldVals = { ...masterMutationObject.current }
    newFormFieldVals[name] = field
    // setFormFields(newFormFieldVals)
    masterMutationObject.current = newFormFieldVals
    if (masterMutationObject.current['Cost']?.name && masterMutationObject.current['Selling Price']?.name) {
      getProfit();
    }
  }

  const getProfit = () => {
    const cp = Number(masterMutationObject.current['Cost'].name);
    const sp = Number(masterMutationObject.current['Selling Price'].name);
    const profit = (sp - cp).toFixed(2)
    // profitRef.current = profit;
    setShowProfit(profit)
  }

  const createNewProduct = () => {
    if (disableCreateProduct) {
      return
    }
    const formObj = masterMutationObject.current
    const exclusions = ['Product Name', 'Description', 'Brand', 'Cost', 'Selling Price'];
    const obj = {};
    const attributes = []
    obj.name = formObj['Product Name']?.name || ''
    obj.description = formObj['Description']?.name || ''
    obj.brand = formObj['Brand']?.id || ''
    obj.active = true
    obj.cost = formObj['Cost']?.name || ''
    obj.price = formObj['Selling Price']?.name || ''
    obj.compatible_products = compatibleProducts.map(product=>product.id)
    if (!selectedCategory.sub_category?.length) {
      obj.category = selectedCategory.id
    } else {
      obj.category = selectedSubCategory.id
    }
    const otherFields = Object.keys(formObj).filter(el => !exclusions.includes(el))
    otherFields.forEach(field => {
      const attr = attributeList.find(el => el.name === field) || {}
      if(formObj[field]?.name){
      attributes.push({ attribute: attr.id, value: formObj[field]?.name })
      }
    }
    )
    obj.attributes = attributes || [];
    if (obj.attributes.length === 0) {
      delete obj.attributes
    }
    if (validateFields(obj)) {
      createProductAPICall(obj);
    }
  }

  const validateFields = (obj) => {
    let newValidate = { ...validations };
    for (const [key, values] of Object.entries(validations)) {
      if (!obj[values.alias]) {
        newValidate[key].isError = true;
      }
    }
    const errorMessage = Object.values(newValidate).filter(el => el.isError).map(el => el.alias).join(', ');
    setSubmitErrorMessage(errorMessage);
    setValidations(newValidate)
    return !!!errorMessage.length
  }

  const handleAddVariant = () => {
    let obj = { key: new Date().getTime(), list: finalVariations }
    setNumberofVariants(number => [...number, obj])
  }

  const handleVariationChange = (name, key, e) => {
    const variationId = JSON.parse(e).variation;
    let existing = variationObjectRef.current?.[key] || {}
    // variationObjectRef.current[key] = {...existing, [variationId]:JSON.parse(e)}
    variationObjectRef.current = { ...variationObjectRef.current, [key]: { ...existing, [variationId]: JSON.parse(e) } }
    const newNumberOfVariants = numberofVariants.map(el => {
      const obj = { ...el }
      if (obj.key === key) {
        obj.list = obj.list.map(listItem => {
          const item = { ...listItem }
          if (item.hasOwnProperty('parent') && item?.id !== variationId && item?.parent?.id === variationId) {
            item['filteredDDN'] = item?.dropDownList?.filter(el => el['child_of'] === JSON.parse(e)?.id)
            item['child_of'] = JSON.parse(e)?.id
          }
          return item
        })
      }
      return obj
    })
    setNumberofVariants(newNumberOfVariants)
  }

  // No variation, but add image
  const saveProductNoVariantImage = () => {
    if (Object.keys(savedProduct).length === 0) {
      alert('Please create a Product first')
      return
    }
    const payloadFormData = new FormData();
    const productVariations = [{ classificationvalue: '', variationvalue: defaultVariation.id }]
    payloadFormData.append(`product`, (savedProduct.id).toString())
    payloadFormData.append(`productvariations`, JSON.stringify(productVariations))
    productNoVariant.files.forEach((image, idx) => {
      payloadFormData.append(`images`, image)
    })
    saveProductVariation(payloadFormData).then(res => {
      console.log('Image added Successfully')
      displayNotification('Image Added Successfully')
    })
  }

  const saveVariation = (key) => {
    if (Object.keys(savedProduct).length === 0) {
      alert('Please create a Product first')
      return
    }
    const master = numberofVariants.find(el => el.key === key)
    const actionRow = variationObjectRef.current[key]
    let groupedList = _.groupBy(master.list, 'orderring')
    const productVariations = [];
    Object.values(groupedList).forEach(el => {
      let final = {}
      el.forEach(variation => {
        final[variation.typeAs] = actionRow[variation.id].id || 0;
      })
      productVariations.push(final)
    })
    const payloadFormData = new FormData();
    payloadFormData.append(`product`, (savedProduct.id).toString())
    payloadFormData.append(`productvariations`, JSON.stringify(productVariations))
    master?.files?.forEach((image, idx) => {
      payloadFormData.append(`images`, image)
    })
    saveProductVariation(payloadFormData).then(res => {
      if (res.id) {
        displayNotification('Variation Saved Successfully')
        let variations = numberofVariants.map(variant => {
          const obj = { ...variant }
          if (obj.key === key) {
            obj.shouldDelete = true
            obj.isSaved = true
            obj.savedResponseId = res.id
          }
          return obj;
        });
        setNumberofVariants(variations)
      }
    })
  }

  const deleteVariant = (variantId, key) => {
    const classname = `variant-field-id-${key}`;
    //Delete selected variant
    deleteProductVariation(variantId).then(res => {
      // document.getElementsByClassName(classname)[0].style.display = 'none';
      let variations = numberofVariants.filter(variant => variant.key !== key);
      setNumberofVariants(variations)
    })
  }

  const addVariantImage = (e, key) => {
    let newVariants = numberofVariants.map(variant => {
      let obj = { ...variant }
      if (obj.key === key) {
        if (obj?.files?.length) {
          obj.files = [...obj.files, ...Object.values(e.target.files)]
        } else {
          obj.files = Object.values(e.target.files)
        }
        obj.imagePreview = obj.files.map(file => URL.createObjectURL(file))
      }
      return obj
    })
    setNumberofVariants(newVariants)
  }

  const addproductNoVariantImage = (e) => {
    const obj = { ...productNoVariant }
    if (obj?.files?.length) {
      obj.files = [...obj.files, ...Object.values(e.target.files)]
    } else {
      obj.files = Object.values(e.target.files)
    }
    obj.imagePreview = obj.files.map(file => URL.createObjectURL(file));
    setProductNoVariant(obj)
  }

  return (
    <div className='create-product-wrapper'>
      <div className='products-heading'>
        Products
      </div>
      {!!showNotification.length && <div className='notification-box'>{showNotification}</div>}
      <div className='main-panel'>
        <div className='left'>
          <div className='heading-section'>
            <span>Create a product based on category</span>
            <span>Please select an item to be able to see information</span>
          </div>
          <div className='field-section'>
            <div className='category'>
              <div className='category-header' onClick={handleCategoryToggle}>
                Category
                <img className={categoryToggle ? 'revert-image' : ''} src={assets.chevron} />
              </div>
              {categoryToggle && (<div className='fields'>
                <Field type={'DDN'} name="Category" dropDownList={categoryList}
                  isRequired={validations['Category']} accessor="name" handleChange={cachedHandleFieldValueChange}
                // showError={validations.category.isError}
                />
                <Field type={'DDN'} name="Sub-Category" dropDownList={subCategoryList}
                  accessor="name" isDisabled={!!!subCategoryList.length} handleChange={cachedHandleFieldValueChange} />
              </div>)}
            </div>
            {/* details section starts */}
            <div className='details'>
              <div className='details-header' onClick={handleDetailsToggle}>
                Details
                <img className={detailsToggle ? 'revert-image' : ''} src={assets.chevron} />
              </div>
              {detailsToggle && (<div className='fields'>
                {/* Top Static section */}
                {memoizedStaticFields?.top?.map(field => <Field key={field.id} type={field.type} name={field.name} dropDownList={field.dropDownList}
                  isRequired={validations[field.name]} accessor={field.accessor} handleChange={cachedHandleFieldValueChange} 
                  isApiLevelSearch={field.isApiLevelSearch} />)}
                {/* Middle dynamic section */}
                {memoizedAttributeList.map(attribute => <Field key={attribute.id} type={attribute.type} name={attribute.name} dropDownList={attribute.dropDownList}
                  accessor={attribute.accessor} handleChange={cachedHandleFieldValueChange} />)}

                {/* Bottom Static section */}
                <div className='cp-sp'>
                  {memoizedStaticFields?.bottom?.map(field => <Field key={field.id} type={field.type} name={field.name} dropDownList={field.dropDownList}
                    isRequired={validations[field.name]} accessor={field.accessor} handleChange={cachedHandleFieldValueChange} />)}
                </div>
                {/* total profit section */}
                {!!showProfit.length &&
                  <div className='profit-section'>
                    <span className='profit-heading'>Profit: &nbsp;</span><span className='profit-value'>${showProfit}</span>
                  </div>}
              </div>)}
            </div>

            {/* details section ends */}
            <div className='compatible-products'>
              <div className='compatible-products-header' onClick={handleCompProducts}>
                Compatible-Poducts
                <img className={compatibleProductsToggle ? 'revert-image' : ''} src={assets.chevron} />
              </div>
              {compatibleProductsToggle && (<div className='fields'>
                <CompatibleProducts actionType='create' handleCompatibleProducts={setCompatibleProducts} />
              </div>)}
            </div>
          </div>
          {!!submitErrorMessage.length && <span style={{ color: 'red' }}>{`${submitErrorMessage} are Required`}</span>}
          <div className='buttons'>
            <button className='cancel-btn' onClick={() => nav(ALLROUTES.dashboardChildren.products)}>Cancel</button>
            <button className='submit-btn' disabled={disableCreateProduct} onClick={createNewProduct}>Create product</button>
          </div>
        </div>
        <div className='right'>
          <div className='product'>
            <div className='product-header' onClick={handleproductToggle}>
              Product
              <img className={productToggle ? 'revert-image' : ''} src={assets.chevron} />
            </div>
            {(productToggle && !!finalVariations.length) && (
              <div>{
                numberofVariants.map(variant =>
                  <div key={variant.key}>
                    <div className={`variant-fields fields variant-field-id-${variant.key}`}>
                      <div className='variant-action'>
                        {<img src={assets.DeleteIcon} className={`${variant.shouldDelete ? '' : 'hidden-image'}`}
                          onClick={() => deleteVariant(variant.savedResponseId, variant.key)}></img>}
                        <img style={{minHeight:'22px',minWidth:'22px'}} className={`variant-save-btn ${!variant.isSaved ? '' : 'hidden-image'}`} onClick={() => saveVariation(variant.key)} src={assets.saveIcon}></img>
                      </div>
                      <div className='variant-image-wrapper'>
                        {variant.imagePreview?.length && <img src={variant.imagePreview[0]} className="preview-image-variant" />}
                        {/* {variant.imagePreview?.map(image=><img className="preview-image-variant" src={image}/>)} */}
                        <span className='add-image-wrapper'>
                          <img className="add-icon" src={assets.addImage} />
                          <input className="image-insert" disabled={variant.isSaved} onChange={(e) => addVariantImage(e, variant.key)} type="file" multiple />
                        </span>
                      </div>
                      {variant.list.map((field, index) => <Field key={index} uniqueKey={variant.key} feature={'variation-creation'} type={'DDN'} name={field.name} dropDownList={field.filteredDDN || field.dropDownList}
                        accessor="name" handleVariationChange={handleVariationChange} fullDetails={field} isApiLevelSearch={true} />)}
                    </div>
                    {/* other images */}
                    {variant.imagePreview?.length > 1 &&
                      <div className='other-variants-wrapper'>
                        {variant.imagePreview.slice(1,).map(image => <img className="preview-image-variant-other" src={image} />)}
                      </div>
                    }
                  </div>)}
              </div>
            )}
            {(productToggle && !!finalVariations.length) && <div className='add-variant'>
              <span onClick={handleAddVariant} className={numberofVariants.every(el => el.hasOwnProperty('shouldDelete')) ? '' : 'disabled-text'}>+ Add another variant</span>
            </div>}

            {/*Product has no Variation still we can add image  */}
            {(productToggle && !!!finalVariations.length) && <div className='add-variant'>
              <div className='variant-action no-variation-action'>
                <img className="variant-save-btn-no-variation" onClick={() => saveProductNoVariantImage()} src={assets.saveIcon}></img>
              </div>
              <div className='no-variant-left'>
                <div className='variant-image-wrapper'>
                  {productNoVariant.imagePreview?.length && <img src={productNoVariant.imagePreview[0]} className="preview-image-variant" />}
                  {/* {variant.imagePreview?.map(image=><img className="preview-image-variant" src={image}/>)} */}
                  <span className='add-image-wrapper'>
                    <img className="add-icon" src={assets.addImage} />
                    <input className="image-insert" onChange={(e) => addproductNoVariantImage(e)} type="file" multiple />
                  </span>
                </div>
                <div className='other-no-variant-images'>
                  {productNoVariant.imagePreview?.length > 1 &&
                    <div className='other-variants-wrapper'>
                      {productNoVariant.imagePreview.slice(1,).map(image => <img className="preview-image-variant-other" src={image} />)}
                    </div>
                  }
                </div>
              </div>
              <div className='no-variant-right'>
                <span>There are no variations, You can still add an image.</span>
              </div>
            </div>}
            {/* )} */}
          </div>
        </div>

      </div>
    </div>
  )
}
