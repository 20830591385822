import React, { useEffect, useState } from "react"
import { useNavigate, Link, useLocation } from "react-router-dom"
import Card from "../../../components/Admin/containers/card"
import { useForm } from "react-hook-form"
import { useAuthContext } from "../../../contexts/AuthContext"
import CenteredContainer, {
  OneHalf
} from "../../../components/Admin/containers/centered-container"
import InputField from "../../../components/Admin/inputField"
import {
  getPayLoadDataFunction,
  LocalStorage,
  validation
} from "../../../utils"
import "./forgotPasswordVerify.scss"
import { SubmitButton } from "../../../components/Admin/elements/form/submit-button"

const ForgotPasswordVerifyScreen = () => {
  const nav = useNavigate()
  const location = useLocation()
  const { isLoading, forgotPasswordVerify, forgotPassword } = useAuthContext()
  const { control, register, formState, trigger, handleSubmit } = useForm()
  const [email, setEmail] = useState("")
  const onSubmit = async vals => {
    const payload = {}
    payload.email = email
    payload.otp = vals.otp
    forgotPasswordVerify(payload).then(response => {
      nav("/admin/set-new-password", {
        state: response
      })
    })
  }

  useEffect(() => {
    setEmail(location.state?.email)
  }, [])
  return (
    <CenteredContainer screenHeight={true} className="gradient-bg">
      <Card whiteBg={false}>
        <h3 className="text-center">Token Verification</h3>
        <p className="text-center mb-1">
          Please enter the token verification code sent to
        </p>
        <p className="text-center mb-3" style={{ color: "#CB9F47" }}>
          {email}
        </p>
        <form className={"login"} onSubmit={handleSubmit(onSubmit)}>
          {/* <Row className="justify-content-center"> */}
          {/* <Col xs={12}> */}
          <InputField
            label=""
            name="otp"
            placeholder=""
            rules={{
              required: "OTP required!",
              validate: { ...validation.number }
            }}
            compType=""
            control={control}
            trigger={trigger}
            className="applicationScreenInputAdmin"
          />
          <p
            className="text-center mt-5 mb-2"
            style={{ color: "#CB9F47", cursor: "pointer" }}
            onClick={() => {
              forgotPassword({ email: email })
            }}
          >
            Resend code
          </p>
          <SubmitButton label="Verify" disabled={isLoading} />
        </form>
      </Card>
    </CenteredContainer>
  )
}

export default ForgotPasswordVerifyScreen
