import { useLocation, useNavigate, useRoutes, Navigate } from "react-router-dom"
import LoginScreen from "./pages/Admin/login"
import Dashboard from "./pages/Admin/dashboard"
import React, { useEffect, useLayoutEffect } from "react"
import ResetPasswordScreen from "./pages/Admin/resetPassword"
import ForgotPasswordVerifyScreen from "./pages/Admin/forgotPasswordVerify"
import SetNewPasswordScreen from "./pages/Admin/setNewPassword"
import Stores from "./pages/Admin/stores"
import Feedback from "./pages/Admin/feedback"
import Inventory from "./pages/Admin/inventory"
import ProtectedHOC from "./utils/ProtectedHOC"
import { useAuthContext } from "./contexts/AuthContext"
import Orders from "./pages/Admin/orders/orders"
import Analytics from "./pages/Admin/analytics/analytics"
import CreateProduct from "./pages/Admin/products/createProduct/createProduct"
import ManageItems from "./pages/Admin/manageItems/manageItems"
import EditProduct from "./pages/Admin/products/editProduct/editProduct"
import Deals from "./pages/Admin/deals/deals"
const DashboardRoutesContainer = React.lazy(()=>import("./components/Admin/containers/dashboard-container"))
const Products = React.lazy(() => import("./pages/Admin/products"))

const adminRoutePrefix = `/admin`
export const ALLROUTES = {
  default: "/",
  login: `${adminRoutePrefix}/login`,
  resetPassword: `${adminRoutePrefix}/reset-password`,
  forgotPasswordVerify: `${adminRoutePrefix}/forgot-password-verify`,
  setNewPassword: `${adminRoutePrefix}/set-new-password`,
  dashboardChildren: {
    dashboard: `${adminRoutePrefix}/dashboard/`,
    dashboardContainer: `${adminRoutePrefix}/dashboard`,
    analytics: `${adminRoutePrefix}/dashboard/analytics`,
    profile: `${adminRoutePrefix}/dashboard/profile`,
    profileChildren: {
      profileEdit: `${adminRoutePrefix}/dashboard/profile/profile-edit`
    },
    deals: `${adminRoutePrefix}/dashboard/deals`,
    orders: `${adminRoutePrefix}/dashboard/orders`,
    stores: `${adminRoutePrefix}/dashboard/stores`,
    feedback: `${adminRoutePrefix}/dashboard/feedback`,
    products: `${adminRoutePrefix}/dashboard/products`,
    productsChildren: {
      createProduct: `${adminRoutePrefix}/dashboard/products/create-product`,
      editProduct: `${adminRoutePrefix}/dashboard/products/edit-product`
    },
    inventory: `${adminRoutePrefix}/dashboard/inventory`,
    manageItems: `${adminRoutePrefix}/dashboard/manage-items`
  }
}

const loggedOutRoutes = [
  "/admin/login",
  "/admin/reset-password",
  "/admin/forgot-password-verify",
  "/admin/set-new-password"
]

const adminRoutes = [
  // default route
  { path: ALLROUTES.default, element: <Navigate to={ALLROUTES.login} /> },
  // onboarding routes
  { path: ALLROUTES.login, element: <LoginScreen /> },
  { path: ALLROUTES.resetPassword, element: <ResetPasswordScreen /> },
  {
    path: ALLROUTES.forgotPasswordVerify,
    element: <ForgotPasswordVerifyScreen />
  },
  {
    path: ALLROUTES.setNewPassword,
    element: <SetNewPasswordScreen />
  },
  // dashboard routes
  {
    path: ALLROUTES.dashboardContainer,
    element: (
      <ProtectedHOC>
        <DashboardRoutesContainer />
      </ProtectedHOC>
    ),
    children: [
      {
        path: ALLROUTES.dashboardChildren.dashboard,
        index: true,
        element: <Dashboard />
      },
      {
        path: ALLROUTES.dashboardChildren.analytics,
        index: true,
        element: <Analytics />
      },
      {
        path: ALLROUTES.dashboardChildren.stores,
        element: <Stores />
      },
      {
        path: ALLROUTES.dashboardChildren.feedback,
        element: <Feedback />
      },
      {
        path: ALLROUTES.dashboardChildren.products,
        // element: <Products />,
        children: [
          {
            path: ALLROUTES.dashboardChildren.products,
            index: true,
            element: <Products />
          },
          {
            path: ALLROUTES.dashboardChildren.productsChildren.createProduct,
            element: <CreateProduct />
          },
          {
            path: `${ALLROUTES.dashboardChildren.productsChildren.editProduct}/:productId`,
            element: <EditProduct />
          }
        ]
      },
      {
        path: ALLROUTES.dashboardChildren.deals,
        element: <Deals />
      },
      {
        path: ALLROUTES.dashboardChildren.inventory,
        element: <Inventory />
      },
      {
        path: ALLROUTES.dashboardChildren.orders,
        element: <Orders />
      },
      {
        path: "*",
        element: <Navigate to={ALLROUTES.dashboardChildren.dashboard} />
      },
      {
        path: ALLROUTES.dashboardChildren.manageItems,
        element: <ManageItems />
      }
    ]
  }
]

const AdminRoutes = () => useRoutes(adminRoutes)

export default AdminRoutes
