import React from "react"
import { Button, Form } from "react-bootstrap"
import { assets } from "../../../utils"
import { UserIcon } from "../../../icon-components/nav-bar-icons"
import "../dashboard/dashboard"

export const ActionsColumn = ({
  record,
  onClickActiveInactiveUser,
  onClickEditUser,
  onClickDeleteUser,
  showStoreAdmins = false,
  isFlagOption,
  isEditOption,
  isSaveOption,
  Savequantity,
  isDeleteOption
}) => {
  const { id, flag } = record
  return (
    <div className="actions">
      {isFlagOption && (
        <img
          src={flag ? assets.FlagFilledIcon : assets.FlagIcon}
          onClick={() =>
            onClickActiveInactiveUser(id, { flag: !flag })
          }
        />
      )}

      {/* {!!showStoreAdmins && (
        <span className="img" onClick={() => onClickEditUser(record)}>
          {record?.storeadmins?.length || 0}
          <UserIcon />
        </span>
      )} */}

      {isEditOption && (
        <img
          style={{ width: "16px", height: "12px" }}
          src={assets.EditIcon}
          onClick={() => onClickEditUser(record)}
        />
      )}
      {isSaveOption && (
        <>
          <img
            className="restock-btn"
            src={assets.restock}
            onClick={Savequantity}
            style={{marginRight:'20px'}}
          />
        </>
      )}
      {isDeleteOption && (
        <>
          <img
           className="delete-product-btn"
            src={assets.DeleteIcon}
            onClick={() => onClickDeleteUser(record)}
          />
        </>
      )}
    </div>
  )
}
