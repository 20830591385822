export const cols = [
    {
        accessor: "category",
        Header: "Category"
    },
    {
        accessor: "brand",
        Header: "Brand",
        Cell:({value})=><div className="order-detail-brand">
            <span className="brand-name">{value.brand}</span>
        </div>
    },
    {
        accessor: "product_name",
        Header: "Product Name"
    },
    {
        accessor: "details",
        Header: "Details"
    },
    {
        accessor: "price",
        Header: "Price",
        Cell: ({ value }) => `$${Number(value.toFixed(2))}`
    },
    {
        accessor: "quantity",
        Header: "Quantity"
    },
    {
        accessor: "discount",
        Header: "Discount",
        Cell: ({ value }) => `$${value}`
    },
    {
        accessor: "total",
        Header: "Total",
        Cell: ({ value }) => `$${value}`
    },
];