import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { useAdminContext } from '../../../../contexts/AdminContext';
import './field.scss';

function Field({ type, dropDownList, name, uniqueKey, feature, value, handleChange,
    handleVariationChange, isDisabled, accessor, isRequired, preFilledValue, fullDetails,
    isApiLevelSearch }) {
    const fullRowFields = ['Description']
    const [selectedDropdownValue, setSelectedDropdownValue] = useState('Select');
    const [searchConfig, setSearchConfig] = useState({});
    const { getVaritionDDNValues, getAllBrands } = useAdminContext();
    let debounceTimer;
    const refer = useRef('')

    useEffect(() => {
        setSelectedDropdownValue('Select')
    }, [dropDownList]);

    useEffect(() => {
        if (preFilledValue) {
            setSelectedDropdownValue(preFilledValue)
        }
    }, [preFilledValue])
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');
            const [filteredList, setFilteredList] = useState([]);

            const onChangeTextDebounce = (text) => {
                clearTimeout(debounceTimer)
                debounceTimer = setTimeout(() => onChangeText(text), 500)
            }

            const onChangeText = (text) => {
                let config = { ...searchConfig };
                config.params.search = text;
                if (name === 'Brand') {
                    // sending false as second parameter to not show loader
                    getAllBrands({params:{search:text}},false).then(resp=>{
                        setFilteredList(resp)
                    })
                } else {
                    getVaritionDDNValues(config).then(resp => {
                        setFilteredList(resp)
                    })
                }
            }
            return (
                <div
                    ref={ref}
                    style={style}
                    className={'custom-search-ddn'}
                    aria-labelledby={labeledBy}
                >
                    <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder={`Search/Add ${name}`}
                        onChange={(e) => {
                            setValue(e.target.value);
                            if (isApiLevelSearch) {
                                onChangeTextDebounce(e.target.value)
                            }
                        }}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {!!isApiLevelSearch ? <>
                            {!!filteredList.length ? <>{filteredList?.map((listItem, idx) => {
                                return (
                                    <div className='custom-menu-ddn'
                                        onClick={() => {
                                            setSelectedDropdownValue(listItem.name || listItem.value);
                                            handledropDownValChange(name, listItem)
                                        }}
                                        key={`${listItem.name}-${idx}` || `${listItem.value}-${idx}`}
                                    >
                                        {listItem.name || listItem.value}
                                    </div>
                                )
                            })}</> :
                                <>
                                    {dropDownList?.map((listItem, idx) => {
                                        return (
                                            <div className='custom-menu-ddn'
                                                onClick={() => {
                                                    setSelectedDropdownValue(listItem.name || listItem.value);
                                                    handledropDownValChange(name, listItem)
                                                }}
                                                key={`${listItem.name}-${idx}` || `${listItem.value}-${idx}`}
                                            >
                                                {listItem.name || listItem.value}
                                            </div>
                                        )
                                    })}</>
                            }
                        </> :
                            <>{dropDownList?.filter(el => el.name?.toLowerCase().startsWith(value.toLowerCase()) || el.value?.toLowerCase().startsWith(value.toLowerCase()))?.map((listItem, idx) => {
                                return (
                                    <div className='custom-menu-ddn'
                                        onClick={() => {
                                            setSelectedDropdownValue(listItem.name || listItem.value);
                                            handledropDownValChange(name, listItem)
                                        }}
                                        key={`${listItem.name}-${idx}` || `${listItem.value}-${idx}`}
                                    >
                                        {listItem.name || listItem.value}
                                    </div>
                                )
                            })}</>
                        }
                    </ul>
                </div>
            );
        },
    );

    const handledropDownValChange = (name, e) => {
        if (feature === 'variation-creation') {
            handleVariationChange(name, uniqueKey, JSON.stringify(e))
            return
        }
        handleChange(name, JSON.stringify(e))
    }


    const InputField = ({ refer }) => {
        switch (type) {
            case 'STR':
                return <TextField refer={refer} />;
            case 'DDN':
                return <DropDownField />;
            case 'INT':
                return <NumberField refer={refer} />;
            case 'TXTAREA':
                return <TextArea refer={refer} />

        }
    }

    useEffect(() => {
        const child_of = fullDetails?.child_of
        const variationId = fullDetails?.id
        const config = {
            params: {
                child_of: child_of || '',
                variation: variationId
            }
        }
        setSearchConfig(config)

    }, [fullDetails])

    const TextField = ({ refer }) => {
        return <div>
            <input type='text' disabled={isDisabled}
                defaultValue={refer.current || preFilledValue}
                onBlur={(e) => {
                    handleChange(name, JSON.stringify({ name: e.target.value }))
                    refer.current = e.target.value
                }}
            />
        </div>
    };

    const NumberField = ({ refer }) => <div className='number-field'>
        {(name === 'Cost' || name === 'Selling Price') && <p className='dollar-sign'>$</p>}
        <input type='number' disabled={isDisabled} defaultValue={refer.current || preFilledValue}
            onBlur={(e) => {
                handleChange(name, JSON.stringify({ name: e.target.value }))
                refer.current = e.target.value
            }} />
    </div>;

    const TextArea = ({ refer }) => <div>
        <textarea type='text' placeholder='Write the product description' disabled={isDisabled}
            defaultValue={refer.current || preFilledValue} onBlur={(e) => {
                handleChange(name, JSON.stringify({ name: e.target.value }))
                refer.current = e.target.value
            }} />
    </div>

    const DropDownField = () => <div>
        <DropdownButton
            disabled={isDisabled}
            // onSelect={(e) => {
            //     setSelectedDropdownValue(JSON.parse(e).name || JSON.parse(e).value);
            //     handledropDownValChange(name, e)
            // }}
            title={selectedDropdownValue}
        >
            <Dropdown.Menu as={CustomMenu}>
                {dropDownList?.map((listItem, idx) => {
                    return (
                        <div className='custom-menu-ddn'
                            onClick={() => {
                                setSelectedDropdownValue(listItem.name || listItem.value);
                                handledropDownValChange(name, listItem)
                            }}
                            key={`${listItem.name}-${idx}` || `${listItem.value}-${idx}`}
                        // eventKey={JSON.stringify(listItem)}
                        >
                            {listItem.name || listItem.value}
                        </div>
                    )
                })}
            </Dropdown.Menu>
        </DropdownButton>
    </div>;
    return (
        <div className={`field-entity ${fullRowFields.includes(name) ? 'full-row-field' : ''}`}>
            <span className={`field-label`}>{name}
                {!!isRequired?.errorMsg?.length && <span className='error-asterisk'>*</span>}
            </span>
            <InputField refer={refer} />
        </div>
    )
}

export default React.memo(Field);
