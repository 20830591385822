import React, { useState } from 'react';
import { API } from '../../api';
import { useAuthContext } from '../AuthContext';
import { setCountryCode } from '../../utils';

export default function Stores() {
    const { setIsLoading } = useAuthContext();
    
    const addStore = (vals, imageData, location) => {
        const { center } = location
        const position = { latitude: center[1], longitude: center[0] }
    
        const formData = new FormData()
        const { address, phone_number, name, email, review_url, sales_tax_percent, nicotine_tax_percent } = vals
        formData.append("logo", imageData)
        formData.append("address", location.place_name)
        formData.append("phone_number", setCountryCode(phone_number))
        formData.append("name", name)
        formData.append("email", email)
        formData.append("sales_tax_percent", sales_tax_percent)
        formData.append("nicotine_tax_percent", nicotine_tax_percent)
        formData.append("lat", center[1].toFixed(4))
        formData.append("lng", center[0].toFixed(4))
        if (review_url) {
          formData.append("review_url", review_url)
        }
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.addStore(formData)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error))
            .finally(() => setIsLoading(false))
        })
      }

      const editStore = (vals, imageData, location) => {
        const { center } = location
        const position = { latitude: center[1], longitude: center[0] }
        const formData = new FormData()
        const { address, phone_number, name, email, review_url, nicotine_tax_percent, sales_tax_percent } = vals
        if (File.prototype.isPrototypeOf(imageData)) {
          formData.append("logo", imageData)
        }
    
        formData.append("address", location.place_name)
        formData.append("phone_number", setCountryCode(phone_number))
        formData.append("name", name)
        formData.append("email", email)
        formData.append("sales_tax_percent", sales_tax_percent)
        formData.append("nicotine_tax_percent", nicotine_tax_percent)
        formData.append("lat", center[1].toFixed(4))
        formData.append("lng", center[0].toFixed(4))
        if (review_url) {
          formData.append("review_url", review_url)
        }
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.editStore(vals.id, formData)
            .then(response => {
              resolve(response.data.data)
            })
            .catch(error => reject(error))
            .finally(() => setIsLoading(false))
        })
      }

      const deleteStore = id => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.deleteStore(id)
            .then(response => {
              resolve(response.data.data)
            })
            .catch(error => console.log(error))
            .finally(() => setIsLoading(false))
        })
      }
      const addStoreAdminToStore = payload => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.addStoreAdminToStore(payload)
            .then(response => {
              resolve(response.data.payload)
            })
            .catch(error => console.log(error))
            .finally(() => setIsLoading(false))
        })
      }
      
      const deleteStoreAdminFromStore = id => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.deleteStoreAdminFromStore(id)
            .then(response => {
              resolve(response)
            })
            .catch(error => console.log(error))
            .finally(() => setIsLoading(false))
        })
      }
      const setStoreAdminNotifications = id => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.setStoreAdminNotifications(id)
            .then(response => {
              resolve(response)
            })
            .catch(error => console.log(error))
            .finally(() => setIsLoading(false))
        })
      }

    const storesContext = { addStore, editStore, deleteStore, addStoreAdminToStore, deleteStoreAdminFromStore,
      setStoreAdminNotifications }
    return storesContext;
}