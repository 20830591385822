import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import PropTypes from "prop-types"
import "./centered-container.scss"

export const OneHalf = props => {
  return (
    <Col
      className={`half d-flex flex-column align-items-${props.alignItems || 'center'} justify-content-${props.justifyContent || 'center'} ${
        props.className || ""
      }`}
      md="auto"
    >
      {props.children}
    </Col>
  )
}

OneHalf.propTypes = {
  style: PropTypes.any,
  className: PropTypes.string
}

export const OneWhole = props => {
  return (
    <Col
      className={`whole d-flex flex-column align-items-center justify-content-center ${
        props.className || ""
      }`}
      md="auto"
    >
      {props.children}
    </Col>
  )
}

OneWhole.propTypes = {
  style: PropTypes.any,
  className: PropTypes.string
}

const CenteredContainer = props => {
  return (
    <Container
      className={`centered-container vw-100${
        props.screenHeight ? " min-vh-100" : " h-100"
      } ${props.className || ""}`}
      fluid
    >
      <Row
        className={`d-flex flex-column flex-nowrap align-items-center justify-content-center ${
          props.screenHeight ? "min-vh-100" : "h-100"
        }`}
      >
        {props.children}
      </Row>
    </Container>
  )
}

CenteredContainer.propTypes = {
  style: PropTypes.any,
  className: PropTypes.string,
  screenHeight: PropTypes.bool
}

export default CenteredContainer
