import React, { useState, useEffect } from "react"
import { API } from "../../api"
import { useAuthContext } from "../AuthContext"
import {
  MILLIGRAM_OPTIONS,
  NICOTINE_OPTIONS,
  PUFF_COUNT_OPTIONS,
  SORT_BY_OPTIONS,
  formatDateForDB,
  setCountryCode,
  sortRecord,
  userRoleProfiles
} from "../../utils"

export default function Products() {
  const [products, setProducts] = useState({ results: [], next: null })
  const [brands, setBrands] = useState([])
  const [flavourTypes, setFlavourTypes] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [flavours, setFlavours] = useState([])
  const [flavoursData, setFlavoursData] = useState([])
  const [milligram, setMilligram] = useState([])
  const [productId, setProductId] = useState(0)
  const [nicotinestrength, setNicotinestrength] = useState([])
  const [sortByOptions, setSortByOptions] = useState([])
  const [filterParams, setFilterParams] = useState({ all: true })
  const { setIsLoading } = useAuthContext()
  const [variations, setVariations] = useState([])
  const [variationValues, setVariationValues] = useState([])
  const [attributes, setAtrributes] = useState([])
  const [attributeValues, setAtrributeValues] = useState([])

  useEffect(() => {
    const config = {}
    config.params = {}
    const flavourtype = flavourTypes.find(({ checked }) => checked)?.label
    if (flavourtype) {
      config.params.flavourtype__name = flavourtype
      getFlavours(config)
    }
  }, [flavourTypes])

  useEffect(() => {
    setSortByOptions(SORT_BY_OPTIONS)
  }, [])

  const getProducts = (config, successCallback) => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    if (currentUser.role === userRoleProfiles.STOREADMIN) {
      setIsLoading(true)
      API.getProductsListingStoreAdmin(config)
        .then(response => {
          successCallback?.(response.data.payload.results)
          if (config?.params?.offset == 0) {
            return setProducts(response.data.payload)
          } else {
            const update = {
              ...response.data.payload,
              results: [...products.results, ...response.data.payload.results]
            }
            setProducts(update)
          }
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    } else if (currentUser.role === userRoleProfiles.SUPERADMIN) {
      setIsLoading(true)
      API.getProductsListingAdmin(config)
        .then(response => {
          successCallback?.(response.data.payload.results)
          if (config?.params?.offset == 0) {
            return setProducts(response.data.payload)
          } else {
            const update = {
              ...response.data.payload,
              results: [...products.results, ...response.data.payload.results]
            }
            setProducts(update)
          }
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    }
  }
  const getProductsStoreAdmin = (config, successCallback) => {
    setIsLoading(true)
    API.getProductsListingStoreAdmin(config)
      .then(response => {
        successCallback?.(response.data.payload.results)
        if (config?.params?.offset == 0) {
          return setProducts(response.data.payload)
        } else {
          const update = {
            ...response.data.payload,
            results: [...products.results, ...response.data.payload.results]
          }
          setProducts(update)
        }
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getBrands = config => {
    setIsLoading(true)
    API.getBrands(config)
      .then(response =>
        // pagination TBD
        setBrands(
          response.data.payload?.results?.map(({ name, id }) => ({
            label: name,
            value: id,
            checked: false
          }))
        )
      )
      .catch(error => console.log(error))
      .finally(() => {
        setIsLoading(false)
      })
  }
  const getFlavours = config => {
    API.getFlavours(config)
      .then(response => {
        setFlavoursData(response.data.payload)
        // pagination TBD
        setFlavours(
          response.data.payload.results.map(({ name, id }) => ({
            label: name,
            value: id,
            checked: false
          }))
        )
      })
      .catch(error => console.log(error))
      .finally(() => { })
  }

  const getFlavourTypes = () => {
    API.getFlavourTypes()
      .then(response =>
        // pagination TBD
        setFlavourTypes(
          response.data.payload.results.map(({ name, id }) => ({
            label: name,
            value: id,
            checked: false
          }))
        )
      )
      .catch(error => console.log(error))
      .finally(() => { })
  }

  const getCategories = config => {
    API.getCategories(config)
      .then(response =>
        // pagination TBD
        setCategories(
          response.data.payload.results.map(({ name, id, sub_category, ...rest }) => ({
            ...rest,
            label: name,
            value: id,
            checked: false,
            sub_category: sub_category.map(({ name, id }) => ({
              label: name,
              value: id,
              checked: false
            }))
          }))
        )
      )
      .catch(error => console.log(error))
      .finally(() => { })
  }

  const addProducts = (vals, totalPrice) => {
    const payload = { ...vals }
    const {
      category,
      name,
      description,
      puffcount,
      price,
      sales_tax,
      nicotine_tax,
      brand,
      milligram
    } = vals
    payload.category = category.value
    payload.brand = brand.value
    payload.name = name
    payload.description = description
    payload.puffcount = puffcount == "" ? null : puffcount
    // payload.milligram = milligram
    payload.price = price
    payload.sales_tax = sales_tax
    payload.nicotine_tax = nicotine_tax
    if (payload?.milligram) {
      payload.milligram = milligram.value
    }
    payload.milligram = milligram && milligram?.value
    payload.total_price = totalPrice
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addProducts(payload)
        .then(response => {
          setProductId(response.data.id)
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addProductStock = vals => {
    const data = vals
    const formData = new FormData()
    data.forEach((item, i) => {
      formData.append(`[${i}]product`, item.product)
      formData.append(`[${i}]flavour`, item.flavour)
      if (!!item?.nicotine) {
        formData.append(`[${i}]nicotine`, item.nicotine)
      }

      const { images } = data[i]
      const arr = images.map((image, j) => {
        formData.append(`[${i}]images[${j}]image`, image.image)
      })
    })
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addProductStock(formData)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const patchProductSku = (skuId, vals) => {
    const data = vals
    const formData = new FormData()
    data.forEach((item, i) => {
      formData.append(`[${i}]product`, skuId)

      const { images } = data[i]
      const arr = images.map((image, j) => {
        formData.append(`[${i}]images[${j}]image`, image.image)
      })
    })
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.patchProductSku(skuId, formData)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deleteProductSku = productId => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteProductSku(productId)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getMilligrams = () => {
    API.getMilligrams()
      .then(response => {
        // pagination TBD
        setMilligram(
          response.data.payload.results
            .sort(sortRecord("gram"))
            .map(({ gram, id }) => ({
              label: gram,
              value: id,
              checked: false
            }))
        )
      })
      .catch(error => console.log(error))
      .finally(() => { })
  }

  const getNicotinestrength = () => {
    API.getNicotinestrength()
      .then(response => {
        // pagination TBD
        setNicotinestrength(
          response.data.payload.results
            .sort(sortRecord("nicotine"))
            .map(({ nicotine, id }) => ({
              label: nicotine,
              value: id,
              checked: false
            }))
        )
      })
      .catch(error => console.log(error))
      .finally(() => { })
  }

  const editAProduct = (id, payload) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.editProduct(id, payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deleteProduct = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteProduct(id)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addToStore = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addToStore(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const onChangeBrand = (obj) => {
    let newOptions = brands.map(item => ({
      ...item
    }))
    newOptions = newOptions.map((el) => {
      let option = { ...el }
      if (obj.hasOwnProperty(el.label)) {
        option.checked = obj[el.label]
      }
      return option
    })
    // const index = newOptions.findIndex(({ label }) => label === value)
    // newOptions[index].checked = isChecked
    setBrands(newOptions)
  }

  const onResetBrand = () => {
    setBrands(prevState => prevState.map(item => ({ ...item, checked: false })))
    const newFilterParams = { ...filterParams }
    delete newFilterParams.brand
    setFilterParams(newFilterParams)
  }

  const onChangeFlavourType = value => {
    // Reset Selected Flavors
    // Remove selected flavours from filterParams
    const newOptions = flavourTypes.map(item => ({
      ...item,
      checked: false
    }))
    const index = newOptions.findIndex(({ label }) => label === value)
    newOptions[index].checked = true
    setFlavourTypes(newOptions)
  }

  const onChangeFlavour = value => {
    const newOptions = [...flavours]
    const index = newOptions.findIndex(({ label }) => label === value)
    newOptions[index].checked = !newOptions[index].checked
    setFlavours(newOptions)
  }

  const onResetFlavour = () => {
    setFlavourTypes(prevState =>
      prevState.map(item => ({ ...item, checked: false }))
    )
    getFlavours()
    const newFilterParams = { ...filterParams }
    delete newFilterParams.flavour
    setFilterParams(newFilterParams)
  }

  const onChangeSortBy = value => {
    const newOptions = sortByOptions.map(item => ({ ...item, checked: false }))
    const index = newOptions.findIndex(({ label }) => label === value)
    newOptions[index].checked = true
    setSortByOptions(newOptions)
  }

  const onResetSortBy = () => {
    setSortByOptions(prevState =>
      prevState.map(item => ({ ...item, checked: false }))
    )
    const newFilterParams = { ...filterParams }
    delete newFilterParams.ordering
    setFilterParams(newFilterParams)
  }

  const onChangeMilligram = value => {
    const newOptions = milligram.map(item => ({
      ...item,
      checked: false
    }))
    const index = newOptions.findIndex(({ label }) => label === value)
    newOptions[index].checked = true
    setMilligram(newOptions)
  }

  const onResetMilligram = () => {
    setMilligram(prevState =>
      prevState.map(item => ({ ...item, checked: false }))
    )
    const newFilterParams = { ...filterParams }
    delete newFilterParams?.mg
    delete newFilterParams?.mg_min
    delete newFilterParams?.mg_max
    setFilterParams(newFilterParams)
  }

  const onChangeNicotine = value => {
    const newOptions = nicotinestrength.map(item => ({
      ...item,
      checked: false
    }))
    const index = newOptions.findIndex(({ label }) => label === value)
    newOptions[index].checked = true
    setNicotinestrength(newOptions)
  }

  const onChangeCategory = value => {
    const newOptions = categories.map(item => ({
      ...item,
      checked: false
    }))
    const index = newOptions.findIndex(({ label }) => label === value)
    newOptions[index].checked = true
    setCategories(newOptions)
  }

  const onResetCategory = () => {
    setCategories(prevState =>
      prevState.map(item => ({ ...item, checked: false }))
    )
    const newFilterParams = { ...filterParams }
    delete newFilterParams?.category
    setFilterParams(newFilterParams)
  }

  const onResetNicotine = () => {
    setNicotinestrength(prevState =>
      prevState.map(item => ({ ...item, checked: false }))
    )
    const newFilterParams = { ...filterParams }
    delete newFilterParams.nicotine
    setFilterParams(newFilterParams)
  }

  //Manage list feature

  const getBrand = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getBrand(id)
        .then(response => resolve(response.data.payload))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deleteBrand = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteBrand(id)
        .then(response => resolve(response.data.payload))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const updateBrand = (id, payload) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.updateBrand(id, payload)
        .then(response => resolve(response.data.payload))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deleteCategory = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteCategory(id)
        .then(response => resolve(response.data.payload))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getSubCategories = config => {
    API.getCategories(config)
      .then(response => {
        const { results } = response.data.payload
        const subCategories = results.reduce((sub_categories, category) => {
          category.sub_category.forEach(item => {
            sub_categories.push(item)
          })
          return sub_categories
        }, [])

        setCategories(
          results.map(({ name, id }) => ({
            label: name,
            value: id,
            checked: false
          }))
        )
        setSubCategories(
          subCategories.map(({ id, name, parent }) => ({
            label: name,
            value: id,
            parent: parent
          }))
        )
      })
      .catch(error => console.log(error))
      .finally(() => { })
  }

  const getVariation = config => {
    setIsLoading(true)
    // remove Default variation (id=8 & name=Default)
    API.getVariation(config)
      .then(response =>
        setVariations(
          response.data.payload.results.filter(el=>(el.name!=='Default' && el.id!==8)).map(
            ({ id, name, is_nested_variation, parent }) => ({
              label: name,
              value: id,
              is_nested_variation,
              parent,
              checked: false
            })
          )
        )
      )
      .catch(error => console.log(error))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getVariationValue = config => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getVariationValue(config)
        .then(response =>
          setVariationValues(
            response.data.payload.results.map(
              ({ child_of, id, value, variation }) => ({
                label: value,
                value: id,
                child_of,
                variation
              })
            )
          )
        )
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getVariationValueByParentId = config => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getVariationValue(config)
        .then(response =>
          resolve(
            response.data.payload.results.map(
              ({ child_of, id, value, variation }) => ({
                label: value,
                value: id,
                child_of,
                variation
              })
            )
          )
        )
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getVariationValueById = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getVariationValueById(id)
        .then(response => resolve(response.data.payload))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addVariationValue = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addVariationValue(payload)
        .then(response => resolve(response.data.payload))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const updateVariationValue = (id, payload) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.updateVariationValue(id, payload)
        .then(response => resolve(response.data.payload))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deleteVariationValue = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteVariationValue(id)
        .then(response => resolve(response.data.payload))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getAtrribute = () => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getAtrribute()
        .then(response =>
          setAtrributes(
            response.data.payload.results.map(({ id, name }) => ({
              label: name,
              value: id,
              checked: false,
              variationType: "attribute"
            }))
          )
        )
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getAtrributeValue = config => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getAtrributeValue(config)
        .then(response =>
          setAtrributeValues(
            response.data.payload.results.map(({ id, value }) => ({
              label: value,
              value: id
            }))
          )
        )
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getAtrributeValueById = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getAtrributeValueById(id)
        .then(response => resolve(response.data.payload))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addAttributeValue = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addAttributeValue(payload)
        .then(response => resolve(response.data.payload))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const updateAttributeValue = (id, payload) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.updateAttributeValue(id, payload)
        .then(response => resolve(response.data.payload))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deleteAttributeValue = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteAttributeValue(id)
        .then(response => resolve(response.data.payload))
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  // New Product creation

  // fetch all categories
  const fetchCategories = config => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getAllCategories(config)
        .then(response => {
          resolve(response.data.payload.results)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }
  // fetch attributes by ID
  const fetchAttributesById = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.fetchAttributesById(id)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  // fetch DDN values for attributes
  const fetchDDNValuesbyID = config => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.fetchDDNValuesbyID(config)
        .then(response => {
          resolve(response.data.payload.results)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getAllBrands = (config,showLoader=true) => {
    if(showLoader){
      setIsLoading(true)
    }
    return new Promise((resolve, reject) => {
      API.getAllBrands(config)
        .then(response => {
          resolve(response.data.payload.results)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  // Add a new Category
  const addNewCategory = config => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getAllBrands(config)
        .then(response => {
          resolve(response.data.payload.results)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  // create a new product
  const createAProduct = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.createProduct(payload)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getVaritionDDNValues = config => {
    // setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.variationValuesforDDN(config)
        .then(response => {
          resolve(response.data.payload.results)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const saveProductVariation = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.saveProductVariation(payload)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }
  // delete product variation
  const deleteProductVariation = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteProductVariation(id)
        .then(response => {
          resolve("Deleted")
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  // Get product detail by Id
  const getProductDetailAdmin = (id) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getProductDetailAdmin(id)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  // Get product detail by Id
  const getProductVariation = (config) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getProductVariation(config)
        .then(response => {
          resolve(response.data.payload.results)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }
  const getProductVariationById = (config) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getProductVariationById(config)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  // Get product detail by Id
  const deleteCurrentProduct = (id) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteCurrentProduct(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const productsContext = {
    getProducts,
    products,
    setProducts,
    getBrands,
    brands,
    getFlavours,
    getFlavourTypes,
    flavourTypes,
    getCategories,
    categories,
    flavours,
    flavoursData,
    addProducts,
    addProductStock,
    patchProductSku,
    deleteProductSku,
    getMilligrams,
    milligram,
    getNicotinestrength,
    nicotinestrength,
    editAProduct,
    deleteProduct,
    addToStore,
    sortByOptions,
    onChangeBrand,
    onResetBrand,
    filterParams,
    setFilterParams,
    onChangeFlavourType,
    onChangeFlavour,
    onResetFlavour,
    onChangeSortBy,
    onResetSortBy,
    onChangeMilligram,
    onResetMilligram,
    onChangeNicotine,
    onChangeCategory,
    onResetCategory,
    onResetNicotine,
    getBrand,
    deleteBrand,
    setBrands,
    updateBrand,
    deleteCategory,
    setCategories,
    subCategories,
    getSubCategories,
    setSubCategories,
    variations,
    getVariation,
    variationValues,
    getVariationValue,
    setVariationValues,
    getVariationValueById,
    updateVariationValue,
    addVariationValue,
    deleteVariationValue,
    getVariationValueByParentId,
    attributes,
    getAtrribute,
    setAtrributes,
    attributeValues,
    getAtrributeValue,
    setAtrributeValues,
    getAtrributeValueById,
    addAttributeValue,
    updateAttributeValue,
    deleteAttributeValue,
    // New Product list creation
    fetchCategories,
    fetchAttributesById,
    fetchDDNValuesbyID,
    getAllBrands,
    addNewCategory,
    createAProduct,
    getVaritionDDNValues,
    saveProductVariation,
    deleteProductVariation,
    // product details
    getProductDetailAdmin,
    getProductVariation,
    deleteCurrentProduct,
    getProductsStoreAdmin,
    getProductVariationById
  }
  return productsContext
}
