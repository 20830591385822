import React, { useEffect, useState } from "react"
import { Button, Nav } from "react-bootstrap"
import { SearchInput } from "../../../components/Admin/SearchInput"
import { assets } from "../../../utils"
import "./manageItems.scss"

export default function Items({
  label = "",
  defaultKey,
  items = [],
  variationType,
  onChangeText,
  setSelectedItem,
  onClickAddNewItem
}) {
  const [allItems, setAllItems] = useState([])

  useEffect(() => {
    items.sort((a, b) => a.label > b.label ? 1 : -1)
    setAllItems(items)
  }, [items])
  return (
    <div className="items">
      <h6 style={{ textTransform: "capitalize" }}>{label}</h6>
      <SearchInput onChangeText={(value) => {
        if (variationType === 'attribute') {
          setAllItems(items.filter(el=>el.label.includes(value)))
        } else {
          onChangeText(value)
        }
      }} />
      <Button
        className="createIcon ms-auto"
        onClick={onClickAddNewItem}
        style={{ fontSize: "14px" }}
      >
        <img src={assets.PlusIcon} />
        Create New Item
      </Button>
      <div className="mb-4"></div>
      <Nav
        variant="pills"
        defaultActiveKey={defaultKey}
        className="flex-column"
      >
        {!!allItems.length && allItems?.map(item => (
          <Nav.Link
            eventKey={item.value}
            onClick={() => setSelectedItem(item.value)}
          >
            {item.label}
          </Nav.Link>
        ))}
      </Nav>
    </div>
  )
}
