import React, { useEffect, useState } from "react"
import { useNavigate, Link, useLocation } from "react-router-dom"
import Card from "../../../components/Admin/containers/card"
import { useForm } from "react-hook-form"
import { useAuthContext } from "../../../contexts/AuthContext"
import CenteredContainer, {
  OneHalf
} from "../../../components/Admin/containers/centered-container"
import InputField from "../../../components/Admin/inputField"
import {
  getPayLoadDataFunction,
  LocalStorage,
  validation
} from "../../../utils"
import "./setNewPassword.scss"
import { SubmitButton } from "../../../components/Admin/elements/form/submit-button"
import { InputPassword } from "../../../components/Admin/inputPassword"
import { Button, Col, Row } from "react-bootstrap"

const SetNewPasswordScreen = () => {
  const nav = useNavigate()
  const location = useLocation()
  const { isLoading, forgotPasswordConfirm } = useAuthContext()

  const {
    control,
    register,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    handleSubmit
  } = useForm()

  const password = watch("password")
  const confirmPassword = watch("confirm_password")
  const [token, setToken] = useState("")
  const [uid, setUid] = useState("")

  const onSubmit = async vals => {
    const { password, confirm_password } = vals
    const payload = {}
    payload.token = token
    payload.uid = uid
    payload.new_password1 = password
    payload.new_password2 = confirm_password
    forgotPasswordConfirm(payload).then(() => nav("/admin/login"))
  }

  useEffect(() => {
    if (confirmPassword) {
      if (password) {
        if (confirmPassword !== password) {
          setError("confirm_password", {
            type: "custom",
            message: "Passwords do not match"
          })
        } else {
          clearErrors("confirm_password")
        }
      }
    }
  }, [password, confirmPassword])

  useEffect(() => {
    if (location.state) {
      const { token, uid } = location.state
      setToken(token)
      setUid(uid)
    }
  }, [])

  return (
    <CenteredContainer screenHeight={true} className="gradient-bg">
      <Card whiteBg={false}>
        <h3 className="text-center">Set a New Password</h3>
        <p className="text-center mb-5">
          Password must be at least 8 characters long
        </p>
        <form className={"login"} onSubmit={handleSubmit(onSubmit)}>
          {/* <Row className="justify-content-center"> */}
          {/* <Col xs={12}> */}
          <InputPassword
            label="Password"
            register={register}
            name="password"
            placeholder="8 characters"
            error={errors["password"]}
            className="applicationScreenInputAdmin"
          />
          <InputPassword
            label="Confirm Password"
            register={register}
            name="confirm_password"
            placeholder="8 characters"
            error={errors["confirm_password"]}
            className="applicationScreenInputAdmin"
          />
          <Row className="reset-password-btns mt-5">
            <Col>
              <Button
                className="cancel"
                variant="outline-dark"
                onClick={() => nav("/admin/forgot-password-verify")}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                disabled={(password !== confirmPassword) | isLoading}
                className="send"
                variant="dark"
                type="submit"
              >
                Send
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
    </CenteredContainer>
  )
}

export default SetNewPasswordScreen
