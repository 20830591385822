import React, { useState } from "react"
import { Form, FormGroup } from "react-bootstrap"
import { Controller } from "react-hook-form"
import Select from "react-select"
import "./vtpSelect.scss"

export default function VTPSelect({
  label,
  name,
  defaultOptions,
  loadOptions,
  placeholder,
  control,
  defaultValue,
  rules,
  isDisabled,
  divStyle,
  ...moreProps
}) {
  const style = {
    div: {
      marginBottom: "2.25rem"
    },
    label: {
      fontSize: "14px",
      lineHeight: "16px",
      marginBottom: "0.5rem"
    },
    placeholder: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "14px",
      color: "#cdc7c7 !important",
      opacity: ".5"
    }
  }

  const [fieldValue, setFieldValue] = useState(null)

  return (
    <div
      className="applicationScreenInputAdmin dv-default-input-container selectbox position-relative"
      style={divStyle}
    >
      <label style={style.label} id="aria-label" htmlFor="aria-example-input">
        {label}
      </label>
      <Controller
        name={name}
        rules={rules}
        render={({ field, fieldState: { error, isTouched } }) => (
          <FormGroup>
            <Select
              classNamePrefix="filter"
              {...field}
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              placeholder={
                <div className="select-placeholder" style={style.placeholder}>
                  {placeholder}
                </div>
              }
              options={defaultOptions}
              loadOptions={loadOptions}
              onChange={val => {
                setFieldValue(val)
                moreProps?.onChange?.(val)
                return field?.onChange(val)
              }}
              isDisabled={isDisabled}
            />

            {error && isTouched && !fieldValue && (
              <Form.Control.Feedback className="d-block" type="invalid">
                {error?.message}
              </Form.Control.Feedback>
            )}
          </FormGroup>
        )}
        control={control}
        defaultValue={defaultValue}
      />
    </div>
  )
}
