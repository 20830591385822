import React, { useState } from "react"
import { Button } from "react-bootstrap"

import "./elements.scss"

export const SubmitButton = ({
  label = "Log in",
  type = "submit",
  disabled,
  submitButtonStyle,
  callButton,
  className = "button-golden"
}) => {
  return (
    <Button
      variant="primary"
      type={type}
      className={className}
      disabled={disabled}
      style={submitButtonStyle}
      onClick={callButton}
    >
      {label}
    </Button>
  )
}
