export function validatePassword(password) {
  var passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,50}$/
  var bothCasePasswordRegex = /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{6,50}$/
  
  if (password.length < 6) {
    return "Minimum 6 characters required."
  }

  if (password.length > 50) {
    return "Maximum 50 characters."
  }

  if (!passwordRegex.test(password)) {
    return "Password should be alphanumeric with special character(s)."
  }

  if (!bothCasePasswordRegex.test(password)) {
    return "Password should have both lowercase and uppercase character(s)."
  }


  return ""
}
