import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../api"
import { formatDateForDB } from "../utils"
import OneSignalPush from "../utils/oneSignalPush"

const isUserLoggedIn = () => {
  const user = JSON.parse(localStorage.getItem('user')) || {}
  const token = localStorage.getItem('token')
  const isLoggedIn = !!(Object.keys(user).length && token);
  return isLoggedIn;
}

const AuthContext = createContext()

const AuthProvider = ({ children }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [loggedUserId, setLoggedUserId] = useState("")
  const [isUserFlagged, setIsUserFlagged] = useState(false)
  const [content, setContent] = useState("")
  const [tncRead, setTncRead] = useState(false)
  const [ppRead, setPpRead] = useState(false)
  const [showTnc, setShowTnc] = useState(false)
  const [showPp, setShowPp] = useState(false)
  const [isLoggedInUser, setLoggedInUser] = useState(isUserLoggedIn())

  const userSignup = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.signup(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const userLogin = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.login(payload)
        .then(response => {
          console.log(response)
          localStorage.setItem("token", response.data.payload.token)
          localStorage.setItem("user", JSON.stringify(response.data.payload.user))
          setLoggedInUser(true)
          resolve(response.data.payload.user)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const userLogout = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.logout(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => console.log(error))
        .finally(() => {
          localStorage.removeItem("token")
          localStorage.removeItem("user")
          setIsLoading(false)
          resolve()
        })
    })
  }

  const emailOtp = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.emailOtp(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const emailVerifyOtp = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.emailVerifyOtp(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const forgotPassword = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.forgotPassword(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const forgotPasswordVerify = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.forgotPasswordVerify(payload)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const forgotPasswordConfirm = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.forgotPasswordConfirm(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  /** USER PROFILE API */

  const getLoggedInUser = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user?.id) {
      setLoggedUserId(user.id)
    }
  }

  const getUserProfile = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getUserProfile(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const updateUserProfile = (id, payload) => {
    const formData = new FormData()
    Object.keys(payload).forEach(key => {
      if (payload[key] && key === "date_of_birth") {
        return formData.append(key, formatDateForDB(payload[key]))
      }
      formData.append(key, payload[key])
    })
    setIsLoading(true)
    return new Promise(resolve => {
      API.updateUserProfile(id, formData)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getTnc = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getTnc()
        .then(response => {
          setContent(response.data[0]?.body)
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getPrivacyPolicy = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getPrivacyPolicy()
        .then(response => {
          setContent(response.data[0]?.body)
          resolve(response.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  useEffect(() => {
    if (loggedUserId) {
      getUserProfile(loggedUserId).then(response =>
        setIsUserFlagged(response.flag)
      )
    }
  }, [loggedUserId])

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUserProfile('').then(resp => {
        console.log('PPPP', resp)
        OneSignalPush()
      })
    }
  }, [localStorage.getItem("token")])
  return (
    <AuthContext.Provider
      value={{
        isLoading,
        userLogin,
        userLogout,
        userSignup,
        emailOtp,
        emailVerifyOtp,
        forgotPassword,
        forgotPasswordVerify,
        forgotPasswordConfirm,
        loggedUserId,
        getLoggedInUser,
        getUserProfile,
        updateUserProfile,
        isUserFlagged,
        content,
        setContent,
        getTnc,
        getPrivacyPolicy,
        tncRead,
        ppRead,
        setTncRead,
        setPpRead,
        showTnc,
        showPp,
        setShowTnc,
        setShowPp,
        isLoggedInUser,
        setLoggedInUser,
        setIsLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider
