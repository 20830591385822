import React, { useEffect, useState, useMemo, useCallback } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { ALLROUTES } from "../../../adminRoutes"
import "./inventory.scss"
import { useAdminContext } from "../../../contexts/AdminContext"
import { Table } from "../../../components/Admin/VTPTable"
import { SearchInput } from "../../../components/Admin/SearchInput"
import { DeletePopup } from "../../../components/Admin/deletePopup"
import InputAction from "./inputAction";
import { ActionsColumn } from "./ActionsColumn"
const Inventory = () => {
  const nav = useNavigate()
  const {
    isLoading,
    productStock,
    getProductStock,
    setProductStock,
    deleteInventoryProduct,
    storeAdminSelectedStore,
    updateProductStockQty,
    filterParams,
    setFilterParams,
    fetchCategories,
    getInventoryItems,
    fetchAttributesById,
    getAllBrands,
    savedInventoryData
  } = useAdminContext()
  const { data, isFetching, error } = {}
  const { setContainerClass } = useOutletContext()
  const [searchText, setSearchText] = useState("")
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [productRecord, setProductRecord] = useState({})
  const [rowEditId, setRowEditId] = useState("")
  const [hasMoreValues, sethasMoreValues] = useState(true)
  const [pagination, setPagination] = useState({ limit: 20, offset: 0 })
  const [categoryList, setCategoryList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const [selectedCatSubCat, setSelectedCatSubCat] = useState({})
  const [cols, setCols] = useState([])
  const [inventoryList, setInventoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [retainPrevious, setRetainPrevious] = useState(false);
  const [inventoryListConfig, setInventoryListConfig] = useState({
    params: {
      search: '',
      store: '',
      variation__product__category: ''
    }
  })
  const [next, setNext] = useState("")

  const ITEMS_PER_PAGE = 10
  const inputRefObject = {}

  const allColumns = [
    {
      accessor: "variation.product.brand.name",
      Header: "Brand",
      Cell: tableProps => (
        <div className="d-flex align-items-center">
          {/* <img
            className="store-logo"
            src={tableProps.row.original.productsku.images[0]?.image}
          /> */}
          <p className="mb-0" style={{ textAlign: "left" }}>
            {tableProps.row.original.variation.product.brand.name}
          </p>
        </div>
      )
    },
    {
      accessor: "variation.product.name",
      Header: "Name"
    },
    {
      accessor: "variation.product.price",
      Header: "Unit Price",
      Cell: ({ value }) => (value ? value + "$" : "N/A")
    },
    {
      accessor: "min_quantity",
      Header: "Min Stock",
      Cell: ({ row, value }) => {
        return (
          <InputAction
            row={row}
            value={value}
            inputChange={handleMinInputChange}
            isLoading={isLoading && rowEditId == row?.original?.id}
          />
        )
      }
    },
    {
      accessor: "quantity",
      Header: "Stock",
      Cell: ({ row, value }) => {
        return (
          <InputAction
            row={row}
            value={value}
            inputChange={handleInputChange}
            isLoading={isLoading && rowEditId == row?.original?.id}
          />
        )
      }
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ row }) => (
        <ActionsColumn
          record={row.original}
          isFlagOption={false}
          isSaveOption={true}
          isDeleteOption={true}
          Savequantity={() => handleSaveQuantity(row.original)}
          onClickDeleteUser={onClickDeleteUser}
        />
      )
    }
  ]

  const DropDownField = ({ name, handledropDownValChange, dropDownList = [], selected }) => {
    const [selectedDropdownValue, setSelectedDropdownValue] = useState(`${name} - ${selected || dropDownList[0]?.name}`)
    return <div>
      <DropdownButton
        onSelect={(e) => {
          setSelectedDropdownValue(`${name} - ${JSON.parse(e).name}`);
          handledropDownValChange(name, e)
        }}
        title={selectedDropdownValue}
      >
        <Dropdown.Menu>
          {dropDownList?.map(listItem => {
            return (
              <Dropdown.Item
                key={listItem.name}
                eventKey={JSON.stringify(listItem)}
              >
                {listItem.name}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </DropdownButton>
    </div>;
  }

  useEffect(() => {
    fetchCategories({}).then(res => {
      setCategoryList(res);
      setSubCategoryList(res[0].sub_category);
      const config = { ...inventoryListConfig }
      config.params.variation__product__category = res[0].sub_category[0].id
      config.params.store = storeAdminSelectedStore.id
      setInventoryListConfig(config)
    });
    getAllBrands().then(resp=>{
      setBrandList(resp)
    })
    setCols(allColumns)
  }, [])

  useEffect(() => {
    // console.log('Category and Sub Category selections', selectedCatSubCat)
    if (selectedCatSubCat.subCategory?.id) {
      let config = { ...inventoryListConfig }
      config.params.variation__product__category = selectedCatSubCat.subCategory.id
      config.params.store = storeAdminSelectedStore?.id
      setInventoryListConfig(config)
    }
  }, [selectedCatSubCat])

  useEffect(() => {
    console.log('configs', storeAdminSelectedStore, inventoryListConfig)
    if (inventoryListConfig.params.store && inventoryListConfig.params.variation__product__category) {
      fetchInventoryItems()
    }
  }, [inventoryListConfig])

  useEffect(() => {
    if (storeAdminSelectedStore?.id) {
      const config = { ...inventoryListConfig };
      config.params.store = storeAdminSelectedStore.id;
      setInventoryListConfig(config)
    }
  }, [storeAdminSelectedStore])

  const fetchInventoryItems = () => {
    const newCols = [...allColumns]
    Promise.all([fetchAttributesById(inventoryListConfig.params.variation__product__category)
      , getInventoryItems(inventoryListConfig, retainPrevious)])
      .then(resp => {
        const categoryResp = resp[0];
        const inventoryResp = resp[1];
        const attrs = categoryResp.attribute;
        const variations = categoryResp.variations;
        const dynamicColumns = [];
        attrs.forEach(attr => {
          dynamicColumns.push(createTableField(attr))
        });
        variations.forEach(variation => {
          const obj = { classification: variation.classification, variation: variation.variation };
          if (!!obj.classification) {
            dynamicColumns.push(createTableField(obj.classification))
          }
          if (!!obj.variation && obj.variation.id!==8 && obj.variation.name!=='Default') {
            dynamicColumns.push(createTableField(obj.variation))
          }
        });
        newCols.splice(2, 0, ...dynamicColumns);
        setCols(newCols);
        if (inventoryResp?.results?.length) {
          inventoryResp.results.forEach(item => {
            attrs.forEach(attr => {
              const newAttr = { ...attr }
              newAttr['value'] = item?.variation?.product_attributes?.find(el => el.attribute.id === attr.id)?.value || ''
              item[attr.name] = newAttr;
            });
            item.variation?.productvariations?.forEach(variation => {
              if (!!variation.classificationvalue) {
                item[variation?.classificationvalue?.variation?.name] = variation.classificationvalue
              } if (!!variation.variationvalue) {
                item[variation?.variationvalue?.variation?.name] = variation.variationvalue
              }
            });
            setInventoryList(inventoryResp.results)

          })
        } else {
          setInventoryList([])
        }
      });
  }

  const createTableField = (attr) => {
    return {
      accessor: attr.name,
      Header: attr.name,
      Cell: ({ value }) => value?.value || ''
      // Cell: (value) => 'Dummy Value'
    }
  }
  const onClickDeleteUser = record => {
    setProductRecord(record)
    setShowDeletePopup(true)
  }
  const onDeleteProduct = () => {
    deleteInventoryProduct(productRecord.id).then(() => {
      setShowDeletePopup(false)
      setProductRecord({})
      const newList = inventoryList.filter(rec => rec.id !== productRecord.id);
      setInventoryList(newList)
    })
  }

  useEffect(() => {
    sethasMoreValues(true)
    if (storeAdminSelectedStore?.id) {
      const config = {
        params: {
          ...pagination,
          search: searchText.toLowerCase(),
          storeid: storeAdminSelectedStore.store || "",
          ...filterParams
        }
      }
      console.log('Call progress')
      getProductStock(config)
    }
  }, [storeAdminSelectedStore, searchText, filterParams])

  const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const onChangeText = debounce(text => {
    const newConfigs = {...inventoryListConfig};
    newConfigs.params.search = text
    setInventoryListConfig(newConfigs)
  }, 300)

  const handleInputChange = (row, inputValue, changeVal) => {
    inputRefObject[row.original.id] = {
      ...inputRefObject[row.original.id],
      quantity: inputValue
    }
    setRowEditId(row.original.id)
  }

  const handleMinInputChange = (row, inputValue, changeVal) => {
    inputRefObject[row.original.id] = {
      ...inputRefObject[row.original.id],
      min_quantity: inputValue
    }
    setRowEditId(row.original.id)
  }

  const handleSaveQuantity = data => {
    const productId = data.id
    const stockValue = inputRefObject[data.id]
    if (!stockValue) return

    const payload = { ...stockValue }
    const store = JSON.parse(localStorage.getItem("user")).user_store[0].id
    updateProductStockQty(productId, payload).then(res => {
      // getProductStock(config)
      const existing = [...inventoryList]
      const index = existing.findIndex(({ id }) => id === productId);
      existing[index].min_quantity = res?.min_quantity
      existing[index].quantity = res.quantity
      setInventoryList(existing)
    })
  }

  const columns = useMemo(() => cols, [isLoading, cols])

  useEffect(() => {
    setContainerClass("dashboard-main")
    return () => setContainerClass("")
  }, [])

  useEffect(() => {
    if (error?.response.status == 404) {
      nav(ALLROUTES.dashboardChildren.profile)
    }
  }, [error])

  const cachedLoadMoreHandler = () => {
    sethasMoreValues(false)
    if (savedInventoryData?.pagination?.next) {
      const config = { ...inventoryListConfig }
      config.params.offset = savedInventoryData.pagination.offset
      config.params.limit = savedInventoryData.pagination.limit
      setRetainPrevious(true)
      setInventoryListConfig(config)
      sethasMoreValues(true)
    } else {
      sethasMoreValues(false)
    }
  }
  // const cachedLoadMoreHandler = useCallback(loadMoreHandler, [])

  useEffect(() => {
    if (next) {
      const config = {
        params: {
          limit: 20,
          offset: getOffset(next),
          search: searchText.toLowerCase(),
          storeid: storeAdminSelectedStore.store || "",
          ...filterParams
        }
      }
      getProductStock(config)
    }
  }, [next])

  const getOffset = url => {
    const _url = new URL(url)
    const params = new URLSearchParams(_url.search)
    return params.get("offset")
  }

  useEffect(() => {
    return () => setFilterParams({ all: true })
  }, [])

  const handleCatValChange = (name, e) => {
    console.log(name, JSON.parse(e))
    let item = JSON.parse(e)
    if (name === 'Category') {
      setSubCategoryList(item.sub_category)
      setSelectedCatSubCat({ category: item, subCategory: item.sub_category[0] })
    } else {
      setSelectedCatSubCat(selections => { return { ...selections, subCategory: item } })
    }
  }

  const handleBrandFilter = (e) => {
    const selectedBrand = JSON.parse(e.target.value);
    const config = {...inventoryListConfig}
    if(selectedBrand.shouldClear){
      config.params.variation__product__brand = '';
      setInventoryListConfig(config)
      return
    }else {
      config.params.variation__product__brand = selectedBrand.id;
      setInventoryListConfig(config)
    }
    // setInventoryList(config)
  }

  return (
    <>
      <header className="head gradient-bg d-flex justify-content-center flex-column position-relative py-3">
        <div className="d-flex justify-content-between w-100 align-items-center px-4">
          <div className="hello-user-card d-flex align-items-center justify-content-start">
            <span className="me-3">
              <h3>Inventory</h3>
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center px-4">
          <SearchInput onChangeText={onChangeText} />
          {/* <Form.Select
            onChange={handleBrandFilter}
            style={{ cursor: "pointer", maxWidth:'110px', marginLeft:'20px' }}
          >
            <option value={JSON.stringify({shouldClear:true})}>Brand</option>
            {brandList?.map((brand)=> (
              <option value={JSON.stringify(brand)} key={brand.id}>
                {brand.name}
              </option>
            ))}
          </Form.Select> */}
        </div>
        <div className="px-3 inventory">
          <div className="category-selection-inventory">
            <DropDownField name='Category' selected={selectedCatSubCat?.category?.name} handledropDownValChange={handleCatValChange} dropDownList={categoryList} />
            <DropDownField name='Sub-Category' selected={selectedCatSubCat?.subCategory?.name} handledropDownValChange={handleCatValChange} dropDownList={subCategoryList} />
          </div>
          <Table
            columns={columns}
            data={inventoryList || []}
            isLoading={isLoading}
            initialSortKey="productsku.product.brand.name"
            height="73vh"
            isPagination={true}
            loadMoreHandler={cachedLoadMoreHandler}
            hasMorevalues={hasMoreValues}
          />
        </div>
        {showDeletePopup ? (
          <DeletePopup
            show={showDeletePopup}
            setShow={setShowDeletePopup}
            label={"Delete Product"}
            currentRecord={productRecord}
            isLoading={isLoading}
            onClickCancel={onDeleteProduct}
            featureType="deleteInventoryProduct"
            heading={"Are you sure you want to delete product?"}
          />
        ) : (
          <></>
        )}
      </header>
    </>
  )
}

export default Inventory
