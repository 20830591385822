import { BrowserRouter as Router } from "react-router-dom";
import AuthProvider, { useAuthContext } from "./contexts/AuthContext"
import ErrorAlert from "./components/ErrorPopup"
import AdminProvider from "./contexts/AdminContext"
import "./App.scss"
import AdminRoutes from "./adminRoutes"
import React from "react";
import { FallbackLoader } from "./components/Admin/Fallback/fallbackLoader";

function App() {
  return (
    <AuthProvider>
      <AdminProvider>
        <Router>
          <ErrorAlert />
          <React.Suspense fallback={<FallbackLoader />}>
          <AdminRoutes />
          </React.Suspense>
        </Router>
      </AdminProvider>
    </AuthProvider>
  )
}

export default App
