import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Select } from '../../../../components/Admin/VTPSelect';
import { useAdminContext } from '../../../../contexts/AdminContext';
import InputAction from '../../inventory/inputAction';
import './createDeal.scss'
import VTPDatePicker from '../../../../components/VTPDatePicker';
import { assets, formatDateYYYYMMDD } from '../../../../utils';
import InputField from '../../../../components/Admin/inputField';
import { SearchInput } from '../../../../components/Admin/SearchInput';

export default function CreateDeal({ handleDealCreation }) {
    const today = new Date()
    const dealEndDate = new Date(today.getFullYear()+2, today.getMonth(), today.getDate())
    const [formFields, setFormFields] = useState({
        selectedProduct: { value: '', error: '' },
        startDate: { value: formatDateYYYYMMDD(new Date()), error: '' },
        endDate: { value: formatDateYYYYMMDD(new Date(dealEndDate)), error: '' },
        noOfItems: { value: '', error: '' },
        dealPrice: { value: '', error: '' },
    })
    const [searchedProduct, setSearchedProduct] = useState('')
    const [productsSuggestion, setProductsSuggestion] = useState([])
    const [selectedProduct, setSelectedProduct] = useState({})
    const [products, setProducts] = useState([])
    const [initialNumberOfItems, setInitialNumberOfItems] = useState(0)
    const { control, trigger, setValue, reset, register, setError, formState, handleSubmit, getValues } = useForm();
    const { getProducts } = useAdminContext();
    let debounceTimer

    const Error = ({ message }) => {
        return <>{
            message?.length ? <div className='deals-error-message'>
                {message}
            </div > :
                <></>
        }</>
    }

    useEffect(() => {
        let listItems = products.map(el => (
            {
                id: el.id,
                name: `${el.name} - ${el.brand.name} - (${el.category?.name})`,
                price: el.price
            }
        ))
        setProductsSuggestion(listItems)
        const existingFields = { ...formFields };
        existingFields.selectedProduct.error=''
        setFormFields(existingFields)
    }, [products])

    const getSuggesstions = () => {
        const config = {
            params: {
                limit: 1000,
                offset: 0,
                search: searchedProduct
            }
        }
        if (searchedProduct.length >= 3) {
            getProducts(config, (resp) => {
                setProducts(resp)
            })
        }
    }

    const handleNoOfItemsChange = (e, numberOfItems) => {
        const existingFields = { ...formFields };
        if (numberOfItems < 0) {
            existingFields.noOfItems.error = 'Invalid Number'
        } else {
            existingFields.noOfItems.value = numberOfItems
            existingFields.noOfItems.error = ''
        }
        setInitialNumberOfItems(numberOfItems)
        setFormFields(existingFields)
    }


    const onChangeText = (e) => {
        const text = e
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(()=>{
            setproductState(text)
        },1000)
    }

    const setproductState = (text) => {
        setSearchedProduct(text)
    }

    useEffect(() => {
        if (!!searchedProduct.length) {
            getSuggesstions()
        }
    }, [searchedProduct])

    const handleProductSelection = (e, product) => {
        setSearchedProduct('')
        setSelectedProduct(product)
        setProductsSuggestion([])
    }

    const handleStartDate = (e) => {
        const existingFields = { ...formFields }
        if (new Date(e) < new Date()) {
            existingFields.startDate.error = 'Invalid Start date'
        } else {
            existingFields.startDate.value = formatDateYYYYMMDD(e)
            existingFields.startDate.error = ''
        }
        setFormFields(existingFields)
    }
    const handleEndDate = (e) => {
        const existingFields = { ...formFields }
        if (new Date(e) < new Date()) {
            existingFields.endDate.error = 'Invalid End date'
        } else {
            existingFields.endDate.value = formatDateYYYYMMDD(e)
            existingFields.endDate.error = ''
        }
        setFormFields(existingFields)
    }

    const onSubmit = data => {
        if (!validateData(data)) {
            const payload = {
                product: selectedProduct.id,
                end_date: formFields.endDate.value,
                start_date: formFields.startDate.value,
                message: data.dealMessage,
                quantity: formFields.noOfItems.value,
                deal_price: data.dealPrice,
            }
            reset()
            console.log('reseting...')
            setProducts([])
            setSelectedProduct({})
            setInitialNumberOfItems(0)
            handleDealCreation(payload)
        }
    };

    const handleDealPriceChange = (e) => {
        const existingFields = { ...formFields }
        if(e.target.value.length){
            existingFields.dealPrice.error = ''
        } else {
            existingFields.dealPrice.error = 'Required'
        }
        setFormFields(existingFields)
    }
    
    const validateData = (data) => {
        const existingFields = { ...formFields }
        if (!existingFields.startDate.value && !existingFields.startDate.error) {
            existingFields.startDate.error = 'Required'
        }
        if (!existingFields.endDate.value && !existingFields.endDate.error) {
            existingFields.endDate.error = 'Required'
        }
        if (!selectedProduct.id) {
            existingFields.selectedProduct.error = 'Required'
        }
        if (!existingFields.noOfItems.value) {
            existingFields.noOfItems.error = 'Required'
        }
        if (!data.dealPrice) {
            existingFields.dealPrice.error = 'Required'
        }
        setFormFields(existingFields)
        return Object.values(existingFields).map(val => val.error).some(el => el.length)
    }

    return (
        <div className='create-deal-wrapper'>
            <div className='create-deal-heading'>
                Create a New Deal
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row className='create-deal-row'>
                    <Col lg={12}>
                        {!selectedProduct.id && <div className='input-box'>
                            <div className='input'>
                                {/* <input value={inputVal} type='text' onChange={handelInputChange}/> */}
                                <SearchInput
                                    // onBlurHandler={() => handleSearchInputBlur()}
                                    // onFocusHandler={()=>getSuggesstions()}
                                    onChangeText={(e)=>onChangeText(e)} />
                            </div>
                            <Error message={formFields.selectedProduct.error} />
                            {!!productsSuggestion.length && <div className='list'>
                                <ul>
                                    {productsSuggestion.map(product =>
                                        <li key={product.id} onClick={(e) => handleProductSelection(e, product)}>{product.name}</li>
                                    )}
                                </ul>
                            </div>}
                        </div>}

                        {selectedProduct.id && <div className='show-selected-product'>
                            <input type="text" id="selectedProduct"
                                {...register('selectedProduct')}
                                disabled
                                value={selectedProduct.name}
                            ></input>
                            <img src={assets.EditIcon} onClick={() => setSelectedProduct({})} />
                            <Error message={formFields.selectedProduct.error} />
                        </div>}
                    </Col>
                    <Col lg={12}>
                        <div className='deal-message'>
                            <label>Deal Message</label>
                            <textarea id="dealMessage" placeholder='Write here'
                                {...register('dealMessage')}
                            />
                        </div>
                    </Col>
                    <Row className='items-price'>
                        <Col lg={4}>
                            <div className='product-price-heading'>
                                Product Price
                            </div>
                            <div className='product-price'>
                                ${selectedProduct.price || ' - -'}
                            </div>
                        </Col>
                        <Col lg={4} className='number-of-items'>
                            <label>Number of Items</label>
                            <InputAction
                                id="numberOfItems"
                                {...register('numberOfItems')}
                                row={0}
                                value={initialNumberOfItems}
                                inputChange={handleNoOfItemsChange}
                            />
                            <Error message={formFields.noOfItems.error} />
                        </Col>
                        <Col lg={4} className='deal-price'>
                            <label>Deal price</label>
                            <div className='input-field'>
                                <p>$</p>
                                <input className="deal-price-input" type='number'
                                    id="dealPrice"
                                    {...register('dealPrice')}
                                    onChange={handleDealPriceChange}
                                />
                                <Error message={formFields.dealPrice.error} />
                            </div>
                        </Col>
                    </Row>

                    <Row className='deal-date-wrapper'>
                        <Col lg={6} className='deals-date-picker'>
                            <label>Start Date</label>
                            <div className='deals-datepicker-wrapper'>
                                <VTPDatePicker
                                    name="startDate"
                                    onChange={handleStartDate}
                                />
                                <img className='calendar-image' src={assets.calendar} />
                            </div>
                            <Error message={formFields.startDate.error} />
                        </Col>
                        <Col lg={6} className='deals-date-picker'>
                            <label>End Date</label>
                            <div className='deals-datepicker-wrapper'>
                                <VTPDatePicker
                                    name="endDate"
                                    initialDate={dealEndDate}
                                    onChange={handleEndDate}
                                />
                                <img className='calendar-image' src={assets.calendar} />
                                <Error message={formFields.endDate.error} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='submit-button-row'>
                        <Button variant='dark'
                            type='submit'
                            disabled={!formState.isValid}>
                            Create Deal
                        </Button>
                    </Row>
                </Row>
            </form>
        </div>
    )
}
