import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { assets } from "../../utils"

export default function CancelConfirmPopup({
  label,
  show,
  setShow,
  onClickCancel,
  showTitle = true,
  isDeletsConfirmation = false,
  completeDeal = false

}) {
  return (
    <div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="confirm-cancel"
        centered
        className="cancel-confirm-popup"
      >
        <Modal.Body className="p-5 px-5 text-center">
          {(!isDeletsConfirmation && !completeDeal) && <img src={assets.FlagIcon} alt="cancel-sign" height={"50px"} />}
          {isDeletsConfirmation && <img src={assets.DeleteIcon} alt="cancel-sign" height={"50px"} />}
          {completeDeal && <img src={assets.completeDealIcon} alt="complete-deal" height={"50px"} />}
          {showTitle && (
            <h3 className="mt-3 mb-0">Confirm Cancellation &nbsp;</h3>
          )}
          <p className="text-muted mt-3 mb-4">{label}</p>
          <div className="">
            <Button
              variant="outline-success"
              onClick={() => {
                setShow(false)
              }}
              className="py-2 px-5"
            >
              NO
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="danger"
              onClick={() => {
                setShow(false)
                onClickCancel()
              }}
              className="py-2 px-5"
            >
              YES
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
