import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';

export default function ProtectedHOC({children}) {
    const { isLoggedInUser } = useAuthContext();
    console.log('protected HOC',isLoggedInUser)
    const { pathname } = useLocation();
    if(isLoggedInUser) return children;
    return <Navigate to="/admin/login" state={{ from: pathname }} replace={true}/>;
}
