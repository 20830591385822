import { ALLROUTES } from "../adminRoutes"
import Request from "./axiosInstance"

const tokenInterceptor = Request.interceptors.request.use(
  req => {
    const token = localStorage.getItem("token")
    if (!!token) {
      req.headers["Authorization"] = `Token ${token}`
    }
    return req
  },
  null,
  { synchronous: true }
)

const catchErrors = err => {
  if ([400, 401, 403, 500, 404].find(e => e == err?.response?.status)) {
    createCustomErrorMessage(err)
    // if any response is a 401 or 403, redirect to login
    if ([401, 403].find(e => e == err?.response?.status)) {
      // clear local storage, so that route guards do not consider stale token
      // localStorage.clear()
      // window.location = ALLROUTES.login
    }
  }
  throw err
}

window.addEventListener("storage", () => {
  if (localStorage.getItem("token")) {
    Request.interceptors.request.eject(tokenInterceptor)
    Request.interceptors.request.use(tokenInterceptor)
  }
})

export const get = (url, config) => {
  return Request.get(url, config).catch(catchErrors)
}

export const post = (url, data, config) => {
  return Request.post(url, data, config).catch(catchErrors)
}

export const put = (url, config) => {
  return Request.put(url, config).catch(catchErrors)
}

export const patch = (url, data, config) => {
  return Request.patch(url, data, config).catch(catchErrors)
}

export const del = (url, data, config) => {
  return Request.delete(url, data, config).catch(catchErrors)
}

// const createCustomErrorMessage = (err) => {
//   console.log('PPPPPPPPP',err)
//   let error = '';
//   for(const k in err.response.data){
//     if(k==='message'){
//       error += err.response.data[k] + ', '
//     }
//     else if(Array.isArray(err.response.data[k]) && err.response.data[k].length){
//       error +=  err.response.data[k] + ', ';
//     }
//     else {
//       error += err.response.data[k].message + ', '
//     }
//   }
//   error = error.trim().slice(0, error.length - 2)
//   window?.customErrorCallback?.(error, err?.response?.status)
// }

const createCustomErrorMessage = (err) => {
  
  window?.customErrorCallback?.(err?.response?.data?.error, err?.response?.status)
}
