import React from "react"
import { Button, Modal } from "react-bootstrap"
import "./verifyUser.scss"

export default function VerifyUserModal(props) {
  const userDetails = props?.data
  const { approveUser, onHide } = props
  const handleApproveClick = () => {
    approveUser(userDetails.id, { id_verified: true })
  }

  return (
    <div className="verifyUserModal">
      <Modal
        {...props}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="verify-modal-popup"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Approve user
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="verify-user-body">
            <div className="ask-question">
              Are you sure you want approve user?
            </div>
            <div className="user-details">
              <div className="name-email">
                <div className="name">{userDetails?.user?.name}</div>
                <div className="email">{userDetails?.user?.email}</div>
              </div>
              <div className="role-phone">
                <div className="role">
                  {userTypeMappings[userDetails?.role]}
                </div>
                <div className="phone">{userDetails?.phone_number}</div>
              </div>
            </div>
            <div className="action-buttons">
              <Button
                variant="outline-dark"
                onClick={onHide}
                className="cancel-btn"
              >
                Cancel
              </Button>
              <Button
                variant="outline-dark"
                onClick={handleApproveClick}
                className="approve-btn"
              >
                Approve
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const userTypeMappings = {
  STAD: "Store Admin",
  SUPA: "Super Admin",
  CUST: "Customer",
  SUBADMIN: "Sub Admin"
}
