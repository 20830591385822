import { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { useForm } from "react-hook-form"
import { formatPhoneNumber, userRoleProfiles, validation } from "../../utils"
import InputField from "./inputField"
import { Loader } from "./Loader"
import "./popup.scss"
import { Select } from "./VTPSelect"
import ReactFlagsSelect from "react-flags-select"
import { useState } from "react"
import { SubmitButton } from "./elements/form/submit-button";
import './Deletepopup.scss'

export function DeletePopup(props) {
  const { control, setValue, reset, trigger, handleSubmit } = useForm()
  const {
    isLoading,
    currentRecord,
    editMode,
    show,
    label,
    setShow,
    onClickCancel,
    heading,
    featureType
  } = props

  console.log('current record', currentRecord)
  useEffect(() => {
    if (editMode) {
      setValue("name", currentRecord.name)
      setValue("user_phone_number", currentRecord.user_phone_number)
      setValue("email", currentRecord.email)
      setValue(
        "role",
        userTypeOptions.find(({ value }) => currentRecord.role === value)
      )
      setValue("id", currentRecord.id)
    }
  }, [editMode])

  useEffect(() => {
    if (!show) {
      reset()
    }
  }, [show])

  const handleDeleteBtnCall = () => {
    setShow(false)
    onClickCancel()
  }

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="title mb-4">{heading}</p>
        <Row className="justify-content-center align-items-center delete-popup-body">
          <Col
            xs="12"
            lg="6"
            className="justify-content-center align-items-center"
          >
            {featureType === 'deleteUser' && <>
              <p className="name mb-0">{currentRecord?.user?.name}</p>
              <p className="name">{currentRecord?.user?.email}</p>
            </>}
            {featureType === 'deleteStore' && <>
              <p className="store-name">{currentRecord?.name}</p>
              <p className="store-admin-names">{currentRecord?.storeadmins?.map(({username})=>username).join(', ')}</p>
              <p className="store-address">{currentRecord?.address}</p>
              <p className="store-email">{currentRecord?.email}</p>
            </>}
          </Col>
          <Col xs="12" lg="6" className="d-flex flex-column align-items-end">
            <p className="userRole mb-0">{userTypeMappings[currentRecord.role]}</p>
            <p className="name">{formatPhoneNumber(currentRecord?.phone_number)}</p>
          </Col>
          <Col xs="12" lg="6" className="text-center">
            <SubmitButton
              label="Cancel"
              type="button"
              className="cancelButton"
              callButton={() => setShow(false)}
            />
          </Col>
          <Col xs="12" lg="6" className="text-center">
            <SubmitButton label="Delete" callButton={handleDeleteBtnCall} />
          </Col>
        </Row>

        {/* <Loader isLoading={isLoading} isCentered={true} /> */}
      </Modal.Body>
    </Modal>
  )
}

const userTypeOptions = [
  { label: "Admin", value: userRoleProfiles.ADMIN },
  { label: "Super Admin", value: userRoleProfiles.SUPERADMIN },
  { label: "Sub Admin", value: userRoleProfiles.SUBADMIN },
  { label: "Customer", value: userRoleProfiles.CUSTOMER }
]
const userStatusOptions = [
  { label: "Active", value: true },
  { label: "Inactive", value: false }
]

const userTypeMappings = {
  STAD: "Store Admin",
  SUPA: "Super Admin",
  CUST: "Customer",
  SUBADMIN: "Sub Admin"
}
