import React, { useEffect, useState, useMemo } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useNavigate, useOutletContext } from "react-router-dom"
import { ALLROUTES } from "../../../adminRoutes"
import InfiniteScroll from "react-infinite-scroller"
import { Loader } from "../../../components/Admin/Loader"
import { SearchInput } from "../../../components/Admin/SearchInput"
import { useAdminContext } from "../../../contexts/AdminContext"
import { assets } from "../../../utils"
import "./feedback.scss"
import FeedbackCards from "./feedbackCards"

const Feedback = () => {
  const { isLoading, feedbacks, getFeedbacks, sendMail, feedbackPagination } =
    useAdminContext()
  const [searchText, setSearchText] = useState("")
  const [messageText, setMessageText] = useState("")
  const [selectMsg, setSelectMsg] = useState({})
  const user = JSON.parse(localStorage.getItem("user"))
  const [colWidth, setColWidth] = useState("12")
  const [emailSent, setEmailSent] = useState(true)
  const [hasMoreValues, sethasMoreValues] = useState(true)

  useEffect(() => {
    const config = {
      params: {
        search: searchText.toLowerCase()
      }
    }
    if (emailSent) {
      getFeedbacks(config)
    }
  }, [searchText, emailSent])

  const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const onChangeText = debounce(text => {
    setSearchText(text)
  }, 300)

  const onChangeReply = text => {
    setMessageText(text)
  }

  const sendMessage = () => {
    if (selectMsg.reply.length) return
    const payload = {
      reply: messageText
    }
    sendMail(selectMsg.id, payload).then(() => {
      setSelectMsg("")
      setEmailSent(true)
      setColWidth("12")
    })
  }
  const handleReply = feedback => {
    setEmailSent(false)
    setColWidth("5")
    setSelectMsg(feedback)
  }

  useEffect(() => {
    setMessageText(selectMsg.reply)
  }, [selectMsg])

  const handleLoadMore = () => {
    sethasMoreValues(false)
    if (feedbackPagination.next) {
      sethasMoreValues(true)
      const loadMoreParams = feedbackPagination.next || ""
      //sending empty config object to reuse the getFeedbacks function
      getFeedbacks({}, loadMoreParams)
    } else {
      sethasMoreValues(false)
    }
  }

  return (
    <>
      <header className="head gradient-bg d-flex justify-content-center flex-column  position-relative py-3">
        <div className="d-flex justify-content-between w-100 align-items-center px-4">
          <div className="hello-user-card d-flex align-items-center justify-content-start">
            <span>
              <h3>Feedback</h3>
            </span>
          </div>
        </div>
        <div
          style={{ height: "50px", marginBottom: "10px" }}
          className="d-flex justify-content-between align-items-center px-4"
        >
          <SearchInput onChangeText={onChangeText} />
        </div>
        <div style={{ marginLeft: "1.3rem" }}>
          <Row className="no-margin">
            <Col
              className="h-max-80vh overflow-y-scroll px-0"
              style={{ backgroundColor: "white", borderRadius: "8px" }}
              lg={colWidth}
            >
              <InfiniteScroll
                pageStart={0}
                loadMore={handleLoadMore}
                hasMore={hasMoreValues}
                useWindow={false}
                initialLoad={false}
              >
                {feedbacks?.length > 0 ? (
                  feedbacks?.map(feedback => {
                    return (
                      <FeedbackCards
                        key={`${feedback.id}-${feedback.title}`}
                        feedback={feedback}
                        setSelectMsg={handleReply}
                        selectMsg={selectMsg}
                      />
                    )
                  })
                ) : (
                  <Col className="text-center">
                    <p
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                      }}
                    >
                      No results found
                    </p>
                  </Col>
                )}
              </InfiniteScroll>
            </Col>
            {feedbacks?.length > 0 && selectMsg && selectMsg?.user && (
              <Col className="email-feedback-container" lg="7">
                <div
                  className="d-flex mb-5 p-2"
                  style={{ backgroundColor: "white", borderRadius: "8px" }}
                >
                  <Col lg="10" className="ps-3">
                    <h6 className="mail">
                      <strong className="text-dark">To: </strong>{" "}
                      <span>{selectMsg.user.name}</span>
                    </h6>
                    <h6 className="mail">
                      <strong className="text-dark">Subject: </strong>Re:
                      <span>{selectMsg.title}</span>
                    </h6>
                    <h6 className="mail mb-2">
                      <strong className="text-dark">From: </strong>
                      <span>
                        {user.name} - {user.role}
                      </span>
                    </h6>
                  </Col>
                  <Col
                    lg="2"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <img
                      className={
                        selectMsg.reply.length ? "disabled-reply-icon" : ""
                      }
                      onClick={() => {
                        sendMessage()
                      }}
                      src={assets.SendIcon}
                    />
                  </Col>
                </div>
                <div className="p-2 reply-text-field">
                  <textarea
                    onChange={({ target: { value } }) => onChangeReply(value)}
                    value={messageText}
                    disabled={selectMsg.reply.length}
                    className="reply"
                    autoFocus={true}
                  />
                  <h6 className="message-text">Message</h6>
                  <div className="d-flex">
                    <div className="vr"></div>
                    <p className="reply-message">{selectMsg.message}</p>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </header>
      {/* <Loader isLoading={isLoading} isCentered={true} /> */}
    </>
  )
}
export default Feedback
