import React, { useState } from 'react'
import VerifyUserModal from '../VerifyUserModal';
import { assets } from '../../utils';
import './verifyIdIcon.scss'

export default function VerifyIdIcon(props) {

  const { original, approveUser } = props
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className='verifyIdIcon'>
      <div className="ps-3">
        {
          !original.id_verified ?
            <img src={assets.banIcon} onClick={() => setModalShow(true)} /> :
            <img className='verified-user-img' src={assets.VerifiedUser} />
        }
      </div>
      <VerifyUserModal
        show={modalShow}
        approveUser={approveUser}
        data={original}
        onHide={() => setModalShow(false)}
      />
    </div>
  )
}
