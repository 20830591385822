import React, { useState } from 'react';
import { API } from '../../api';
import { useAuthContext } from '../AuthContext';

export default function Inventory() {
    const [productStock, setProductStock] = useState({ results: [], next: null })
    const [savedInventoryData, setSavedInventoryData] = useState({})
    const { setIsLoading } = useAuthContext();

    const filterUrlParams = url => {
      const urlObj = new URL(url)
      const limit = urlObj.searchParams.get("limit")
      const offset = urlObj.searchParams.get("offset")
      return { limit, offset }
    }
    
    const getProductStock = config => {
        setIsLoading(true)
        API.getProductStock(config)
          .then(response => {
            if (config?.params?.offset == 0) {
              return setProductStock(response.data)
            } else {
              const update = {
                ...response.data,
                results: [...productStock.results, ...response.data.results]
              }
              setProductStock(update)
            }
          })
          .catch(error => console.log(error))
          .finally(() => setIsLoading(false))
      }

      const deleteInventoryProduct = productId => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.deleteInventoryProduct(productId)
            .then(response => {
              resolve(response)
            })
            .catch(error => console.log(error))
            .finally(() => setIsLoading(false))
        })
      }

      const updateProductStockQty = (productId, payload) => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.updateProductStockQty(productId, payload)
            .then(response => {
              resolve(response.data.payload)
            })
            .catch(error => reject(error))
            .finally(() => setIsLoading(false))
        })
      }
      
      const getInventoryItems = (config,retainPrevious=false) => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.getInventoryItems(config)
            .then(response => {
              const data = response.data.payload
              const { limit, offset } = data.next
              ? filterUrlParams(data.next)
              : { limit: "", offset: "" }
              setSavedInventoryData({
                pagination:{limit,offset,next:response.data.payload.next},
                list:response.data.payload.results
              })
              if(!retainPrevious){
                resolve(response.data.payload)
              } else {
                resolve({...response.data.payload,results:[...savedInventoryData.list,...response.data.payload.results]})
              }
            })
            .catch(error => reject(error))
            .finally(() => setIsLoading(false))
        })
      }

    const inventory = { productStock, getProductStock, setProductStock, deleteInventoryProduct,
        updateProductStockQty, getInventoryItems, savedInventoryData  }
  return inventory
}
