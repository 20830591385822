import React, { useState } from "react"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"
import "./vtpDatePicker.scss"

const VTPDatePicker = props => {
  const [startDate, setStartDate] = useState(props.initialDate?new Date(props.initialDate):new Date())
  const [selectedDate, setSelectedDate] = useState(
    !!props?.defaultValue
      ? new Date(props?.defaultValue)
      : props?.datepickerSelectedDate || startDate
  )

  let renderCustomHeader = null
  if (!!props?.dobYearsBefore) {
    const years = [...Array(props?.dobYearsBefore).keys()].map(
      i => i + new Date().getFullYear() - props?.dobYearsBefore + 1
    )
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]
    renderCustomHeader = ({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled
    }) => (
      <div
        style={{
          margin: 10,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <button
          type="button"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          {"<"}
        </button>
        <select
          value={date.getFullYear()}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <select
          value={months[date.getMonth()]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <button
          type="button"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          {">"}
        </button>
      </div>
    )
  }

  return (
    <DatePicker
      {...(!!props?.dobYearsBefore
        ? { renderCustomHeader: renderCustomHeader }
        : {})}
      {...props}
      selected={selectedDate}
      onChange={date => {
        setStartDate(date)
        props?.onChange(date)
        return setSelectedDate(date)
      }}
      dateFormat={props.dateFormat || "MM-dd-yyyy"}
    />
  )
}
export default VTPDatePicker
