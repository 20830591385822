import React, { useRef } from "react"
import { useTable, useExpanded, useSortBy } from "react-table"
import TableScrollbar from "react-table-scrollbar"
import InfiniteScroll from "react-infinite-scroller"
import { assets } from "../../../utils"
import { Loader } from "../Loader"
import "./vtpTable.scss"
import { Col } from "react-bootstrap"

function VTPTable({
  columns: userColumns,
  data,
  renderRowSubComponent,
  isLoading,
  rowsExpanded,
  height,
  isPagination,
  loadMoreHandler,
  hasMorevalues,
  initialSortKey,
  myClassName = '',
  id = '',
  isAsc,
  onRowClick = () => { }
}) {
  let scrollParentRef
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    customClass
  } = useTable(
    {
      columns: userColumns,
      initialState: {
        sortBy: [
          {
            id: initialSortKey || "",
            desc: isAsc || false,
          }
        ]
      },
      data: data || []
    },
    useSortBy
  )

  const Table = () => {
    return (
      <>
        {/* <Loader isLoading={isLoading} isCentered={true} /> */}
        <table id={id} className={myClassName} {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={() => column.toggleSortBy(!column.isSortedDesc)}
                  >
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img src={assets.ArrowDown} />
                        ) : (
                          <img src={assets.ArrowUp} />
                        )
                      ) : (
                        ""
                      )}
                    </span>{" "}
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row)
                return (
                  <React.Fragment key={i}>
                    <tr
                      className="tr-main"
                      {...row.getRowProps()}
                      onClick={() => onRowClick(row.original)}
                    >
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                    {row.isExpanded ? (
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          {renderRowSubComponent({ row })}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                )
              })
            ) : (!isLoading &&
              <Col className="text-center">
                <p
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                  }}
                >
                  No results found
                </p>
              </Col>
            )}
          </tbody>
        </table>
      </>
    )
  }

  return (
    <div
      style={{ height }}
      className={`${customClass} table-wrapper`}
      ref={node => {
        scrollParentRef = node
      }}
    >
      {!isPagination ? (
        <>
          <Table />
          <br />
        </>
      ) : (
        <>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMoreHandler}
            hasMore={hasMorevalues}
            useWindow={false}
            getScrollParent={scrollParentRef}
            initialLoad={false}
            threshold={20}
            style={{maxHeight:'inherit'}}
          >
            <Table />
          </InfiniteScroll>
          <br />
        </>
      )}
    </div>
  )
}

export default React.memo(VTPTable)
