import React from "react"

export const DashboardIcon = ({
  fill = "#A5A5A5",
  circleColor = "#E5E5E5",
  className = ""
}) => (
  <div className="logo-holder d-flex w-100 justify-content-center mx-auto">
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.5" cy="21.5" r="21" stroke={circleColor} />
      <path
        d="M13.822 20.3977H19.5758C19.7938 20.3977 20.0028 20.3111 20.157 20.157C20.3111 20.0028 20.3977 19.7938 20.3977 19.5758V13.822C20.3977 13.604 20.3111 13.3949 20.157 13.2407C20.0028 13.0866 19.7938 13 19.5758 13H13.822C13.604 13 13.3949 13.0866 13.2407 13.2407C13.0866 13.3949 13 13.604 13 13.822V19.5758C13 19.7938 13.0866 20.0028 13.2407 20.157C13.3949 20.3111 13.604 20.3977 13.822 20.3977ZM14.6439 14.6439H18.7538V18.7538H14.6439V14.6439Z"
        fill={fill}
      />
      <path
        d="M22.8636 20.3977H28.6174C28.8354 20.3977 29.0445 20.3111 29.1986 20.157C29.3528 20.0028 29.4394 19.7938 29.4394 19.5758V13.822C29.4394 13.604 29.3528 13.3949 29.1986 13.2407C29.0445 13.0866 28.8354 13 28.6174 13H22.8636C22.6456 13 22.4366 13.0866 22.2824 13.2407C22.1283 13.3949 22.0417 13.604 22.0417 13.822V19.5758C22.0417 19.7938 22.1283 20.0028 22.2824 20.157C22.4366 20.3111 22.6456 20.3977 22.8636 20.3977ZM23.6856 14.6439H27.7954V18.7538H23.6856V14.6439Z"
        fill={fill}
      />
      <path
        d="M13.822 29.4397H19.5758C19.7938 29.4397 20.0028 29.3531 20.157 29.199C20.3111 29.0448 20.3977 28.8358 20.3977 28.6178V22.864C20.3977 22.646 20.3111 22.4369 20.157 22.2827C20.0028 22.1286 19.7938 22.042 19.5758 22.042H13.822C13.604 22.042 13.3949 22.1286 13.2407 22.2827C13.0866 22.4369 13 22.646 13 22.864V28.6178C13 28.8358 13.0866 29.0448 13.2407 29.199C13.3949 29.3531 13.604 29.4397 13.822 29.4397ZM14.6439 23.6859H18.7538V27.7958H14.6439V23.6859Z"
        fill={fill}
      />
      <path
        d="M22.8636 29.4397H28.6174C28.8354 29.4397 29.0445 29.3531 29.1986 29.199C29.3528 29.0448 29.4394 28.8358 29.4394 28.6178V22.864C29.4394 22.646 29.3528 22.4369 29.1986 22.2827C29.0445 22.1286 28.8354 22.042 28.6174 22.042H22.8636C22.6456 22.042 22.4366 22.1286 22.2824 22.2827C22.1283 22.4369 22.0417 22.646 22.0417 22.864V28.6178C22.0417 28.8358 22.1283 29.0448 22.2824 29.199C22.4366 29.3531 22.6456 29.4397 22.8636 29.4397ZM23.6856 23.6859H27.7954V27.7958H23.6856V23.6859Z"
        fill={fill}
      />
    </svg>
  </div>
)

export const WalletIcon = ({
  className = "",
  fill = "#A5A5A5",
  circleColor = "#E5E5E5"
}) => (
  <div className="logo-holder d-flex w-100 justify-content-center mx-auto">
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="21.5" cy="21.5" r="21" stroke={circleColor} />
      <path
        d="M27.6174 15H14.4659C13.8119 15 13.1847 15.2598 12.7222 15.7222C12.2598 16.1847 12 16.8119 12 17.4659V25.6856C12 26.3396 12.2598 26.9668 12.7222 27.4293C13.1847 27.8917 13.8119 28.1515 14.4659 28.1515H27.6174C28.2714 28.1515 28.8986 27.8917 29.3611 27.4293C29.8235 26.9668 30.0833 26.3396 30.0833 25.6856V17.4659C30.0833 16.8119 29.8235 16.1847 29.3611 15.7222C28.8986 15.2598 28.2714 15 27.6174 15ZM14.4659 16.6439H27.6174C27.8354 16.6439 28.0445 16.7305 28.1987 16.8847C28.3528 17.0388 28.4394 17.2479 28.4394 17.4659V18.2879H13.6439V17.4659C13.6439 17.2479 13.7305 17.0388 13.8847 16.8847C14.0388 16.7305 14.2479 16.6439 14.4659 16.6439ZM27.6174 26.5076H14.4659C14.2479 26.5076 14.0388 26.421 13.8847 26.2668C13.7305 26.1127 13.6439 25.9036 13.6439 25.6856V21.5758H28.4394V25.6856C28.4394 25.9036 28.3528 26.1127 28.1987 26.2668C28.0445 26.421 27.8354 26.5076 27.6174 26.5076Z"
        fill={fill}
      />
    </svg>
  </div>
)

export const TransactionIcon = ({
  className = "",
  fill = "#A5A5A5",
  circleColor = "#E5E5E5"
}) => (
  <div className="logo-holder d-flex w-100 justify-content-center mx-auto">
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="21.5" cy="21.5" r="21" stroke={circleColor} />
      <path
        d="M14.233 30.2611C13.5521 30.2611 13 29.7096 13 29.0281V21.6304C13 20.9489 13.5521 20.3975 14.233 20.3975C14.9138 20.3975 15.4659 20.9489 15.4659 21.6304V29.0281C15.4659 29.7096 14.9138 30.2611 14.233 30.2611Z"
        fill={fill}
      />
      <path
        d="M19.1648 30.2614C18.4839 30.2614 17.9318 29.7099 17.9318 29.0284V14.233C17.9318 13.5515 18.4839 13 19.1648 13C19.8457 13 20.3977 13.5515 20.3977 14.233V29.0284C20.3977 29.7099 19.8457 30.2614 19.1648 30.2614Z"
        fill={fill}
      />
      <path
        d="M24.0966 30.261C23.4151 30.261 22.8636 29.7096 22.8636 29.0281V24.0962C22.8636 23.4147 23.4151 22.8633 24.0966 22.8633C24.7781 22.8633 25.3296 23.4147 25.3296 24.0962V29.0281C25.3296 29.7096 24.7781 30.261 24.0966 30.261Z"
        fill={fill}
      />
      <path
        d="M29.0284 30.2612C28.3469 30.2612 27.7955 29.7097 27.7955 29.0282V19.1646C27.7955 18.4831 28.3469 17.9316 29.0284 17.9316C29.7099 17.9316 30.2614 18.4831 30.2614 19.1646V29.0282C30.2614 29.7097 29.7099 30.2612 29.0284 30.2612Z"
        fill={fill}
      />
    </svg>
  </div>
)

export const ProfileIcon = ({
  className = "",
  fill = "#A5A5A5",
  circleColor = "#E5E5E5"
}) => (
  <div className="logo-holder d-flex w-100 justify-content-center mx-auto">
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.4852 21.4011C19.8411 21.4011 18.2755 20.6521 17.0765 19.2925C15.9759 18.0443 15.3444 16.4375 15.3444 14.8849C15.3444 13.3241 15.9914 11.8273 17.143 10.7236C18.2946 9.62002 19.8566 9 21.4852 9C23.1138 9 24.6758 9.62002 25.8274 10.7236C26.979 11.8273 27.626 13.3241 27.626 14.8849C27.626 16.4375 26.9945 18.0443 25.8938 19.2925C24.6949 20.6521 23.1293 21.4011 21.4852 21.4011ZM21.4852 11.2548C20.4809 11.2558 19.5181 11.6386 18.8079 12.3192C18.0978 12.9997 17.6983 13.9225 17.6972 14.8849C17.6972 15.8982 18.1367 17.0017 18.8736 17.8368C19.618 18.6815 20.5455 19.1446 21.4852 19.1446C22.4249 19.1446 23.3524 18.6797 24.0968 17.8368C24.8337 17.0017 25.2732 15.8977 25.2732 14.8849C25.2721 13.9225 24.8726 12.9997 24.1625 12.3192C23.4523 11.6386 22.4895 11.2558 21.4852 11.2548Z"
        fill={fill}
      />
      <path
        d="M31.6963 32.089C31.3843 32.089 31.0851 31.9702 30.8645 31.7588C30.6439 31.5473 30.5199 31.2606 30.5199 30.9616V28.3763C30.5199 26.1603 29.0819 24.7836 26.7672 24.7836H16.5326C14.2179 24.7836 12.7799 26.1603 12.7799 28.3763V30.9616C12.7799 31.2606 12.656 31.5473 12.4354 31.7588C12.2147 31.9702 11.9155 32.089 11.6035 32.089C11.2915 32.089 10.9923 31.9702 10.7717 31.7588C10.5511 31.5473 10.4271 31.2606 10.4271 30.9616V28.3763C10.4271 26.6748 11.0134 25.2142 12.1211 24.1522C13.2288 23.0902 14.7562 22.5288 16.5307 22.5288H26.7653C28.5412 22.5288 30.0658 23.0902 31.1749 24.1522C32.284 25.2142 32.8689 26.6748 32.8689 28.3763V30.9616C32.8689 31.26 32.7455 31.5462 32.5257 31.7575C32.3059 31.9688 32.0077 32.088 31.6963 32.089Z"
        fill={fill}
      />
      <circle cx="21.5" cy="21.5" r="21" stroke={circleColor} />
    </svg>
  </div>
)

export const AnalyticsIcon = ({ className = "", fill = "#393CDC" }) => (
  <div className="icon-wrap d-flex align-items-center justify-content-center">
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4105 6.0288C9.149 5.9358 8.864 6.0723 8.7725 6.3333C8.2085 7.9283 6.692 8.9998 5 8.9998C2.7945 8.9998 1 7.2058 1 4.9998C1 3.3078 2.0715 1.7913 3.6665 1.2273C3.927 1.1358 4.0635 0.8503 3.9715 0.5898C3.8795 0.3298 3.594 0.1928 3.3335 0.2853C1.3395 0.9898 0 2.8843 0 4.9998C0 7.7568 2.243 9.9998 5 9.9998C7.1155 9.9998 9.01 8.6608 9.715 6.6663C9.807 6.4063 9.671 6.1208 9.4105 6.0288ZM5.99985 4V1.0355C7.53035 1.2555 8.74435 2.469 8.96435 4H5.99985ZM5.49985 0C5.22385 0 4.99985 0.2235 4.99985 0.5V4.5C4.99985 4.7765 5.22385 5 5.49985 5H9.49985C9.77585 5 9.99985 4.7765 9.99985 4.5C9.99985 2.0185 7.98085 0 5.49985 0Z"
        fill="#343434"
      />
    </svg>
  </div>
)

export const UserIcon = ({ className = "", fill = "#4737DA" }) => (
  <div className="icon-wrap d-flex align-items-center justify-content-center">
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2.5C5 1.9485 4.5515 1.5 4 1.5C3.4485 1.5 3 1.9485 3 2.5C3 3.0515 3.4485 3.5 4 3.5C4.5515 3.5 5 3.0515 5 2.5ZM6 2.5C6 3.603 5.103 4.5 4 4.5C2.897 4.5 2 3.603 2 2.5C2 1.397 2.897 0.5 4 0.5C5.103 0.5 6 1.397 6 2.5ZM0.5 9C0.5 7.07 2.0705 5.5 4 5.5C5.9295 5.5 7.5 7.07 7.5 9C7.5 9.276 7.2765 9.5 7 9.5C6.7235 9.5 6.5 9.276 6.5 9C6.5 7.6215 5.3785 6.5 4 6.5C2.6215 6.5 1.5 7.6215 1.5 9C1.5 9.276 1.2765 9.5 1 9.5C0.7235 9.5 0.5 9.276 0.5 9Z"
        fill="#343434"
      />
    </svg>
  </div>
)

export const StoreIcon = ({ className = "", fill = "#393CDC" }) => (
  <div className="icon-wrap d-flex align-items-center justify-content-center">
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.191 6H3.382L2.5635 3H8.691L7.191 6ZM9.5415 2.474C9.358 2.177 9.04 2 8.691 2H2.291L1.9825 0.8685C1.923 0.651 1.7255 0.5 1.5 0.5H0.5C0.2235 0.5 0 0.724 0 1C0 1.276 0.2235 1.5 0.5 1.5H1.118L2.5175 6.6315C2.577 6.849 2.7745 7 3 7H7.5C7.6895 7 7.8625 6.893 7.9475 6.7235L9.5855 3.447C9.742 3.1345 9.725 2.771 9.5415 2.474ZM2.7501 8C2.3361 8 2.0001 8.3355 2.0001 8.75C2.0001 9.1645 2.3361 9.5 2.7501 9.5C3.1641 9.5 3.5001 9.1645 3.5001 8.75C3.5001 8.3355 3.1641 8 2.7501 8ZM7.0001 8.75C7.0001 8.3355 7.3361 8 7.7501 8C8.1641 8 8.5001 8.3355 8.5001 8.75C8.5001 9.1645 8.1641 9.5 7.7501 9.5C7.3361 9.5 7.0001 9.1645 7.0001 8.75Z"
        fill="#343434"
      />
    </svg>
  </div>
)

export const ProductIcon = ({ className = "", fill = "#4737DA" }) => (
  <div className="icon-wrap d-flex align-items-center justify-content-center">
    <svg
      width="4"
      height="10"
      viewBox="0 0 4 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.61803 1L1 2.23607V2.5H3V2.23607L2.38197 1H1.61803ZM1 9V3.5H3V9H1ZM1.30902 0C1.11963 0 0.946499 0.107001 0.861804 0.276393L0.0527864 1.89443C0.0180726 1.96385 0 2.04041 0 2.11803V9.5C0 9.77614 0.223858 10 0.5 10H3.5C3.77614 10 4 9.77614 4 9.5V2.11803C4 2.04041 3.98193 1.96386 3.94721 1.89443L3.1382 0.276393C3.0535 0.107001 2.88037 0 2.69098 0H1.30902ZM1.5 8.5H2.5V7.5H1.5V8.5Z"
        fill="#343434"
      />
    </svg>
  </div>
)

export const FeedbackIcon = ({ className = "", fill = "#4737DA" }) => (
  <div className="icon-wrap d-flex align-items-center justify-content-center">
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 4C2.5 3.724 2.724 3.5 3 3.5C3.276 3.5 3.5 3.724 3.5 4C3.5 4.276 3.276 4.5 3 4.5C2.724 4.5 2.5 4.276 2.5 4ZM5 3.5C4.724 3.5 4.5 3.724 4.5 4C4.5 4.276 4.724 4.5 5 4.5C5.276 4.5 5.5 4.276 5.5 4C5.5 3.724 5.276 3.5 5 3.5ZM7 3.5C6.724 3.5 6.5 3.724 6.5 4C6.5 4.276 6.724 4.5 7 4.5C7.276 4.5 7.5 4.276 7.5 4C7.5 3.724 7.276 3.5 7 3.5ZM9 6.5C9 6.7755 8.7755 7 8.5 7H3.277C3.0055 7 2.7385 7.074 2.505 7.214L1 8.117V1.5C1 1.2245 1.2245 1 1.5 1H8.5C8.7755 1 9 1.2245 9 1.5V6.5ZM8.5 0H1.5C0.673 0 0 0.673 0 1.5V9C0 9.18 0.097 9.3465 0.2535 9.435C0.33 9.4785 0.415 9.5 0.5 9.5C0.589 9.5 0.678 9.4765 0.7575 9.4285L3.0195 8.0715C3.0975 8.0245 3.1865 8 3.277 8H8.5C9.327 8 10 7.327 10 6.5V1.5C10 0.673 9.327 0 8.5 0Z"
        fill="#343434"
      />
    </svg>
  </div>
)

export const SignoutIcon = ({ className = "", fill = "#F24545" }) => (
  <div className="icon-wrap d-flex align-items-center justify-content-center">
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.3555 9.79788L14.1367 17.6729C13.4922 18.376 12.375 17.8838 12.375 16.876V12.376H6.53125C5.95977 12.376 5.5 11.8744 5.5 11.251V6.751C5.5 6.12756 5.95977 5.626 6.53125 5.626H12.375V1.126C12.375 0.122872 13.4879 -0.374003 14.1367 0.329122L21.3555 8.20413C21.7551 8.64475 21.7551 9.35725 21.3555 9.79788ZM8.25 17.4385V15.5635C8.25 15.2541 8.01797 15.001 7.73438 15.001H4.125C3.36445 15.001 2.75 14.3307 2.75 13.501V4.501C2.75 3.67131 3.36445 3.001 4.125 3.001H7.73438C8.01797 3.001 8.25 2.74787 8.25 2.4385V0.563497C8.25 0.254122 8.01797 0.000996965 7.73438 0.000996965H4.125C1.84766 0.000996965 0 2.01662 0 4.501V13.501C0 15.9854 1.84766 18.001 4.125 18.001H7.73438C8.01797 18.001 8.25 17.7479 8.25 17.4385Z"
        fill={fill}
      />
    </svg>
  </div>
)

export const TopArrowIcon = ({ className = "", fill = "#FFF" }) => (
  <svg
    width="12"
    height="5"
    viewBox="0 0 12 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.6124 0.45743C5.8442 0.291859 6.15558 0.291859 6.38738 0.45743L11.054 3.79076C11.3537 4.00477 11.4231 4.42114 11.209 4.72074C10.995 5.02035 10.5787 5.08975 10.2791 4.87574L5.99989 1.81919L1.72071 4.87574C1.42111 5.08975 1.00474 5.02035 0.790733 4.72074C0.576727 4.42114 0.646122 4.00477 0.94573 3.79076L5.6124 0.45743Z"
      fill="white"
    />
  </svg>
)

export const ManageItemsIcon = ({ className = "", fill = "#4737DA" }) => (
  <div className="icon-wrap d-flex align-items-center justify-content-center">
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.99985 6H2.49985C2.22385 6 1.99985 5.776 1.99985 5.5C1.99985 5.224 2.22385 5 2.49985 5H3.99985C4.27585 5 4.49985 5.224 4.49985 5.5C4.49985 5.776 4.27585 6 3.99985 6ZM1.99985 7.5C1.99985 7.224 2.22385 7 2.49985 7H5.49985C5.77635 7 5.99985 7.224 5.99985 7.5C5.99985 7.776 5.77635 8 5.49985 8H2.49985C2.22385 8 1.99985 7.776 1.99985 7.5ZM6.722 9H1.2775C1.1245 9 1 8.888 1 8.75V1.25C1 1.112 1.1245 1 1.2775 1H4V2.575C4 3.361 4.6085 4 5.357 4H7V8.75C7 8.888 6.8755 9 6.722 9ZM5 1.489L6.371 3H5.357C5.16 3 5 2.8095 5 2.575V1.489ZM7.87 3.164L5.148 0.164C5.053 0.0595 4.919 0 4.7775 0H1.2775C0.573 0 0 0.561 0 1.25V8.75C0 9.439 0.573 10 1.2775 10H6.722C7.4265 10 8 9.439 8 8.75V3.5C8 3.3755 7.9535 3.256 7.87 3.164Z"
        fill="#343434"
      />
    </svg>
  </div>
)
export const DealsIcon = ({ className = "", fill = "" }) => (
  <div className="icon-wrap d-flex align-items-center justify-content-center">
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99977 8.525C6.07927 8.525 6.15877 8.544 6.23127 8.5815L8.11577 9.5665L7.75727 7.488C7.72877 7.325 7.78327 7.159 7.90227 7.0435L9.42027 5.5755L7.31877 5.2695C7.15627 5.246 7.01577 5.1445 6.94277 4.9975L5.99977 3.1005L5.05677 4.9975C4.98377 5.1445 4.84327 5.246 4.68077 5.2695L2.57927 5.5755L4.09727 7.0435C4.21627 7.159 4.27077 7.325 4.24227 7.488L3.88377 9.5665L5.76827 8.5815C5.84077 8.544 5.92027 8.525 5.99977 8.525ZM8.78077 10.9775C8.70127 10.9775 8.62177 10.959 8.54927 10.921L5.99977 9.589L3.45027 10.921C3.28127 11.009 3.07727 10.9935 2.92477 10.8815C2.77077 10.7695 2.69427 10.58 2.72627 10.3925L3.21227 7.5785L1.15227 5.586C1.01477 5.4535 0.965773 5.2545 1.02427 5.073C1.08227 4.8915 1.23927 4.7595 1.42777 4.732L4.27827 4.318L5.55177 1.755C5.72077 1.415 6.27877 1.415 6.44777 1.755L7.72127 4.318L10.5718 4.732C10.7603 4.7595 10.9173 4.8915 10.9753 5.073C11.0338 5.2545 10.9848 5.4535 10.8473 5.586L8.78727 7.5785L9.27327 10.3925C9.30527 10.58 9.22877 10.7695 9.07477 10.8815C8.98827 10.9455 8.88477 10.9775 8.78077 10.9775Z" fill="#343434" />
    </svg>

  </div>
)
