import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../api"
import {
  MILLIGRAM_OPTIONS,
  NICOTINE_OPTIONS,
  PUFF_COUNT_OPTIONS,
  SORT_BY_OPTIONS,
  formatDateForDB,
  setCountryCode,
  sortRecord
} from "../utils"
import orders from "./Admin/ordersContext"
import Products from "./Admin/productsContext"
import Inventory from "./Admin/inventoryContext"
import Users from "./Admin/usersContext"
import Stores from "./Admin/storesContext"
import { useAuthContext } from "./AuthContext"
import Feedback from "./Admin/feedbackContext"

const AdminContext = createContext()

const AdminProvider = ({ children }) => {
  const [stores, setStores] = useState([])
  const [storePagination, setStorePagination] = useState({})
  const [productsPagination, setProductsPagination] = useState({})
  const [storeAdminSelectedStore, setStoreAdminSelectedStore] = useState(null)
  const [puffCountOptions, setPuffCountOptions] = useState([])
  const [milligramOptions, setMilligramOptions] = useState([])
  const [nicotineOptions, setNicotineOptions] = useState([])
  const [superAdminUsers, setSuperAdminUsers] = useState([])
  const [superAdminUsersData, setSuperAdminUsersData] = useState([])
  const { setIsLoading } = useAuthContext()

  useEffect(() => {
    // setSortByOptions(SORT_BY_OPTIONS)
    setPuffCountOptions(PUFF_COUNT_OPTIONS)
    setMilligramOptions(MILLIGRAM_OPTIONS)
    setNicotineOptions(NICOTINE_OPTIONS)
  }, [])

  //This is common, will be used by store and users
  const getAllStores = config => {
    setIsLoading(true)
    API.getStores(config)
      .then(response => {
        const paginationObj = {
          next: response.data.payload.next,
          prev: response.data.payload.previous,
          count: response.data.payload.count
        }
        setStorePagination(paginationObj)
        const sortedData = response.data.payload.results.sort((a,b)=>new Date(a.created_on).getTime()-new Date(b.created_on).getTime())
        setStores(sortedData)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  // ______________________________UTILITY Starts________________________

  const getUserStoreIds = () => {
    // console.log('storewa',JSON.parse(localStorage.getItem('user')))
    const stores = JSON.parse(localStorage.getItem("user"))?.user_store || []
    return stores
  }

  const filterUrlParams = url => {
    const urlObj = new URL(url)
    const limit = urlObj.searchParams.get("limit")
    const offset = urlObj.searchParams.get("offset")
    return { limit, offset }
  }

  // ______________________________UTILITY Ends________________________

  // _____________________________ createProductModal starts ___________________________
  const addBrand = vals => {
    const payload = {
      name: vals,
      active: true
    }

    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addBrand(payload)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addCategory = vals => {
    // const payload = {
    //   name: vals
    // }
    const payload = {
      ...vals
    }
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addCategory(payload)
        .then(response => {
          resolve(response.data.payload)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addMilligram = vals => {
    const payload = {
      gram: vals
    }

    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addMilligram(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addNicotinestrength = vals => {
    const payload = {
      nicotine: vals
    }

    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addNicotinestrength(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addFlavourtype = vals => {
    const payload = {
      name: vals
    }

    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addFlavourtype(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addFlavour = vals => {
    const payload = {
      name: vals?.flavour,
      flavourtype: vals?.flavourType
    }

    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addFlavour(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  // ___________________________________ createProductModal ends ______________________

  // unused users screen
  const editStoreAdmin = (id, payload) => {
    payload.user = payload.user
    payload.store = payload.store
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.editStoreAdmin(id, payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  // get store admin stores

  const getStoreAdminStores = () => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.getStoreAdminStores()
        .then(response => {
          console.log(response)
          resolve(response.data.payload)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  // createNewStore
  const getSuperAdminUsers = (config) => {
    setIsLoading(true)
    API.getSuperAdminUsers(config)
      .then(response => {
        setSuperAdminUsersData(response?.data?.payload)
        // pagination TBD
        setSuperAdminUsers(
          response.data.payload.results.map((el) => ({
            label: el.user?.name,
            value: el.user?.id,
            storeAdminId: el.id
          }))
        )
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  // Create Deal
  const createDeal = (payload) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.createDeal(payload)
        .then(response => {
          console.log(response)
          resolve(response.data.payload)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }
  // Past Deals
  const fetchDealHistory = (config) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.fetchDealHistory(config)
        .then(response => {
          console.log(response)
          resolve(response.data.payload)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }
  // set Deal status
  const setDealStatus = (id,payload) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.setDealStatus(id, payload)
        .then(response => {
          console.log(response)
          resolve(response.data.payload)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }
  const deleteDeal = (id) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteDeal(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  return (
    <AdminContext.Provider
      value={{
        stores,
        getAllStores,
        // productId,
        addBrand,
        addCategory,
        addMilligram,
        addNicotinestrength,
        addFlavourtype,
        addFlavour,
        editStoreAdmin,
        storePagination,
        productsPagination,
        getUserStoreIds,
        storeAdminSelectedStore,
        setStoreAdminSelectedStore,
        puffCountOptions,
        milligramOptions,
        nicotineOptions,
        superAdminUsers,
        getSuperAdminUsers,
        filterUrlParams,
        getStoreAdminStores,
        createDeal,
        fetchDealHistory,
        setDealStatus,
        deleteDeal,
        ...orders(),
        ...Products(),
        ...Inventory(),
        ...Users(),
        ...Stores(),
        ...Feedback()
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}

export const useAdminContext = () => useContext(AdminContext)

export default AdminProvider
