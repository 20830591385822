import { userRoleProfiles } from './utils/index';

export const APPCONFIG = {
  axiosBaseUrl:
    window.location.href.indexOf("staging") > -1 ||
      window.location.href.indexOf("localhost") > -1
      ? "https://vape-smoke-universe-38-staging.botics.co"
      : "https://solitary-bush-38352.botics.co",
  oneSignalAppId:
    window.location.href.indexOf("staging") > -1 ||
      window.location.href.indexOf("localhost") > -1
      ? "f47ae793-704c-4e71-b8ed-f52c549452fd"
      : "f47ae793-704c-4e71-b8ed-f52c549452fd",
  debugGlobal: localStorage.getItem("debugGlobal") || false,
  roles: [
    {
      value: userRoleProfiles.SUPERADMIN,
      label: "Super Admin"
    },
    {
      value: userRoleProfiles.STOREADMIN,
      label: "Store Admin"
    },
    {
      value: userRoleProfiles.CUSTOMER,
      label: "Customer"
    }
  ],
  api: {
    defaultPageSize: 20,
    variantOptionsPageSize: 99
  }
}
