import React, { useEffect, useMemo, useRef, useState } from "react"
import { Table } from "../../../components/Admin/VTPTable"
import { useAdminContext } from "../../../contexts/AdminContext"
import { SearchInput } from "../../../components/Admin/SearchInput"
import OrderPopup from "./orderPopup"
import "./orders.scss"
import { assets, convertDateTtime } from "../../../utils"
import CancelConfirmPopup from "../../../components/Admin/CancelConfirmPopup"

export default function Orders() {
  const {
    getStoreAdminOrders,
    getUserStoreIds,
    isLoading,
    storeAdminOrders,
    storeAdminSelectedStore,
    filterUrlParams,
    updateOrderStatus
  } = useAdminContext()
  const [orderList, setOrderList] = useState([])
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [completeOrderListResponse, setCompleteOrderListResponse] = useState({})
  const [searchText, setSearchText] = useState('');
  const [hasMoreValues, sethasMoreValues] = useState(true)
  const [userPaginated, setUserPaginated] = useState(false)
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const storeAdminSelectedStoreRef = useRef();
  storeAdminSelectedStoreRef.current = storeAdminSelectedStore;
  let debounceTimer;
  const configObj = {
    params: {
      search: "",
      limit: 20,
      offset: 0,
    }
  }
  const [orderListConfig, setOrderListConfig] = useState(configObj)
  const selectedStoreId =
    getUserStoreIds()[0]?.id || ""
  // const [storeId, setStoreId] = useState( selectedStoreId )
  // const [storeId, setStoreId] = useState(1)
  const [orderId, setorderId] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  let pollInterval;

  const cols = [
    {
      accessor: "id",
      Header: "Order ID",
      Cell: ({row}) =><div className={`color-${row.original.status}`}><span className={`order-indicator`}></span><span style={{color:'black'}}>{row.original.id}</span></div>
    },
    {
      accessor: "total_price",
      Header: "Total bill amount",
      Cell: ({ row }) => `$${(row.original.total_price +
        row.original.total_nicotine_tax +
        row.original.total_sales_tax -
        row.original.total_discount).toFixed(2)}`
    },
    {
      accessor: "customer",
      Header: "Customer Name",
    },
    {
      accessor: "created_on",
      Header: "Date & Time",
      Cell: ({ value }) => convertDateTtime(value)
    },
    {
      accessor: "order_status",
      Header: "Status",
      Cell: ({row}) => <span className={`color-${row.original.status}`}>{row.original.order_status}</span>
    },
    {
      Header:"Completed",
      Cell: ({row}) => <div>
        {row.original.status!=='CO' && <img src={assets.completeDealIcon} onClick={(e)=>{e.stopPropagation();markDealAsCompleted(row.original.id)}}/>}
      </div>
    }
  ]
  const columns = useMemo(() => cols, [])
  const rowData = useMemo(() => orderList, [storeAdminSelectedStore, orderList])

  const markDealAsCompleted = (orderId) => {
    setSelectedOrderId(orderId)
    setShowStatusPopup(true)
  }

  const handleLoadMore = () => {
    setUserPaginated(true)
    sethasMoreValues(false)
    if (completeOrderListResponse.next) {
      const config = { ...orderListConfig }
      const { limit, offset } = completeOrderListResponse.next
        ? filterUrlParams(completeOrderListResponse.next)
        : { limit: "", offset: "" }
      config.params.offset = offset;
      setOrderListConfig(config)
      sethasMoreValues(true)
    } else {
      sethasMoreValues(false)
    }
  }

  const getOrderHistory = () => {
    if (!!storeAdminSelectedStore) {
      getStoreAdminOrders(storeAdminSelectedStore?.id, orderListConfig).then(res => {
        if (res.next) {
          sethasMoreValues(true)
        }
        setCompleteOrderListResponse(res)
        // setOrderList([...orderList,...res.results])
        if(userPaginated){
          setOrderList([...orderList,...res.results])
        }else {
          setOrderList([...res.results])
        }
        setUserPaginated(false)
      })
    }
  }

  useEffect(()=>{
    console.log('this is order list',orderList)
  },[orderList])

  const initiateOrderPoll = () => {
    clearInterval(pollInterval);
    pollInterval = setInterval(() => {
      getOrderHistory()
    }, 300000)
  }

  useEffect(() => {
    if(storeAdminSelectedStore?.id || (storeAdminSelectedStoreRef.current?.id!==storeAdminSelectedStore?.id)){
    // re-initialise variables on store change
    setOrderListConfig(configObj);
    setUserPaginated(false);
    sethasMoreValues(true)
    // re-initialise ends
    }
  }, [storeAdminSelectedStore])

  useEffect(() => {
    // TODO: uncomment post API fix
    getOrderHistory();
    initiateOrderPoll();
    return () => {
      clearInterval(pollInterval)
    }
  }, [orderListConfig]);

  const onRowClickHandle = row => {
    setorderId(row.id)
    setSelectedOrder(row)
    setIsModalOpen(true)
  }
  const debounceFunction = (event) => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      setOrderListConfig({ ...orderListConfig, params: { ...orderListConfig.params, search: event } })
    }, 1000)
  }
  const handleChangeSearchText = (event) => {
    debounceFunction(event)
  }
  const handleModalClose = () => {
    setSelectedOrder(null)
    setIsModalOpen(false)
  }

  const handleOrderRefresh = (value) => {
    getOrderHistory();
    // const orders = [...orderList];
    // orders[orders.findIndex((order)=>order.id===value.id)] = value;
    // setOrderList(orders)
  }

  
  const handleConfirmation = () => {
    const payload = {status:"CO"}
    updateOrderStatus(storeAdminSelectedStore?.id,selectedOrderId,payload).then(resp=>{
      getOrderHistory()
    })
  }

  return (
    <>
      <header className="head gradient-bg d-flex justify-content-center flex-column  position-relative py-3">
        <div className="d-flex justify-content-between w-100 align-items-center px-4">
          <div className="hello-user-card d-flex align-items-center justify-content-start">
            <span>
              <h3>Orders</h3>
            </span>
          </div>
        </div>
        <div
          style={{ height: "50px", marginBottom: "10px" }}
          className="d-flex justify-content-between align-items-center px-4"
        >
          <SearchInput onChangeText={handleChangeSearchText} />
        </div>
        <div className="px-3 store-admin-orders">
          <Table
            columns={columns}
            data={rowData}
            isLoading={isLoading}
            isPagination={true}
            loadMoreHandler={handleLoadMore}
            hasMorevalues={hasMoreValues}
            height="82vh"
            initialSortKey='created_on'
            isAsc={true}
            onRowClick={onRowClickHandle}
            myClassName='order-list'
          />
        </div>
        {/* order popup starts */}
        {isModalOpen &&
          <OrderPopup
            order={selectedOrder}
            label={`Order - ${selectedOrder?.id}`}
            store_id={storeAdminSelectedStore?.id}
            order_id={orderId}
            show={isModalOpen}
            onHide={handleModalClose}
            refreshorderdetail={handleOrderRefresh}
            dialogClassName={"order-popup-modal"}
          />
        }
        
      <CancelConfirmPopup
          label={'Are you sure You want to mark the order as completed'}
          showTitle={false}
          show={showStatusPopup}
          setShow={setShowStatusPopup}
          onClickCancel={handleConfirmation}
          completeDeal={true}
        />
      </header>
    </>
  )
}
