import React, { useState } from 'react';
import { API } from '../../api';
import { useAuthContext } from '../AuthContext';
import {
    setCountryCode
  } from "../../utils"

export default function Users() {
    const [users, setUsers] = useState([])
    const [userPagination, setUserPagination] = useState({})
    const { setIsLoading } = useAuthContext();

    const filterUrlParams = url => {
        const urlObj = new URL(url)
        const limit = urlObj.searchParams.get("limit")
        const offset = urlObj.searchParams.get("offset")
        return { limit, offset }
      }

    const getAllUsers = async (config, retainPrevious = false) => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.getUsers(config, null).then(response => {
            const data = response.data.payload
            const allUsers = data.results?.map(el =>
              JSON.parse(JSON.stringify(el), (key, value) =>
                value === null ? "-" : value
              )
            )
            // maintaining pagination Object to get next and prevous values
            const { limit, offset } = data.next
              ? filterUrlParams(data.next)
              : { limit: "", offset: "" }
            const paginationObj = {
              limit,
              offset,
              next: data.next
                ? data.next.replace(new URL(data.next).origin, "")
                : "",
              prev: data.previous
                ? data.previous.replace(new URL(data.previous).origin, "")
                : "",
              count: data.count
            }
            if (!retainPrevious) {
              setUserPagination(paginationObj)
              setTimeout(() => {
                setUsers([...allUsers])
                resolve([...allUsers])
              }, 0)
            } else {
              setUserPagination(paginationObj)
              setTimeout(() => {
                setUsers([...users, ...allUsers])
                resolve([...users, ...allUsers])
              }, 0)
            }
          })
        })
          .catch(error => console.log(error))
          .finally(() => setIsLoading(false))
      }

      const addUser = vals => {
        const payload = { ...vals, phone_number:setCountryCode(vals.phone_number) }
        // const { role, phone_number, name, email } = vals
        // payload.role = role.value
        // payload.phone_number = setCountryCode(phone_number)
        // payload.name = name
        // payload.email = email
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.addUser(payload)
            .then(response => {
              resolve(response.data.payload)
            })
            .catch(error => {reject(error)})
            .finally(() => setIsLoading(false))
        })
      }

      const editUser = (id, payload) => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.editUser(id, payload)
            .then(response => {
              resolve(response.data.payload)
            })
            .catch(error => reject(error))
            .finally(() => setIsLoading(false))
        })
      }

      const flagUser = (id, payload) => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.flagUser(id, payload)
            .then(response => {
              resolve(response.data.payload)
            })
            .catch(error => reject(error))
            .finally(() => setIsLoading(false))
        })
      }

      const deleteUser = id => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.deleteUser(id)
            .then(response => {
              resolve(response.data.data)
            })
            .catch(error => console.log(error))
            .finally(() => setIsLoading(false))
        })
      }
      
      const addStoreAdmin = (userid, storeid) => {
        const payload = {
          user: userid,
          store: storeid
        }
    
        setIsLoading(true)
        return new Promise((resolve, reject) => {
          API.addStoreAdmin(payload)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error))
            .finally(() => setIsLoading(false))
        })
      }

    const usersContext = { users, getAllUsers, userPagination, addUser, editUser, flagUser, deleteUser,
        addStoreAdmin }
    return usersContext;
}