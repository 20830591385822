import React, { useEffect, useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import Card from "../../../components/Admin/containers/card"
import { useForm } from "react-hook-form"
import { useAuthContext } from "../../../contexts/AuthContext"
import CenteredContainer, {
  OneHalf
} from "../../../components/Admin/containers/centered-container"
import InputField from "../../../components/Admin/inputField"
import {
  getPayLoadDataFunction,
  LocalStorage,
  userRoleProfiles,
  validation
} from "../../../utils";
import "./login.scss"
import { InputPassword } from "../../../components/Admin/inputPassword"
import { SubmitButton } from "../../../components/Admin/elements/form/submit-button"
import ErrorAlert from "../../../components/ErrorPopup"
import { validatePassword } from "../../../utils/Validation"

const LoginScreen = () => {
  const nav = useNavigate()
  const { isLoading, userLogin, userIs, isLoggedInUser } = useAuthContext();
  const {
    control,
    register,
    watch,
    formState: { errors: formErrors, dirtyFields },
    trigger,
    handleSubmit
  } = useForm()
  const [errors, setErrors] = useState({
    password: {
      error: false,
      message: ""
    }
  })
  const password = watch("password")

  useEffect(()=>{
    if(isLoggedInUser){
      nav('/admin/dashboard')
    }
  },[])

  useEffect(() => {
    if (formErrors?.password?.message) {
      setErrors(prevErrors => ({
        ...prevErrors
      }))
    }
  }, [handleSubmit, formErrors, dirtyFields])

  const handleNavigation = (res) => {
    if (res.role === userRoleProfiles.SUPERADMIN) {
      nav("/admin/dashboard")
    } else if (res.role === userRoleProfiles.STOREADMIN) {
      nav("/admin/dashboard/orders")
    } else {
      alert("You are not authorized to visit this site.")
    }
  }

  const onSubmit = async vals => {
    userLogin(vals).then(res => {
      setTimeout(()=> {
        handleNavigation(res)
      },0)
    })
  }

  return (
    <CenteredContainer screenHeight={true} className="gradient-bg">
      <Card whiteBg={false}>
        <h3 className="text-center mb-5">Welcome to Admin Dashboard</h3>
        <form className={"login"} onSubmit={handleSubmit(onSubmit)}>
          {/* <Row className="justify-content-center"> */}
          {/* <Col xs={12}> */}
          <InputField
            label="Email"
            name="email"
            placeholder=""
            rules={{
              required: "Email required!",
              validate: { ...validation.email }
            }}
            compType=""
            control={control}
            trigger={trigger}
            className="applicationScreenInputAdmin"
          />
          {/* </Col> */}
          {/* <Col xs="12"> */}
          <InputPassword
            label="Password"
            register={register}
            name="password"
            placeholder=""
            error={errors["password"]}
            className="applicationScreenInputAdmin"
          />
          {/* </Col> */}
          <p className="m-0 text-end">
            <Link className="forgotPasswordText" to="/admin/reset-password">
              Forgot Password
            </Link>
          </p>

          <SubmitButton />
        </form>
      </Card>
    </CenteredContainer>
  )
}

export default LoginScreen
