import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import CreateDeal from './createDeal/createDeal'
import ViewDeals from './viewDeals/viewDeals'
import { useAdminContext } from '../../../contexts/AdminContext'
import './deals.scss';

export default function Deals() {
    const [pastDeals, setPastDeals] = useState([])
    const { createDeal, fetchDealHistory } = useAdminContext();
    const [showNotification, setShowNotification] = useState('')
    const [dealConfigs, setDealConfigs] = useState({})

    useEffect(() => {
        getPastDeals({})
    }, [])

    const getPastDeals = (config = {}, retainPrevious = false) => {
        fetchDealHistory(config).then(resp => {
            if (!retainPrevious) {
                const sorted = resp.results.sort((a,b)=>new Date(a.created_on).getTime()<new Date(b.created_on).getTime()?1:-1)
                setPastDeals(sorted)
            } else {
                const sorted = [...dealConfigs.results, resp.results].sort((a,b)=>new Date(a.created_on).getTime()<new Date(b.created_on).getTime()?1:-1)
                setPastDeals(sorted)
            }
            setDealConfigs(resp);
        })
    }

    const handleDealCreation = (data) => {
        createDeal(data).then(resp => {
            console.log(resp);
            displayNotification("Deal created successfully")
            getPastDeals();
        })
    }

    const displayNotification = (message) => {
        setShowNotification(message)
        setTimeout(() => {
            setShowNotification('')
        }, [3000])
    }

    return (
        <div className="deals-container" style={{ height: '100%' }}>
            <header className="head gradient-bg d-flex justify-content-center flex-column  position-relative py-3">
                <div className="d-flex justify-content-between w-100 align-items-center px-4">
                    <div className="hello-user-card d-flex align-items-center justify-content-start">
                        <span>
                            <h3>Deals</h3>
                        </span>
                    </div>
                </div>
                {!!showNotification.length && <div className='notification-box'>{showNotification}</div>}
            </header>
            <Row className='deal-content-wrapper'>
                <Col xs={12} lg={4} className='new-deal-wrapper'>
                    <CreateDeal handleDealCreation={handleDealCreation} />
                </Col>
                <Col xs={12} lg={8} className='past-deal-wrapper'>
                    <ViewDeals pastDeals={pastDeals} fetchDeals={getPastDeals}
                        dealConfigs={dealConfigs} />
                </Col>
            </Row>
        </div>
    )
}
