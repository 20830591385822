import React from "react"
import { Nav } from "react-bootstrap"
import "./manageItems.scss"

export default function ListItems({ variationList = [], setSelectedEvent }) {
  return (
    <div className="list-items">
      <h6>List Items</h6>
      <Nav variant="pills" defaultActiveKey="/home" className="flex-column">
        {variationList.map(({ label, value, parent, variationType }, index) => (
          <Nav.Link
            key={index}
            eventKey={label}
            onClick={() => setSelectedEvent(value, parent, variationType)}
          >
            {label}
          </Nav.Link>
        ))}
      </Nav>
    </div>
  )
}
