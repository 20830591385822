import React, { useEffect, useState } from 'react'
import { SearchInput } from '../../../../components/Admin/SearchInput';
import { useAdminContext } from '../../../../contexts/AdminContext';
import './createProduct.scss';

function CompatibleProducts({ actionType, productDetails, handleCompatibleProducts }) {
    const [productsSuggestion, setProductsSuggestion] = useState([])
    const [products, setProducts] = useState([])
    const [compProducts, setCompProducts] = useState([])
    const [searchedProduct, setSearchedProduct] = useState('')
    const { getProducts } = useAdminContext();

    useEffect(() => {
        if (actionType === 'edit') {
            console.log('received product details', productDetails)
            if (productDetails.id && productDetails.compatible_products.length) {
                let listItems = productDetails.compatible_products.map(el => (
                    {
                        id: el.id,
                        name: `${el.name} - ${el.brand} - (${el.category})`,
                        chipDisplayName: `${el.name}`
                    }
                ))
                setCompProducts(listItems)
            }
        }
    }, [productDetails])

    useEffect(() => {
        let listItems = products.map(el => (
            {
                id: el.id,
                name: `${el.name} - ${el.brand.name} - (${el.category?.name})`,
                chipDisplayName: el.name
            }
        ))
        const existing = compProducts.map(el => el.id)
        listItems = listItems.filter(prod => !existing.includes(prod.id))
        setProductsSuggestion(listItems)
    }, [products])

    const debounce = (func, wait) => {
        let timeout
        return function executedFunction(...args) {
            const later = () => {
                timeout = null
                func(...args)
            }
            clearTimeout(timeout)
            timeout = setTimeout(later, wait)
        }
    }

    const onChangeText = debounce(text => {
        setSearchedProduct(text)
    }, 1000)

    useEffect(() => {
        if (searchedProduct.length) {
            getSuggesstions()
        }
    }, [searchedProduct])

    useEffect(() => {
        handleCompatibleProducts(compProducts)
    }, [compProducts])

    const getSuggesstions = () => {
        const config = {
            params: {
                limit: 1000,
                offset: 0,
                search: searchedProduct
            }
        }
        if (searchedProduct.length >= 3) {
            getProducts(config, (resp) => {
                setProducts(resp)
            })
        }
    }

    const handleProductSelection = (e, product) => {
        let newList = [...compProducts, product]
        setCompProducts(newList);
        setProductsSuggestion([])
    }

    const handleRemoveProduct = (product) => {
        const newProducts = compProducts.filter(item => item.id !== product.id);
        setCompProducts(newProducts)
    }

    const handleSearchInputBlur = () => {
        setSearchedProduct('')
        setTimeout(() => setProductsSuggestion([]), 500)
    }
    return (
        <div className='compatible-products-wrapper'>
            <div className='input-box'>
                <div className='input'>
                    {/* <input value={inputVal} type='text' onChange={handelInputChange}/> */}
                    <SearchInput
                        onBlurHandler={() => handleSearchInputBlur()}
                        // onFocusHandler={()=>getSuggesstions()}
                        onChangeText={onChangeText} />
                </div>
                {!!productsSuggestion.length && <div className='list'>
                    <ul>
                        {productsSuggestion.map(product =>
                            <li key={product.id} onClick={(e) => handleProductSelection(e, product)}>{product.name}</li>
                        )}
                    </ul>
                </div>}
            </div>
            <div className='chips-area'>
                {!!compProducts.length &&
                    <>{compProducts.map(prod =>
                        <div className="product-chip" key={prod.id}>
                            <span style={{ marginRight: '22px' }}>{prod.chipDisplayName}</span>
                            <span className='cross-icon' onClick={() => handleRemoveProduct(prod)}>x</span>
                        </div>
                    )}</>
                }
            </div>
        </div>
    )
}

export default React.memo(CompatibleProducts)