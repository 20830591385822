import React, { useEffect, useMemo, useState } from 'react';
import { Table } from '../../../../components/Admin/VTPTable';
import { useAdminContext } from '../../../../contexts/AdminContext';
import { Form } from 'react-bootstrap';
import { assets } from '../../../../utils';
import './viewDeals.scss'
import { SearchInput } from '../../../../components/Admin/SearchInput';

function ViewDeals({ pastDeals, fetchDeals, dealConfigs }) {
  // const [allPastDeals,setAllPastDeals] = useState(pastDeals)
  const { isLoading, setDealStatus, deleteDeal } = useAdminContext();
  const [hasMoreValues, sethasMoreValues] = useState(true)

  // useEffect(()=>{
  //   setAllPastDeals(pastDeals)
  // },[pastDeals])

  const filterUrlParams = url => {
    const urlObj = new URL(url)
    const limit = urlObj.searchParams.get("limit")
    const offset = urlObj.searchParams.get("offset")
    return { limit, offset }
  }

  const ActionItems = React.memo(({ rowData, fetchDeals }) => {
    const { id } = rowData;
    const [active, setActive] = useState(rowData.active)

    const handleDealStatus = () => {
      const payload = { active: !active }
      setDealStatus(id, payload).then(resp => {
        setActive(resp?.active)
        fetchDeals()
      })
    }
    const deleteADeal = () => {
      deleteDeal(id).then(resp => {
        console.log('Deal deleted')
        fetchDeals()
        // let newDeals = [...allPastDeals]
        // newDeals = newDeals.filter(deal=>deal.id!==id);
        // setAllPastDeals(newDeals)
      })
    }
    return <div className='past-deals-actions' style={{ display: 'flex' }}>
      <span>
        <img className='delete-icon' onClick={deleteADeal} src={assets.DeleteIcon} />
      </span>
      <span>
        <Form.Check
          type="switch"
          id="custom-switch-deals"
          label=""
          className="ms-4"
          checked={active}
          onChange={handleDealStatus}
        />
      </span>
    </div>
  })
  const cols = [
    {
      accessor: "product.name",
      Header: "Product Name"
    },
    {
      accessor: "message",
      Header: "Deal"
    },
    {
      accessor: "start_date",
      Header: "Start Date"
    },
    {
      accessor: "end_date",
      Header: "End Date"
    },
    {
      accessor: "",
      Header: "Actions",
      Cell: ({ row }) => <ActionItems fetchDeals={() => fetchDeals()} rowData={row.original} />
    }
  ]

  const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  // useEffect(()=>{
  //   getPastDeals().then(resp=>{
  //     const deals = resp.results;
  //     setPastDeals(deals)
  //   })
  // },[])
  const columns = useMemo(() => cols, [])
  const rowData = useMemo(() => pastDeals, [pastDeals])

  const handleLoadMore = () => {
    sethasMoreValues(false)
    if (dealConfigs.next) {
      const config = { params: { limit: '', offset: '' } }
      const { limit, offset } = dealConfigs.next
        ? filterUrlParams(dealConfigs.next)
        : { limit: "", offset: "" }
      config.params.offset = limit
      config.params.limit = offset
      fetchDeals(config,true)
      sethasMoreValues(true)
    } else {
      sethasMoreValues(false)
    }
  }

  const onChangeText = debounce(text => {
    // setSearchText(text)
    const config = { params: { search: '' } }
    config.params.search = text.toLowerCase()
    fetchDeals(config)
  }, 300)

  return (
    <div className='past-deals-wrapper'>
      <div className='past-deals-header'>
        <div className='past-deal-heading'>
          Past Deals
        </div>
        <SearchInput onChangeText={onChangeText} />
      </div>
      <div className='past-deals-table'>
        <Table
          columns={columns}
          data={rowData}
          isLoading={isLoading}
          isPagination={true}
          loadMoreHandler={handleLoadMore}
          hasMorevalues={hasMoreValues}
          height="75vh"
          // initialSortKey='created_on'
          isAsc={true}
          // onRowClick={onRowClickHandle}
          myClassName='past-deals-list'
        />
      </div>
    </div>
  )
}

export default React.memo(ViewDeals)
