import { validatePassword } from "./Validation"
import { useLayoutEffect, useState } from "react"

import logo from "../assets/images/logo.svg"
import eyeRegular from "../assets/images/eyeRegular.svg"
import eyeClosed from "../assets/images/eyeClosed.svg"
import backArrow from "../assets/images/backArrow.svg"
import search from "../assets/images/search.svg"
import heart from "../assets/images/heart.svg"
import storeLogo from "../assets/images/store_logo.svg"
import filterIcon from "../assets/images/filter_icon.svg"
import iconGear from "../assets/images/settings.svg"
import profileIcon from "../assets/images/profile.svg"
import fileUploadIcon from "../assets/images/file_upload_icon.svg"
import profileRedIcon from "../assets/images/profile__icon_red.svg"
import VapeLogo from "../assets/icons/vape_logo.svg"
import DummyAvatar from "../assets/icons/dummy_avatar.svg"
import FlagIcon from "../assets/icons/flag.svg"
import EditIcon from "../assets/icons/edit.svg"
import DeleteIcon from "../assets/icons/delete.svg"
import PlusIcon from "../assets/icons/plus.svg"
import SearchIcon from "../assets/icons/search.svg"
import ArrowDown from "../assets/icons/arrow_down.svg"
import ArrowUp from "../assets/icons/arrow_up.svg"
import EyeClosedIcon from "../assets/icons/eye_closed.svg"
import EyeRegularIcon from "../assets/icons/eye_regular.svg"
import FlagFilledIcon from "../assets/icons/flag_filled.svg"
import forwardArrow from "../assets/images/forwardArrow.svg"
import UploadIcon from "../assets/icons/upload.svg"
import downArrow from "../assets/images/downArrow.svg"
import SendIcon from "../assets/icons/send.svg"
import marker from "../assets/images/marker.svg"
import createPhotoIcon from "../assets/icons/createPhoto.svg"
import verifiedIcon from "../assets/icons/verifiedIcon.svg"
import banIcon from "../assets/icons/banIcon.svg"
import cartIcon from "../assets/images/cart_icon.svg"
import heartBlack from "../assets/images/heart_black.svg"
import minusIcon from "../assets/images/minus_icon.svg"
import plusIcon from "../assets/images/plus_icon.svg"
import smallCircle from "../assets/images/small_circle.svg"
import backArrowWhite from "../assets/images/backArrowWhite.svg"
import watchIcon from "../assets/images/watch.svg"
import heartFilledIcon from "../assets/images/heart_filled.svg"
import cartDeleteIcon from "../assets/images/cart_delete_icon.svg"
import cartPlusIcon from "../assets/images/cart_plus_icon.svg"
import repliedIcon from "../assets/images/replied_icon.svg"
import storeDefaultIcon from "../assets/images/store_default_icon.svg"
import chevronDown from "../assets/images/chevron-down.svg"
import VerifiedUser from "../assets/images/verified-user.svg"
import profileDefaultIcon from "../assets/images/default-profile-img.svg"
import saveIcon from "../assets/images/save-icon.svg"
import emailIcon from "../assets/images/email-icon.svg"
import chevron from "../assets/icons/chevron.svg"
import CheckIcon from "../assets/icons/check.svg"
import addImage from "../assets/icons/image-add.svg"
import restock from "../assets/icons/restock.svg"
import calendar from "../assets/icons/calendar.svg"
import completeDealIcon from "../assets/icons/complete-deal_icon.svg"

export const assets = {
  logo,
  eyeRegular,
  eyeClosed,
  backArrow,
  search,
  heart,
  storeLogo,
  filterIcon,
  iconGear,
  profileIcon,
  fileUploadIcon,
  profileRedIcon,
  forwardArrow,
  downArrow,
  marker,
  cartIcon,
  heartBlack,
  minusIcon,
  plusIcon,
  smallCircle,
  backArrowWhite,
  watchIcon,
  heartFilledIcon,
  cartPlusIcon,
  cartDeleteIcon,
  storeDefaultIcon,
  //admin
  VapeLogo,
  DummyAvatar,
  FlagIcon,
  EditIcon,
  DeleteIcon,
  PlusIcon,
  SearchIcon,
  ArrowDown,
  ArrowUp,
  EyeClosedIcon,
  EyeRegularIcon,
  FlagFilledIcon,
  UploadIcon,
  SendIcon,
  createPhotoIcon,
  verifiedIcon,
  banIcon,
  repliedIcon,
  chevronDown,
  VerifiedUser,
  profileDefaultIcon,
  saveIcon,
  emailIcon,
  chevron,
  CheckIcon,
  addImage,
  restock,
  calendar,
  completeDealIcon
}

export const SCREEN_SIZES = {
  DESKTOP: "desktop",
  TAB: "tab",
  MOBILE: "mobile"
}

export const getUserID = (user) => {
  switch(user) {
    case 'superadmin':
      return 'SUPA'
    case 'customer':
      return 'CUST'
    case 'storeadmin':
      return 'STAD'
    default:
      return ''
  }
}
export const getCompleteRole = (role) => {
  switch(role) {
    case 'SUPA':
      return 'Super Admin'
    case 'CUST':
      return 'Customer'
    case 'STAD':
      return 'Store Admin'
    default:
      return ''
  }
}

export const userRoleProfiles = {
  ADMIN:'',
  SUBADMIN:'SUAD',
  SUPERADMIN:'SUPA',
  CUSTOMER:'CUST',
  STOREADMIN:'STAD'
}

export const convertDateTtime = (input) => {
  const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  const formatDate = (value) => {
    const val = new Date(value)
    let time = formatAMPM(val)
    let date = val.toLocaleDateString()
    return `${date} ${time}`
  }
  return formatDate(input)
}

export const formatDateYYYYMMDD = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export const useWindowSize = () => {
  const [screenSize, setScreenSize] = useState(SCREEN_SIZES.DESKTOP)
  useLayoutEffect(() => {
    function updateSize() {
      if (window.matchMedia("(max-width: 767px)").matches) {
        setScreenSize(SCREEN_SIZES.MOBILE)
      } else if (
        window.matchMedia("(min-width: 768px) and (max-width: 992px)").matches
      ) {
        setScreenSize(SCREEN_SIZES.TAB)
      } else {
        setScreenSize(SCREEN_SIZES.DESKTOP)
      }
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])
  return screenSize
}

export const ensureLoggedIn = () => {
  const sessionToken = localStorage.getItem("token")
  return !!sessionToken
}

export function toggleMainNavMenu(toggle) {
  if (toggle === "open") {
    document.body.classList.add("show-nav-bar__main")
  } else {
    document.body.classList.remove("show-nav-bar__main")
  }
}

export const formatPhoneNumber = (phoneNumberString) => {
  if(phoneNumberString){
  const countryCode = phoneNumberString.slice(0,phoneNumberString.length-10)
  const phoneString = phoneNumberString.length>10? phoneNumberString.slice(-10):phoneNumberString
  const cleaned = ('' + phoneString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}else {
  return ''
}
}

const regex = {
  internationalPhone:
    /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  name: /^[a-zA-Z][a-zA-Z ]+/
}

export const UserTypes = {
  serviceProvider: 3,
  owner: 4,
  tenant: 1,
  carrier: 2
}

export const UserEnum = {
  TENANT: 1,
  CARRIER: 2,
  serviceProvider: 3,
  Owner: 4,
  ADMIN: 5
}

export const getUserTypeStringFromEnum = _enum => {
  switch (_enum) {
    case 1:
      return "Tenant"
    case 2:
      return "Carrier"
    case 3:
      return "Service Provider"
    case 4:
      return "Owner"
    case 5:
      return "Admin"
  }
}

export const validation = {
  //** do not name the method for matching against regex as pattern as it seems to create name conflict with useform library **
  username: {
    match: username =>
      regex.email.test(username) ||
      regex.internationalPhone.test(username) ||
      "Not a valid phone/email"
  },

  name: {
    match: name =>
      !name ||
      regex.name.test(name) ||
      (name.length < 3 ? "Too short" : "Invalid name")
  },

  email: {
    match: email => !email || regex.email.test(email) || "Invalid email address"
  },

  password: {
    match: password =>
      !password || validatePassword(password) || "Invalid password address"
  },

  gender: {
    match: gender =>
      !gender ||
      /^male$|^Male$|^female$|^Female$/.test(gender) ||
      "Invalid gender"
  },

  nameMax30: {
    match: name =>
      !name ||
      /^([a-zA-Z, .'-]){3,30}$/i.test(name) ||
      (name.length < 3
        ? "Too short"
        : name.length > 30
        ? "Too long"
        : "Invalid name")
  },

  nameMax40: {
    match: name =>
      !name ||
      /^([a-zA-Z, .'-]){3,40}$/i.test(name) ||
      (name.length < 3
        ? "Too short"
        : name.length > 40
        ? "Too long"
        : "Invalid name")
  },

  email: {
    match: email => !email || regex.email.test(email) || "Invalid email address"
  },

  number: {
    match: number => !number || /^\d+(\.\d+)?$/.test(number) || "Invalid number"
  },

  salary: {
    match: number => {
      if (!number || !/^\d+(\.\d+)?$/.test(number)) {
        return "Invalid number"
      } else if (number && /^\d+(\.\d+)?$/.test(number) && number <= 800) {
        return "Net monthly income should be greater than $800"
      }
    }
  },

  phone: {
    us: phoneNumber =>
      !phoneNumber ||
      /^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
        phoneNumber
      ) ||
      "Invalid phone number"
  },

  routingNumber: {
    us: routingNumber =>
      !routingNumber ||
      /^[0-9]{9}$/.test(routingNumber) ||
      "Invalid routing number"
  },

  bankAccountNumber: {
    us: bankAccountNumber =>
      !bankAccountNumber ||
      /^[0-9]{8,17}$/.test(bankAccountNumber) ||
      "Bank account number should be 8-17 digits long"
  },

  ssn: {
    us: ssn =>
      !ssn ||
      /^(?!(000|666|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/.test(ssn) ||
      "Invalid SSN"
  },

  federalTaxID: {
    us: id =>
      !id ||
      /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/.test(
        id
      ) ||
      "Invalid Federal Tax ID Number"
  },

  street: {
    us: street =>
      !street || /^[a-zA-Z0-9\s,'-]*$/.test(street) || "Invalid address"
  },

  city: {
    us: city =>
      !city || /^[A-Z][a-z]+(?: +[A-Z][a-z]+)*/.test(city) || "Invalid city"
  },

  state: {
    us: state =>
      !state || /^[A-Z][a-z]+(?: +[A-Z][a-z]+)*/.test(state) || "Invalid state"
  },

  zip: {
    us: zipCode =>
      !zipCode || /^[0-9]{5}(?:-[0-9]{4})?$/.test(zipCode) || "Invalid zip code"
  },

  dob: {
    notToday: dateInput => {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      return (
        !dateInput ||
        new Date(dateInput).getTime() < today.getTime() ||
        "Please enter your date of birth"
      )
    }
  },

  amount: {
    us: amount =>
      !amount ||
      (/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(amount) &&
        amount > 0 &&
        amount <= 5000) ||
      "Please enter amount greater than $1 and less than $5000"
  },

  fax: {
    us: faxNumber =>
      !faxNumber ||
      /^(\+?\d{1,}(\s?|\-?)\d*(\s?|\-?)\(?\d{2,}\)?(\s?|\-?)\d{3,}\s?\d{3,})$/.test(
        faxNumber
      ) ||
      "Invalid fax number"
  },

  webSiteURL: {
    us: url =>
      !url ||
      /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(
        url
      ) ||
      "Enter valid website URL"
  },

  percentage: {
    match: percentage =>
      !percentage ||
      (/^\d+(\.\d+)?$/.test(percentage) &&
        percentage > 0 &&
        percentage <= 100) ||
      "Invalid percentage and should not have '%' sign"
  },

  internationalPhone: {
    internationalPhone: val =>
      regex.internationalPhone.test(val) || "Invalid phone"
  }
}

export const translateSvg = (x, y) => ["transform", `translate(${x},${y})`]
export const countryCode = "+91"

export const setCountryCode = phone => {
  const countryCode = localStorage.getItem("countryCode") || "+1"
  return `${countryCode}${phone}`
}

export const formatDateForDB = date => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day

  return [year, month, day].join("-")
}

export const formatDateUS = date => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day

  return [month, day, year].join("-")
}

export const debounce = (func, wait) => {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      timeout = null
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export const MAPBOX_TOKEN = `pk.eyJ1IjoibWRpc3JhaWwiLCJhIjoiY2xmbXV6ZnF1MGZwdTN0bzNsNGRjZXFhYiJ9.d73oSxrST9ZdDzbZ_bxF0A`
export const GOOGLE_API_KEY = `AIzaSyDL73DBtdCPEglZO_MLRZzhNefpzVvNsXA`

export const SORT_BY_OPTIONS = [
  { label: "Alphabetically, A-Z", value: "name", checked: false },
  { label: "Alphabetically, Z-A", value: "-name", checked: false },
  { label: "Price Low to High", value: "price", checked: false },
  { label: "Price High to Low", value: "-price", checked: false }
]
export const MILLIGRAM_OPTIONS = [
  {
    label: "Up to 2ml",
    value: { mg_max: "2" },
    checked: false
  },
  {
    label: "2ml to 4ml",
    value: { mg_min: "2", mg_max: "4" },
    checked: false
  },
  {
    label: "5ml",
    value: { mg: "5" },
    checked: false
  },
  {
    label: "6ml",
    value: { mg: "6" },
    checked: false
  },
  {
    label: ">7ml",
    value: { mg_min: "7" },
    checked: false
  }
]
export const PUFF_COUNT_OPTIONS = [
  {
    label: "250 - 500 puffs",
    value: { puff_min: "250", puff_max: "500" },
    checked: false
  },
  {
    label: "800 - 1,000 puffs",
    value: { puff_min: "800", puff_max: "1000" },
    checked: false
  },
  {
    label: "1,200 - 1,600 puffs",
    value: { puff_min: "1200", puff_max: "1600" },
    checked: false
  },
  {
    label: "2,000 - 2,500 puffs",
    value: { puff_min: "2000", puff_max: "2500" },
    checked: false
  },
  {
    label: "3,000 puffs",
    value: { puff: "3000" },
    checked: false
  },
  {
    label: "> 4,000 Puffs",
    value: { puff_min: "4000" },
    checked: false
  }
]

export const NICOTINE_OPTIONS = [
  { label: "0% Nicotine", value: "0", checked: false },
  { label: "3% Nicotine", value: "3", checked: false },
  { label: "5% Nicotine", value: "5", checked: false },
  { label: "5.5% Nicotine", value: "5.5", checked: false },
  { label: "6% Nicotine", value: "6", checked: false }
]

export const FILTER_LABELS = [
  {
    label: "Sort By",
    value: "ordering",
    active: false
  },
  {
    label: "Flavor",
    value: "flavour",
    active: false
  },
  {
    label: "Brand",
    value: "brand",
    active: false
  },
  {
    label: "Puff Count",
    value: "puff",
    active: false
  },
  {
    label: "Milligram",
    value: "mg",
    active: false
  },
  {
    label: "Nicotine",
    value: "nicotine",
    active: false
  }
]

export const getOffset = url => {
  const _url = new URL(url)
  const params = new URLSearchParams(_url.search)
  return params.get("offset")
}

export const sortRecord = key => {
  return (a, b) => {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  }
}
