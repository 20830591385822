import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import ListItems from "./listItems"
import { useAdminContext } from "../../../contexts/AdminContext"
import Items from "./ltems"
import AddItem from "./addItem"
import { validation } from "../../../utils"
import "./manageItems.scss"

export default function ManageItems() {
  const {
    brands,
    getBrands,
    getBrand,
    categories,
    getCategories,
    fetchAttributesById,
    subCategories,
    getSubCategories,
    variations,
    getVariation,
    variationValues,
    getVariationValue,
    getVariationValueById,
    getVariationValueByParentId,
    attributes,
    getAtrribute,
    attributeValues,
    getAtrributeValue,
    getAtrributeValueById
  } = useAdminContext()
  const [variationList, setVariationLIst] = useState([])
  const [selectedEvent, setSelectedEvent] = useState("")
  const [parent, setParent] = useState({})
  const [variationType, setVariationType] = useState("")
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState("")
  const [formData, setFormData] = useState([])
  const [addNewItem, setAddNewItem] = useState(false)
  const [itemActive, setItemActive] = useState(true)
  const [idToUpdate, setIdToUpdate] = useState("")
  const [searchText, setSearchText] = useState("")
  const [showNotification, setShowNotification] = useState("")
  const [addItemLabelPrefix, setAddItemLabelPrefix] = useState("Change")
  const [variationAPISearch, setVariationAPISearch] = useState({search:''})

  const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const onChangeText = debounce(text => {
    if(!variationType && !['brand','category','subCategory'].includes(selectedEvent)){
      setVariationAPISearch(currentState=>({...currentState,search:text}))
    }
    setSearchText(text)
  }, 300)

  useEffect(() => {
    const config = { params: {} }
    config.params.search = searchText
    if (selectedEvent === "brand") {
      getBrands(config)
    } else if (selectedEvent === "category") {
      getCategories(config)
    }
  }, [searchText])

  useEffect(() => {
    if (selectedEvent === "brand") {
      getBrands()
    } else if (selectedEvent === "category") {
      getCategories()
    } else if (selectedEvent === "subCategory") {
      getSubCategories()
    } else if (variationType && variationType === "attribute") {
      getAtrributeValue({
        params: {
          attribute: selectedEvent
        }
      })
    } else {
      getVariationValue({
        params: {
          variation: selectedEvent,
        }
      })
    }
  }, [selectedEvent])

  useEffect(()=>{
    getVariationValue({
      params: {
        variation: selectedEvent,
        search: variationAPISearch.search
      }
    })
  },[variationAPISearch])

  useEffect(() => {
    if (selectedEvent === "brand") {
      setItems(brands)
    } else if (selectedEvent === "category") {
      setItems(categories)
    } else if (selectedEvent === "subCategory") {
      setItems(subCategories)
    } else if (variationType && variationType === "attribute") {
      setItems(attributeValues)
    } else {
      setItems(variationValues)
    }
  }, [
    selectedEvent,
    brands,
    categories,
    subCategories,
    variationValues,
    attributeValues
  ])

  useEffect(()=>{
    setTimeout(()=>{
      setShowNotification('')
    },3000)
  },[showNotification])

  useEffect(() => {
    if (selectedEvent === "brand" && selectedItem) {
      const id = selectedItem
      getBrand(id).then(response => {
        const { id, name, active } = response
        setAddNewItem(false)
        setIdToUpdate(id)
        setItemActive(active)
        setFormData([
          {
            name: "name",
            defaultValue: name,
            rules: {
              required: "Brand required!",
              minLength:{
                value:'3',
                message:'Too Short'
              }
            }
          }
        ])
      })
    } else if (selectedEvent === "category" && selectedItem) {
      const id = selectedItem
      fetchAttributesById(id).then(response => {
        const { id, name, active } = response
        setAddNewItem(false)
        setIdToUpdate(id)
        setItemActive(active)
        setFormData([
          {
            name: "name",
            defaultValue: name,
            rules: {
              required: "Category required!",
              validate: { ...validation.name }
            }
          }
        ])
      })
    } else if (selectedEvent === "subCategory" && selectedItem) {
      const id = selectedItem
      fetchAttributesById(id).then(response => {
        const { id, name, parent, active } = response
        setAddNewItem(false)
        setIdToUpdate(id)
        setItemActive(active)
        setFormData([
          {
            name: "parent",
            defaultOptions: categories,
            defaultValue: categories.find(({ value }) => value === parent),
            rules: {
              required: "Category required!"
            },
            type: "select",
            isDisabled:true
          },
          {
            name: "name",
            fieldLabel:'Sub Category',
            defaultValue: name,
            rules: {
              required: "Sub category required!",
              validate: { ...validation.name }
            }
          }
        ])
      })
    } else if (selectedItem && variationType === "attribute") {
      const id = selectedItem
      getAtrributeValueById(id).then(response => {
        const { id, value } = response
        setAddNewItem(false)
        setIdToUpdate(id)
        setFormData([
          {
            name: "value",
            defaultValue: value,
            rules: {
              required: "Attribute required!"
            }
          }
        ])
      })
    } else if (selectedItem) {
      const id = selectedItem
      if (parent?.id) {
        Promise.allSettled([
          getVariationValueById(id),
          getVariationValueByParentId({
            params: {
              variation: parent.id,
            }
          })
        ]).then(response => {
          const [variationRes, parentRes] = response
          const variation = variationRes.value
          const parentList = parentRes.value
          const { id, value, child_of } = variation
          setIdToUpdate(id)
          setAddNewItem(false)
          return setFormData([
            {
              name: "parent",
              defaultOptions: parentList,
              defaultValue: parentList.find(({ value }) => value == child_of),
              rules: {
                required: "Parent required!"
              },
              type: "select"
            },
            {
              name: "value",
              defaultValue: value,
              rules: {
                required: "Variation required!"
              }
            }
          ])
        })
      } else {
        getVariationValueById(id).then(response => {
          const { id, value } = response
          setAddNewItem(false)
          setIdToUpdate(id)
          setFormData([
            {
              name: "value",
              defaultValue: value,
              rules: {
                required: "Variation required!"
              }
            }
          ])
        })
      }
    }
    setAddItemLabelPrefix('Change')
  }, [selectedEvent, selectedItem, variationType, variationAPISearch])

  const addNewItemHandle = () => {
    setAddItemLabelPrefix('Create')
    setIdToUpdate("")
    setAddNewItem(true)
  }

  useEffect(() => {
    if (addNewItem) {
      if (selectedEvent === "brand") {
        setFormData([
          {
            name: "name",
            defaultValue: "",
            rules: {
              required: "Brand required!",
              minLength:{
                value:'3',
                message:'Too Short'
              }
            }
          }
        ])
      } else if (selectedEvent === "category") {
        setFormData([
          {
            name: "name",
            defaultValue: "",
            rules: {
              required: "Category required!",
              validate: { ...validation.name }
            }
          }
        ])
      } else if (selectedEvent === "subCategory") {
        setFormData([
          {
            name: "parent",
            defaultOptions: categories,
            defaultValue: {},
            rules: {
              required: "Category required!"
            },
            type: "select"
          },
          {
            name: "name",
            defaultValue: "",
            rules: {
              required: "Sub category required!",
              validate: { ...validation.name }
            }
          }
        ])
      } else {
        if (parent?.id) {
          getVariationValueByParentId({
            params: {
              variation: parent.id
            }
          }).then(response => {
            const parentList = response
            return setFormData([
              {
                name: "parent",
                defaultOptions: parentList,
                defaultValue: {},
                rules: {
                  required: "Parent required!"
                },
                type: "select"
              },
              {
                name: "value",
                defaultValue: "",
                rules: {
                  required: "Variation required!"
                }
              }
            ])
          })
        } else {
          setFormData([
            {
              name: "value",
              defaultValue: "",
              rules: {
                required: "Variation required!"
              }
            }
          ])
        }
      }
    }
  }, [addNewItem, selectedEvent])

  useEffect(() => {
    const list = [
      {
        label: "Brand",
        value: "brand"
      },
      {
        label: "Category",
        value: "category"
      },
      {
        label: "Sub Category",
        value: "subCategory"
      }
    ]
    setVariationLIst([...list, ...variations, ...attributes])
  }, [variations, attributes])

  useEffect(() => {
    getVariation()
    getAtrribute()
  }, [])

  const getItemTitle = () => {
    return variationType
      ? variationList
        .filter(({ variationType }) => variationType)
        .find(({ value }) => value === selectedEvent)?.label
      : variationList.find(({ value }) => value === selectedEvent)?.label
  }

  return (
    <Container className="p-4" fluid>
      <Row>
        <Col>
          <h3>Manage list items</h3>
        </Col>
      </Row>
      <Row>
        {!!showNotification.length && <div className='notification-box'>{showNotification}</div>}
      </Row>
      <Row className="p-3" style={{ background: "#fff", height: "75vh", overflow: "hidden" }}>
        <Col md={3} className="border-end">
          <ListItems
            variationList={variationList}
            setSelectedEvent={(eventKey, parent, variationType) => {
              setSelectedItem("")
              setAddNewItem(false)
              setParent(parent)
              setVariationType(variationType)
              setSelectedEvent(eventKey)
            }}
          />
        </Col>
        <Col md={3} className="border-end">
          {selectedEvent ? (
            <Items
              label={getItemTitle()}
              items={items}
              variationType={variationType}
              defaultKey={selectedItem}
              onChangeText={onChangeText}
              setSelectedItem={setSelectedItem}
              onClickAddNewItem={addNewItemHandle}
            />
          ) : (
            <div style={{ color: "#A0A0A0" }}>
              <h6
                className="mb-3"
                style={{
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontWize: "12px",
                  color: "#A0A0A0"
                }}
              >
                Sub List
              </h6>
              <p>Please select an item to be able to see information</p>
            </div>
          )}
        </Col>
        <Col md={6} className="border-end">
          {(selectedItem || addNewItem) && (
            <AddItem
              notification={setShowNotification}
              labelPrefix={addItemLabelPrefix}
              label={getItemTitle()}
              selectedEvent={selectedEvent}
              hasParent={parent}
              variationType={variationType}
              formData={formData}
              active={itemActive}
              setActive={setItemActive}
              id={idToUpdate}
              setId={setIdToUpdate}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}
