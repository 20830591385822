import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAdminContext } from '../../../../contexts/AdminContext'
import Field from '../custom-field/field';
import { assets } from '../../../../utils';
import * as _ from 'lodash';
import { ALLROUTES } from '../../../../adminRoutes';
import './editProduct.scss'
import CompatibleProducts from '../createProduct/compatibleProducts';

export default function EditProduct() {
    const nav = useNavigate();
    const params = useParams();
    const productId = params.productId;
    const [productDetails, setProductDetails] = useState({})
    const [staticFields, setStaticFields] = useState([])
    const [brandList, setBrandList] = useState([])
    const [attributeList, setAttributeList] = useState([])
    const [attributeListPrefilled, setAttributeListPrefilled] = useState([])
    const [variations, setVariations] = useState([]);
    const [numberofVariants, setNumberofVariants] = useState([]);
    const [finalVariations, setFinalVariations] = useState([])
    const [savedProductVariatons, setSavedProductVariatons] = useState([])
    const [enhancedVarientList, setEnhancedVarientList] = useState([])
    const [productNoVariant, setProductNoVariant] = useState(false)
    const [defaultVariation, setDefaultVariation] = useState({})
    const [defaultVarientImages, setDefaultVarientImages] = useState([])
    const [compatibleProducts, setCompatibleProducts] = useState([])
    const [showNotification, setShowNotification] = useState('')
    const { getProductDetailAdmin, getAllBrands, fetchAttributesById, fetchDDNValuesbyID,
        getVaritionDDNValues, getProductVariation, deleteProductVariation, saveProductVariation,
        editAProduct } = useAdminContext();
    const masterMutationObject = useRef({});
    const variationObjectRef = useRef({});

    const [categoryToggle, setCategoryToggle] = useState(true);
    const [detailsToggle, setDetailsToggle] = useState(true);
    const [compatibleProductsToggle, setCompatibleProductsToggle] = useState(true);

    // Initial hit for Edit
    useEffect(() => {
        getProductDetailAdmin(productId).then(res => {
            setProductDetails(res);
            fetchAttributeList(res.category?.id)
        });
        getAllBrands().then(res => {
            setBrandList(res);
        })
    }, []);

    const handleCategoryToggle = () => {
        // setCategoryToggle((state) => !state)
      }
      const handleDetailsToggle = () => {
        // setDetailsToggle((state) => !state)
      }
      const handleCompProducts = () => {
        // setCompatibleProductsToggle((state) => !state)
      }

    const displayNotification = (message) => {
        setShowNotification(message)
        setTimeout(() => {
          setShowNotification('')
        }, [3000])
      }

    // Variation Manager logic below:
    useEffect(() => {
        if (savedProductVariatons.length && variations.length) {
            let enhancedSavedProdVar = savedProductVariatons.map(el => {
                const variant = { ...el }
                variant.key = `${variant.id}-${new Date().getTime()}`;
                variant.isSaved = true;
                variant.savedResponseId = el.id;
                variant.list = numberofVariants[0]?.list;
                variant.list = variant.list?.map(val => {
                    const listItem = { ...val };
                    if (listItem.typeAs === "classificationvalue") {
                        listItem['preFilledValue'] = variant.productvariations.find(el => el.classificationvalue)?.classificationvalue?.value || ' ';
                    } else {
                        listItem['preFilledValue'] = variant.productvariations.find(el => el.variationvalue?.variation?.id === listItem.id)?.variationvalue.value || ' ';
                    }
                    return listItem
                })
                return variant
            })
            setEnhancedVarientList(enhancedSavedProdVar)
        }
    }, [numberofVariants, savedProductVariatons])

    // handle variation logic
    useEffect(() => {
        if (variations.length) {
            // set Default variation 
            if (variations[0].variation.name === 'Default') {
                getVaritionDDNValues({ params: { variation: variations[0].variation.id } }).then(res => {
                    setDefaultVariation(res[0])
                })
            }
            let sortedVariations = variations?.sort((a, b) => a.ordering > b.ordering);
            let final = []
            sortedVariations = sortedVariations.map((variation) => {
                const current = variation;
                if (current?.classification) {
                    //fetch dropdown values later
                    current.classification = { ...current.classification, type: 'DDN', dropDownList: [], typeAs: 'classificationvalue', orderring: current.ordering }
                    final.push(current.classification)
                }
                if (current?.variation && current.variation.id !== 8 && current.variation.name !== 'Default') {
                    // fetch dropdown values later
                    current.variation = { ...current.variation, type: 'DDN', dropDownList: [], typeAs: 'variationvalue', orderring: current.ordering }
                    final.push(current.variation)
                }
                return current;
            })
            final = final.map(variant => {
                let obj = { ...variant };
                getVaritionDDNValues({ params: { variation: variant.id } }).then(res => {
                    obj.dropDownList = res;
                })
                return obj
            })
            let newVariant = { key: new Date().getTime(), list: final }
            setNumberofVariants(variants => [...variants, newVariant])
            setFinalVariations(final)
        } else {
            setFinalVariations([])
        }
    }, [variations])

    // get attributes List
    const fetchAttributeList = (id) => {
        fetchAttributesById(id).then(res => {
            //clear previous variations
            // setNumberofVariants([])
            let attributes = res.attribute;
            setVariations(res.variations);
            let newResp = attributes.map(attr => {
                let obj = { ...attr }
                if (attr.type === "DDN") {
                    const config = {
                        params: {
                            attribute: attr.id
                        }
                    }
                    fetchDDNValuesbyID(config).then(res => {
                        obj.dropDownList = res.map(el => {

                            const obj = { ...el };
                            obj.name = obj.value
                            return obj
                        })
                    })
                }
                return obj
            })
            setAttributeList(new Array(...newResp))
            // setShowProfit('');
            // masterMutationObject.current = {}
        })
    }

    // Mapping attribute List to prefilled data
    useEffect(() => {
        let newAttr = attributeList.map(el => {
            const attr = { ...el };
            attr['preFilledValue'] = productDetails.attributes.find(val => val.attribute.id === attr.id)?.value
            // maitaining mutation object initially
            // masterMutationObject.current = {...masterMutationObject.current,
            //     [masterMutationObject.current[attr.name]]:{name:attr['preFilledValue']}
            // }
            return attr
        });
        setAttributeListPrefilled(newAttr)
    }, [attributeList])

    // Initialise static fields
    useEffect(() => {
        if (brandList.length && productDetails.id) {
            initialiseStaticFields();
        }
    }, [brandList, productDetails])

    // get Saved Product variatns
    useEffect(() => {
        // get saved product variations
        if (productDetails.id) {
            const payload = { params: { product: productDetails.id } }
            getProductVariation(payload).then(res => {
                setSavedProductVariatons(res);
            })
        }
    }, [productDetails])

    const initialiseStaticFields = () => {
        const staticFields = {
            top: [
                {
                    id: 'brand',
                    type: 'DDN',
                    name: 'Brand',
                    accessor: 'name',
                    dropDownList: brandList,
                    preFilledValue: productDetails.brand?.name || ''
                },
                {
                    id: 'productName',
                    type: 'STR',
                    name: 'Product Name',
                    accessor: 'name',
                    preFilledValue: productDetails.name || ''
                },
                {
                    id: 'textArea',
                    type: 'TXTAREA',
                    name: 'Description',
                    accessor: 'name',
                    preFilledValue: productDetails.description || ''
                }
            ],
            bottom: [
                {
                    id: 'costPrice',
                    type: 'INT',
                    name: 'Cost',
                    accessor: 'name',
                    preFilledValue: productDetails.cost || ''
                },
                {
                    id: 'sellingPrice',
                    type: 'INT',
                    name: 'Selling Price',
                    accessor: 'name',
                    preFilledValue: productDetails.price || ''
                }
            ]
        }
        setStaticFields(staticFields)
    }

    //Main state maintain function
    const handleFieldValueChange = (name, e) => {
        // const newValidations = { ...validations }
        // for (const key of Object.keys(validations)) {
        //   newValidations[key].isError = false
        // }
        // setValidations(newValidations);
        // setSubmitErrorMessage('')

        handleDynamicfields(name, JSON.parse(e))
    }

    const handleDynamicfields = (name, field) => {
        const newFormFieldVals = { ...masterMutationObject.current }
        newFormFieldVals[name] = field
        masterMutationObject.current = newFormFieldVals
        // if (masterMutationObject.current['Cost']?.name && masterMutationObject.current['Selling Price']?.name) {
        //   getProfit();
        // }
    }

    const cachedHandleFieldValueChange = useCallback(handleFieldValueChange, [])
    // const memoizedStaticFields = useMemo(() => { return staticFields }, [staticFields])

    const handleAddVariant = () => {
        const newEnhancedVarient = {
            isSaved: false,
            key: new Date().getTime(),
            list: numberofVariants[0].list,
            images: []
        }
        setEnhancedVarientList([...enhancedVarientList, newEnhancedVarient])
    }

    const handleVariationChange = (name, key, e) => {
        const variationId = JSON.parse(e).variation;
        let existing = variationObjectRef.current?.[key] || {}
        // variationObjectRef.current[key] = {...existing, [variationId]:JSON.parse(e)}
        variationObjectRef.current = { ...variationObjectRef.current, [key]: { ...existing, [variationId]: JSON.parse(e) } }
        const newEnhancedVarientList = enhancedVarientList.map(el => {
            const obj = { ...el }
            if (obj.key === key) {
                obj.list = obj.list.map(listItem => {
                    const item = { ...listItem }
                    if (item.hasOwnProperty('parent') && item?.id !== variationId && item?.parent?.id === variationId) {
                        item['filteredDDN'] = item?.dropDownList?.filter(el => el['child_of'] === JSON.parse(e)?.id)
                        item['child_of'] = JSON.parse(e)?.id
                    }
                    return item
                })
            }
            return obj
        })
        setEnhancedVarientList(newEnhancedVarientList)
    }
    // saving images for default variants
    useEffect(() => {
        if (enhancedVarientList.length > 0) {
            if (enhancedVarientList[0]?.productvariations?.[0]?.variationvalue.value === 'DEFAULT') {
                setDefaultVarientImages(enhancedVarientList[0].images)
            }
        }
    }, [enhancedVarientList])

    const saveVariation = (key) => {
        const master = enhancedVarientList.find(el => el.key === key)
        const actionRow = variationObjectRef.current[key]
        let groupedList = _.groupBy(master.list, 'orderring')
        const productVariations = [];
        Object.values(groupedList).forEach(el => {
            let final = {}
            el.forEach(variation => {
                final[variation.typeAs] = actionRow[variation.id].id || 0;
            })
            productVariations.push(final)
        })
        const payloadFormData = new FormData();
        payloadFormData.append(`product`, (productDetails.id).toString())
        payloadFormData.append(`productvariations`, JSON.stringify(productVariations))
        master?.files?.forEach((image, idx) => {
            payloadFormData.append(`images`, image)
        })
        saveProductVariation(payloadFormData).then(res => {
            if (res.id) {
                displayNotification('Varient Added Successfully')
                let variations = enhancedVarientList.map(variant => {
                    const obj = { ...variant }
                    if (obj.key === key) {
                        obj.isSaved = true
                        obj.savedResponseId = res.id
                    }
                    return obj;
                });
                setEnhancedVarientList(variations)
            }
        })
    }

    const addVariantImage = (e, key) => {
        let newVariants = enhancedVarientList.map(variant => {
            let obj = { ...variant }
            if (obj.key === key) {
                if (obj?.files?.length) {
                    obj.files = [...obj.files, ...Object.values(e.target.files)]
                } else {
                    obj.files = Object.values(e.target.files)
                }
                obj.images = obj.files.map(file => {
                    return { id: 'test', image: URL.createObjectURL(file) }
                })
            }
            return obj
        })
        setEnhancedVarientList(newVariants)
    }

    const deleteVariant = (variantId, key) => {
        const classname = `variant-field-id-${key}`;
        //Delete selected variant
        deleteProductVariation(variantId).then(res => {
            // document.getElementsByClassName(classname)[0].style.display = 'none';
            let variations = enhancedVarientList.filter(variant => variant.key !== key);
            setEnhancedVarientList(variations)
        })
    }

    const editProductAPICall = (obj) => {
        if (Object.keys(obj).length) {
            editAProduct(productDetails.id, obj).then(res=>{
                displayNotification('Product Edited Successfully')
            })
        }
    }

    const editProduct = () => {
        // if(disableCreateProduct){
        //   return
        // }
        const formObj = masterMutationObject.current
        const exclusions = ['Product Name', 'Description', 'Brand', 'Cost', 'Selling Price'];
        const obj = {};
        const attributes = []
        obj.name = formObj['Product Name']?.name || ''
        obj.description = formObj['Description']?.name || ''
        obj.brand = formObj['Brand']?.id || ''
        obj.active = true
        obj.cost = formObj['Cost']?.name || ''
        obj.price = formObj['Selling Price']?.name || ''
        obj.category = productDetails.category?.id
        if(compatibleProducts.length){
            obj['compatible_products'] = compatibleProducts.map(el=>el.id)
        } else {
            obj['compatible_products'] = []
        }
        const otherFields = Object.keys(formObj).filter(el => !exclusions.includes(el))
        otherFields.forEach(field => {
            const attr = attributeList.find(el => el.name === field) || {}
            const id = (productDetails.attributes?.find(val => val.attribute.id === attr.id))?.id
            if(formObj[field]?.name){
            attributes.push({ id: id, attribute: attr.id, value: formObj[field]?.name })
            }
        }
        )
        obj.attributes = attributes || [];
        for (const property in obj) {
            if ((obj[property] === "" || obj[property].length === 0)&& property!=='compatible_products') {
                delete obj[property]
            }
        }
        editProductAPICall(obj);
        // if (validateFields(obj)) {
        //   createProductAPICall(obj);
        // }

    }

    const addproductNoVariantImage = (e) => {
        const obj = { ...productNoVariant }
        if (obj?.files?.length) {
            obj.files = [...obj.files, ...Object.values(e.target.files)]
        } else {
            obj.files = Object.values(e.target.files)
        }
        obj.imagePreview = obj.files.map(file => URL.createObjectURL(file));
        setProductNoVariant(obj)
    }

    const saveProductNoVariantImage = () => {
        const payloadFormData = new FormData();
        const productVariations = [{ classificationvalue: '', variationvalue: defaultVariation.id }]
        payloadFormData.append(`product`, (productDetails.id).toString())
        payloadFormData.append(`productvariations`, JSON.stringify(productVariations))
        productNoVariant.files.forEach((image, idx) => {
            payloadFormData.append(`images`, image)
        })
        saveProductVariation(payloadFormData).then(res => {
            displayNotification('Image Added Successfully')
            console.log('Image added Successfully')
        })
    }

    return (
        <div className='create-product-wrapper'>
            <div className='products-heading'>
                Edit Product
            </div>
            {!!showNotification.length && <div className='notification-box'>{showNotification}</div>}
            <div className='main-panel'>
                <div className='left'>
                    <div className='heading-section'>
                        <span>Edit a product based on category</span>
                    </div>
                    <div className='field-section'>
                        <div className='category'>
                            <div className='category-header' onClick={handleCategoryToggle}>
                                Category
                                <img src={assets.chevron} />
                            </div>
                            {categoryToggle && (<div className='fields'>
                                <Field type={'DDN'} preFilledValue={productDetails?.category?.name} name="Category"
                                    isDisabled={true} accessor="name"
                                // showError={validations.category.isError}
                                />
                                <Field type={'DDN'} preFilledValue={productDetails?.category?.name} name="Sub-Category"
                                    isDisabled={true} accessor="name" />
                            </div>)}
                        </div>
                        {/* details section starts */}

                        <div className='details'>
                            <div className='details-header' onClick={handleDetailsToggle}>
                                Details
                                <img src={assets.chevron} />
                            </div>
                            {detailsToggle && (<div className='fields'>
                                {/* Top Static section */}
                                {staticFields?.top?.map(field => <Field key={field.id} type={field.type} name={field.name} dropDownList={field.dropDownList}
                                    preFilledValue={field.preFilledValue} accessor={field.accessor}
                                    handleChange={cachedHandleFieldValueChange} />)}
                                {/* Middle dynamic section */}

                                {attributeListPrefilled.map(attribute => <Field key={attribute.id} type={attribute.type} name={attribute.name} dropDownList={attribute.dropDownList}
                                    preFilledValue={attribute.preFilledValue} accessor={attribute.accessor}
                                    handleChange={cachedHandleFieldValueChange} />)}

                                {/* Bottom Static section */}
                                <div className='cp-sp'>
                                    {staticFields?.bottom?.map(field => <Field key={field.id} type={field.type} name={field.name} dropDownList={field.dropDownList}
                                        preFilledValue={field.preFilledValue} accessor={field.accessor}
                                        handleChange={cachedHandleFieldValueChange} />)}
                                </div>
                            </div>)}
                        </div>

                        {/* details section ends */}
                        <div className='compatible-products'>
                            <div className='compatible-products-header' onClick={handleCompProducts}>
                                Compatible-Poducts
                                <img src={assets.chevron} />
                            </div>
                            {compatibleProductsToggle && (<div className='fields'>
                                <CompatibleProducts actionType='edit' handleCompatibleProducts={setCompatibleProducts}
                                    productDetails={productDetails} />
                            </div>)}
                        </div>

                    </div>
                    <div className='buttons'>
                        <button className='cancel-btn' onClick={() => nav(ALLROUTES.dashboardChildren.products)}>Cancel</button>
                        <button className='submit-btn' onClick={editProduct}>Save Changes</button>
                    </div>
                </div>



                <div className='right'>
                    <div className='product'>
                        <div className='product-header'>
                            Product
                            <img src={assets.chevron} />
                        </div>
                        {!!finalVariations.length && (
                            <div>{
                                enhancedVarientList.map(variant =>
                                    <div key={variant.key}>
                                        <div className={`variant-fields fields variant-field-id-${variant.key}`}>
                                            <div className='variant-action'>
                                                {<img src={assets.DeleteIcon} className={`${variant.isSaved ? '' : 'hidden-image'}`}
                                                    onClick={() => deleteVariant(variant.savedResponseId, variant.key)}
                                                ></img>}
                                                <img style={{minHeight:'22px',minWidth:'22px'}} className={`variant-save-btn ${!variant.isSaved ? '' : 'hidden-image'}`} onClick={() => saveVariation(variant.key)} src={assets.saveIcon}></img>
                                            </div>
                                            <div className='variant-image-wrapper'>
                                                {variant.images[0]?.image?.length && <img src={variant.images[0]?.image} className="preview-image-variant" />}
                                                <span className='add-image-wrapper'>
                                                    <img className="add-icon" src={assets.addImage} />
                                                    <input disabled={variant.isSaved} className="image-insert" type="file" multiple
                                                        onChange={(e) => addVariantImage(e, variant.key)} />
                                                </span>
                                            </div>
                                            {variant.list?.map((field, index) => <Field key={index} uniqueKey={variant.key} feature={'variation-creation'} type={'DDN'}
                                                name={field.name} preFilledValue={field.preFilledValue} dropDownList={field.filteredDDN || field.dropDownList}
                                                isDisabled={variant.isSaved} handleVariationChange={handleVariationChange} fullDetails={field} isApiLevelSearch={true} accessor="name" />)}
                                        </div>
                                        {/* other images */}
                                        {variant.images?.length > 1 &&
                                            <div className='other-variants-wrapper'>
                                                {variant.images.map(image => <img className="preview-image-variant-other" src={image.image} />)}
                                            </div>
                                        }
                                    </div>)}
                            </div>
                        )}
                        {!!finalVariations.length && <div className='add-variant'>
                            <span onClick={handleAddVariant}>+ Add another variant</span>
                        </div>}

                        {(!!!finalVariations.length) && <div className='add-variant'>
                            <div className='variant-action no-variation-action'>
                                <img className="variant-save-btn-no-variation" onClick={() => saveProductNoVariantImage()} src={assets.saveIcon}></img>
                            </div>
                            <div className='no-variant-left'>
                                <div className='variant-image-wrapper'>
                                    {productNoVariant.imagePreview?.length && <img src={productNoVariant.imagePreview[0]} className="preview-image-variant" />}
                                    {/* {variant.imagePreview?.map(image=><img className="preview-image-variant" src={image}/>)} */}
                                    <span className='add-image-wrapper'>
                                        <img className="add-icon" src={assets.addImage} />
                                        <input disabled className="image-insert" onChange={(e) => addproductNoVariantImage(e)} type="file" multiple />
                                    </span>
                                </div>
                                <div className='other-no-variant-images'>
                                    {productNoVariant.imagePreview?.length > 1 &&
                                        <div className='other-variants-wrapper'>
                                            {productNoVariant.imagePreview.slice(1,).map(image => <img className="preview-image-variant-other" src={image} />)}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='no-variant-right'>
                                <span>There are no variations, You can still add an image.</span>
                            </div>
                        </div>}
                        {/* Show Images for default variant */}
                        {!!defaultVarientImages.length &&
                            <>
                                <p className='saved-iamge-heading'>Saved Images:</p>
                                <div className='default-variant-images'>
                                    {defaultVarientImages.map(image => <img src={image.image} />)}
                                </div>
                            </>
                        }
                        {/* )} */}
                    </div>
                </div>

            </div>
        </div>
    )
}
