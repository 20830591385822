import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { assets } from "../../../utils"
import { Button, Col, Row, Form } from "react-bootstrap"
import InputField from "../../../components/Admin/inputField"
import { useAdminContext } from "../../../contexts/AdminContext"
import { Select } from "../../../components/Admin/VTPSelect"
import CancelConfirmPopup from "../../../components/Admin/CancelConfirmPopup"

export default function AddItem({
  notification,
  label,
  labelPrefix,
  selectedEvent,
  hasParent,
  variationType,
  id,
  setId,
  formData,
  active,
  setActive
}) {
  const [confirmDelete, setConfirmDelete] = useState()
  const {
    brands,
    addBrand,
    deleteBrand,
    setBrands,
    updateBrand,
    categories,
    addCategory,
    deleteCategory,
    setCategories,
    subCategories,
    setSubCategories,
    updateVariationValue,
    variationValues,
    setVariationValues,
    addVariationValue,
    deleteVariationValue,
    attributeValues,
    setAtrributeValues,
    addAttributeValue,
    updateAttributeValue,
    deleteAttributeValue
  } = useAdminContext()
  const { control, trigger, setValue, reset, setError, handleSubmit } =
    useForm()

  const ITEMS_API = [
    {
      name: "brand",
      updateItem: (id, payload) => updateBrand(id, payload),
      addItem: payload => addBrand(payload.name),
      lists: brands,
      deleteItem: deleteBrand,
      setItems: setBrands
    },
    {
      name: "category",
      updateItem: (id, payload) =>
        console.log("API is not available", id, payload),
      addItem: payload => addCategory(payload),
      lists: categories,
      deleteItem: deleteCategory,
      setItems: setCategories
    },
    {
      name: "subCategory",
      updateItem: (id, payload) =>
        console.log("API is not available", id, payload),
      addItem: payload => addCategory(payload),
      lists: subCategories,
      deleteItem: deleteCategory,
      setItems: setSubCategories
    }
  ]

  const onSubmit = async vals => {
    if (
      selectedEvent === "brand" ||
      selectedEvent === "category" ||
      selectedEvent === "subCategory"
    ) {
      if (selectedEvent === "subCategory") {
        if (!vals?.parent?.value) {
          return setError(
            "parent",
            { type: "focus", message: "Category required!" },
            { shouldFocus: true }
          )
        }
        vals.parent = vals.parent.value
      }
      const { updateItem, addItem, lists, setItems } = ITEMS_API.find(
        ({ name }) => name === selectedEvent
      )

      if (id) {
        const payload = { ...vals }
        payload.name = vals.name
        payload.active = active
        updateItem(id, payload).then(() => {
          reset()
          if (!payload.active) {
            return setItems(lists.filter(({ value }) => value != id))
          }
          const update = [...lists]
          const index = update.findIndex(({ value }) => value == id)
          update[index].label = payload.name
          update[index].active = payload.active
          update[index].checked = false
          setValue("name", payload.name)
          setItems(update)
          notification('Updated Successfully')
        })
        return
      }
      addItem(vals).then(response => {
        const { active, id, name } = response
        const newItem = {}
        newItem.value = id
        newItem.label = name
        newItem.active = active
        setId("")
        setItems([newItem, ...lists])
        setValue("name", "")
        setValue("parent", "")
        notification('Added Successfully')
      })
    } else if (variationType && variationType === "attribute") {
      const payload = { ...vals }
      if (id) {
        updateAttributeValue(id, payload).then(response => {
          const { id, value } = response
          const update = [...attributeValues]
          const index = update.findIndex(({ value }) => value == id)
          update[index].label = value
          setAtrributeValues(update)
          notification('Updated Successfully')
        })
      } else {
        payload.attribute = selectedEvent
        addAttributeValue(payload).then(response => {
          const { id, value } = response
          const newItem = {
            label: value,
            value: id
          }
          setAtrributeValues([newItem, ...attributeValues])
          notification('Added Successfully')
          reset()
        })
      }
    } else {
      if (id) {
        let payload = {}
        if (vals?.parent && vals?.parent?.value) {
          payload.child_of = vals.parent.value
        }
        payload.value = vals.value
        updateVariationValue(id, payload).then(response => {
          const { id, value } = response
          const update = [...variationValues]
          const index = update.findIndex(({ value }) => value == id)
          update[index].label = value
          setVariationValues(update)
          notification('Updated Successfully')
        })
      } else {
        console.log("hasParent", hasParent)
        if (hasParent?.id && !vals?.parent?.value) {
          return setError(
            "parent",
            { type: "focus", message: "Parent required!" },
            { shouldFocus: true }
          )
        }
        let payload = {}
        if (vals?.parent && vals?.parent?.value) {
          payload.child_of = vals.parent.value
        }
        payload.variation = selectedEvent
        payload.value = vals.value
        addVariationValue(payload).then(response => {
          const { child_of, id, value, variation } = response
          const newItem = {
            label: value,
            value: id,
            child_of,
            variation
          }
          setVariationValues([newItem, ...variationValues])
          reset()
          notification('Added Successfully')
        })
      }
    }
  }

  const onClickDelete = () => {
    if (id) {
      const { deleteItem, setItems, lists } =
        ITEMS_API.find(({ name }) => name === selectedEvent) || {}
      if (typeof deleteItem == "function") {
        deleteItem(id).then(() => {
          setId("")
          setItems(lists.filter(({ value }) => value !== id))
          setValue("name", "")
        })
      } else if (variationType && variationType === "attribute") {
        deleteAttributeValue(id).then(() => {
          setId("")
          setAtrributeValues(
            attributeValues.filter(({ value }) => value !== id)
          )
          setValue("value", "")
        })
      } else {
        deleteVariationValue(id).then(() => {
          setId("")
          setVariationValues(
            variationValues.filter(({ value }) => value !== id)
          )
          setValue("value", "")
        })
      }
    }
  }

  useEffect(() => {
    formData.map(({ name, defaultValue }) => setValue(name, defaultValue))
  }, [formData])

  return (
    <div className="add-item">
      <h6>{`${labelPrefix} ${label}`}</h6>
      <form className={"login"} onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-2">
          {formData.map(
            ({ name, fieldLabel, isDisabled, type, defaultValue, defaultOptions, rules }) => {
              if (type === "select") {
                return (
                  <Col lg={12} key={name}>
                    <Select
                      name={name}
                      label="Category"
                      placeholder="Select"
                      optionSelected={{}}
                      defaultOptions={defaultOptions}
                      control={control}
                      isDisabled={isDisabled}
                      defaultValue={defaultValue}
                      rules={rules}
                    />
                  </Col>
                )
              }
              return (
                <Col xs={12} key={name}>
                  <InputField
                    label={fieldLabel || ""}
                    name={name}
                    defaultValue={defaultValue}
                    placeholder=""
                    rules={rules}
                    compType=""
                    control={control}
                    trigger={trigger}
                    className="applicationScreenInputAdmin"
                    type={type}
                  />
                </Col>
              )
            }
          )}
        </Row>
        <Row className="mb-4">
          {/* {selectedEvent === "brand" && (
            <Col xs={12} className="d-flex">
              <span>{`Set ${label} inactive`}</span>
              <Form.Check
                type="switch"
                id="custom-switch"
                label=""
                className="ms-4"
                checked={active}
                onChange={() => setActive(!active)}
              />
            </Col>
          )} */}
        </Row>
        <Row className="align-items-center justify-content-between">
          <Col xs={4}>
            <Button
              className="createIcon ms-auto"
              // onClick={onClickDelete}
              onClick={()=>setConfirmDelete(true)}
              style={{ fontSize: "14px", width: "100%" }}
            >
              <img src={assets.DeleteIcon} />
              <span className="ms-2">Delete</span>
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              className="saveChanges ms-auto"
              type="submit"
              style={{
                fontSize: "14px",
                width: "100%",
                background: "#DBBE82 !important"
              }}
            >
              <img src={assets.CheckIcon} />
              <span className="ms-2">Save Changes</span>
            </Button>
          </Col>
        </Row>
      </form>
      <CancelConfirmPopup
          label={`Are you sure want to Delete`}
          showTitle={false}
          show={confirmDelete}
          isDeletsConfirmation={true}
          setShow={setConfirmDelete}
          onClickCancel={onClickDelete}
        />
    </div>
  )
}
