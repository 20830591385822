import { APPCONFIG } from "../app-config"
import { post, get, put, del, patch } from "./http-methods"

const apiHandlerVariantOptions = (entity, config) => {
  const limit = config?.limit || APPCONFIG.api.variantOptionsPageSize
  const offset = config?.offset || 0
  return get(`/api/v1/${entity}/?limit=${limit}&offset=${offset}`, config)
}

export const API = {
  signup: payload => post("/api/v1/signup/", payload),
  login: payload => post("/api/v1/login/", payload),
  logout: () => post("/api/v1/logout/"),
  emailOtp: payload => post("/api/v1/email-otp/", payload),
  emailVerifyOtp: payload => post("/api/v1/email-verify-otp/", payload),
  forgotPassword: payload => post("/api/v1/users/forget-password/", payload),
  updateDeviceId: payload => patch(`/api/v1/users/device-token/update/`, payload),
  forgotPasswordVerify: payload =>
    post("/api/v1/users/forget-password/verify/", payload),
  forgotPasswordConfirm: payload =>
    post("/rest-auth/password/reset/confirm/", payload),
  getStores: config => get("/api/v1/stores/admin/store/", config),
  addStore: payload => post("/api/v1/stores/admin/store/", payload),
  editStore: (userid, payload) =>
    patch(`/api/v1/stores/admin/store/${userid}/`, payload),
  deleteStore: userid => del(`/api/v1/stores/admin/store/${userid}/`),
  getStore: id => get(`/api/v1/store/${id}/`), //for later (not being used anywhere)
  getProducts: config => get("/api/v1/product-stock/", config),
  getProduct: id => get(`/api/v1/product-stock/${id}/`, id),
  getBrands: config => apiHandlerVariantOptions("brand", config),
  getFlavours: config => apiHandlerVariantOptions("flavour", config),
  getFlavourTypes: config => apiHandlerVariantOptions("flavourtype", config),
  getCategories: config => get("/api/v1/category/", config),
  getPlaceName: url => get(url),
  getUserProfile: id => id?get(`/api/v1/users/my-profile/${id}/`):get(`/api/v1/users/my-profile/`),
  updateUserProfile: (id, payload) => patch(`/api/v1/users/my-profile/${id}/`, payload),
  getFavouriteSku: () => get("/api/v1/favourites/"),
  createFavouriteSku: payload => post("/api/v1/favourites/", payload),
  deleteFavouriteSku: id => del(`/api/v1/favourites/${id}/`),

  getCart: () => get("/api/v1/cart/"),
  getCartItem: () => get("/api/v1/cart-item/"),
  createCartItem: payload => post("/api/v1/cart-item/", payload),
  updateCartItem: (id, payload) => patch(`/api/v1/cart-item/${id}/`, payload),
  deleteCartItem: id => del(`/api/v1/cart-item/${id}/`),
  getTnc: () => get("/modules/terms-and-conditions/"),
  getPrivacyPolicy: () => get("/modules/privacy-policy/"),

  // admin
  getUsers: (config, loadMoreUrl) => {
    return !loadMoreUrl
      ? get("/api/v1/users/admin/userprofile/", config)
      : get(`${loadMoreUrl}`)
  },
  // getUsersLoadMore: url => get(`${url}`),
  addUser: payload => post("/api/v1/users/admin/userprofile/", payload),
  editUser: (userid, payload) =>
    patch(`/api/v1/users/admin/userprofile/${userid}/`, payload),
  deleteUser: userid => del(`/api/v1/users/admin/userprofile/${userid}/`),
  flagUser: (userid, payload) =>
    patch(`/api/v1/users/admin/userprofile/${userid}/`, payload),
  getFeedback: (config, loadMoreUrl) => {
    return !loadMoreUrl ? get(`/api/v1/feedback/`, config) : get(loadMoreUrl)
  },
  sendMail: (userid, payload) => patch(`/api/v1/feedback/${userid}/`, payload),
  addProducts: payload => post("/api/v1/product/", payload),
  addProductStock: payload => post("/api/v1/product-sku/", payload),
  patchProductSku: (skuId, payload) =>
    patch(`/api/v1/product-sku/${skuId}/`, payload),
  deleteProductSku: productid => del(`/api/v1/product-sku/${productid}/`),
  getProductsAdmin: config => get("/api/v1/product-sku/", config),
  getStoreReview: url => get(url),
  addBrand: payload => post("/api/v1/brand/", payload),
  getMilligrams: config => apiHandlerVariantOptions("milligram", config),
  getNicotinestrength: config => apiHandlerVariantOptions("nicotine", config),
  addCategory: payload => post("/api/v1/category/", payload),
  addMilligram: payload => post("/api/v1/milligram/", payload),
  addNicotinestrength: payload => post("/api/v1/nicotine/", payload),
  addFlavourtype: payload => post("/api/v1/flavourtype/", payload),
  addFlavour: payload => post("/api/v1/flavour/", payload),
  editProduct: (productid, payload) =>
    patch(`/api/v1/admin/product/${productid}/`, payload),
  deleteProduct: productid => del(`/api/v1/product/${productid}/`),
  getProductStock: config => get("/api/v1/product-stock/", config),
  addStoreAdmin: payload => post("/api/v1/storeadmin/", payload),
  editStoreAdmin: (userid, payload) =>
    put(`/api/v1/storeadmin/${userid}/`, payload),
  addStoreAdminToStore: (payload) => post('/api/v1/stores/admin/storeadmin/',payload),
  deleteStoreAdminFromStore: (id) => del(`/api/v1/stores/admin/storeadmin/${id}/`),

  // store admin
  getStoreAdminOrders: (store_id, config) =>
    get(`/api/v1/store/${store_id}/orders/`, config),
  getOrderDetails: (store_id, order_id) =>
    get(`/api/v1/store/${store_id}/orders/${order_id}/`),
  addToStore: payload => post(`/api/v1/stores/admin/store-admin/product/${payload.product}/add-to-store/${payload.store}/`),
  updateOrderStatus: (store_id, order_id, payload) =>
    patch(`/api/v1/store/${store_id}/orders/${order_id}/`, payload),
  // deleteInventoryProduct: productId =>
  //   del(`/api/v1/product-stock/${productId}/`),
  deleteInventoryProduct: productId =>
    del(`/api/v1/stores/admin/store-inventory/${productId}/`),
  updateProductStockQty: (productId, payload) =>
    patch(`/api/v1/stores/admin/store-inventory/${productId}/`, payload),
  getSuperAdminUsers: (config) =>
    get("/api/v1/users/admin/userprofile/",config),

  getStoreAdminStores: () => get("/api/v1/stores/my-store/"),

  //Manage list list

  getBrand: id => get(`/api/v1/brand/${id}/`),
  updateBrand: (id, payload) => patch(`/api/v1/brand/${id}/`, payload),
  deleteBrand: id => del(`/api/v1/brand/${id}/`),
  deleteCategory: id => del(`/api/v1/category/${id}/`),
  getVariation: config => get(`/api/v1/variation/`, config),
  getVariationValue: config => get(`/api/v1/variation-value/`, config),
  getVariationValueById: id => get(`/api/v1/variation-value/${id}/`),
  addVariationValue: payload => post(`/api/v1/variation-value/`, payload),
  updateVariationValue: (id, payload) =>
    patch(`/api/v1/variation-value/${id}/`, payload),
  deleteVariationValue: id => del(`/api/v1/variation-value/${id}/`),
  getAtrribute: () => get(`/api/v1/attribute/`),
  getAtrributeValue: config => get(`/api/v1/attribute-value/`, config),
  getAtrributeValueById: id => get(`/api/v1/attribute-value/${id}/`),
  addAttributeValue: payload => post(`/api/v1/attribute-value/`, payload),
  updateAttributeValue: (id, payload) =>
    patch(`/api/v1/attribute-value/${id}/`, payload),
  deleteAttributeValue: id => del(`/api/v1/attribute-value/${id}/`),
  // New Product cretaion
  getAllCategories: (config) => get("/api/v1/category/",config),
  fetchAttributesById: (id) => get(`/api/v1/category/${id}/`),
  fetchDDNValuesbyID: (config) => get(`/api/v1/attribute-value/`,config),
  getAllBrands: (config) => get('/api/v1/brand/',config),
  createProduct: (payload) => post('/api/v1/admin/product/', payload),
  variationValuesforDDN: (config) => get('/api/v1/variation-value/', config),
  saveProductVariation: (payload) => post('/api/v1/admin/product-variation/', payload),
  deleteProductVariation: (id) => del(`/api/v1/admin/product-variation/${id}/`),

  getProductsListingAdmin: (config) =>get('/api/v1/admin/product/',config),
  getProductsListingStoreAdmin: (config) =>get('/api/v1/admin/store-admin/product/',config),
  getProductDetailAdmin: (id) =>get(`/api/v1/admin/product/${id}/`),
  getProductVariation: (config) => get(`/api/v1/admin/product-variation/`,config),
  getProductVariationById: (id) => get(`/api/v1/admin/product-variation/${id}/`),
  deleteCurrentProduct: (id) => del(`/api/v1/admin/product/${id}/`),

  // Inventory
  getInventoryItems: (config) => get('api/v1/stores/admin/store-inventory/',config),
  // Deals
  createDeal: (payload) => post('api/v1/admin/deal/',payload),
  fetchDealHistory: (config) => get('api/v1/admin/deal/',config),
  setDealStatus: (id, payload) => patch(`api/v1/admin/deal/${id}/`,payload),
  deleteDeal: (id) => del(`api/v1/admin/deal/${id}/`),
  setStoreAdminNotifications: (storeId) => post(`api/v1/stores/stores/${storeId}/currently-working/`)
}
