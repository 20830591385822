import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { assets } from "../../utils"
import "./inputPassword.scss"

export function InputPassword({ label, register, name, placeholder, error }) {
  const [inputFocus, setInputFocus] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="admin-input-password">
      <Form.Group className="position-relative mb-3">
        <Form.Label>{label}</Form.Label>
        <img
          src={`${showPassword ? assets.EyeRegularIcon : assets.EyeClosedIcon}`}
          className="eye-Regular"
          onClick={() => {
            setShowPassword(!showPassword)
          }}
        />
        <Form.Control
          className="reset-form-input position-relative"
          type={showPassword ? "text" : "password"}
          name={name}
          placeholder={placeholder}
          {...register(name, {
            required: `${name} is required`
          })}
          aria-invalid={true ? "true" : "false"}
          onPaste={e => {
            e.preventDefault()
            return false
          }}
          onCopy={e => {
            e.preventDefault()
            return false
          }}
        />
        {error && (
          <Form.Control.Feedback className="d-block" type="invalid">
            {error?.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </div>
  )
}
