import { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { useForm } from "react-hook-form"
import { validation } from "../../utils"
import InputField from "./inputField"
import { Loader } from "./Loader"
import "./popup.scss"
import { Select } from "./VTPSelect"
import ReactFlagsSelect from "react-flags-select"
import { useState } from "react"
import { SubmitButton } from "./elements/form/submit-button"
import { APPCONFIG } from "../../app-config"

export function CreateNewUserPopup(props) {
  const { control, setValue, reset, trigger, handleSubmit } = useForm()
  const {
    isLoading,
    onClickCreateUser,
    currentUser,
    editMode,
    show,
    onClickUpdateUser,
    label,
    isFormType,
    onClickCancel,
    stores,
    ...restModalProps
  } = props
  const [selected, setSelected] = useState("")
  const [userRole, setUserRole] = useState("")
  const onSubmit = vals => {
    if (editMode) {
      return onClickUpdateUser(vals)
    } else {
      onClickCreateUser(vals)
    }

    // reset()
    // setUserRole("")
  }

  useEffect(() => {
    if (editMode) {
      setValue("name", currentUser?.user?.name)
      setValue("phone_number", currentUser.phone_number?.slice(-10))
      setValue("email", currentUser?.user?.email)
      setValue(
        "role",
        APPCONFIG.roles.find(({ value }) => currentUser.role === value)
      )
      setUserRole(
        APPCONFIG.roles.find(({ value }) => currentUser.role === value)
          ?.value
      )
      setValue("id", currentUser.id)
    }
  }, [editMode])

  useEffect(() => {
    if (!show) {
      reset()
      setUserRole("")
    }
  }, [show])

  const handleCallButton = () => {
    reset()
    onClickCancel()
  }

  return (
    <Modal
      show={show}
      {...restModalProps}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isFormType ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="justify-content-center">
              <InputField
                label="Full Name"
                name="name"
                placeholder="John Doe"
                rules={{
                  required: "Name required!",
                  validate: { ...validation.name }
                }}
                control={control}
                trigger={trigger}
                className="applicationScreenInputAdmin"
              />
              <InputField
                label="Email"
                disabled={editMode ? true : false}
                name="email"
                placeholder="name@company.com"
                rules={{
                  required: "Email required!",
                  validate: { ...validation.email }
                }}
                control={control}
                trigger={trigger}
                className="applicationScreenInputAdmin"
              />
            </Row>
            <Row className="justify-content-center" style={{ height: "75px" }}>
              <Col
                style={{ marginTop: "-17px" }}
                xs="12"
                lg="2"
                className="d-flex justify-content-center align-items-center"
              >
                <ReactFlagsSelect
                  disabled={true}
                  selected={"US"}
                  onSelect={code => {
                    setSelected(code)
                  }}
                  showOptionLabel={false}
                  showSelectedLabel={false}
                  showSecondarySelectedLabel={false}
                />
              </Col>
              <Col xs="12" lg="4">
                <InputField
                  labelClass={"phone-label"}
                  label="Phone number"
                  name="phone_number"
                  placeholder="Phone"
                  // disabled={editMode ? true : false}
                  rules={{
                    required: "Phone* required!",
                    validate: { ...validation.phone }
                  }}
                  control={control}
                  trigger={trigger}
                  className="applicationScreenInputAdmin"
                />
              </Col>

              <Col xs="12" lg="6">
                <Select
                  divStyle={{ marginBottom: "2.25rem" }}
                  name="role"
                  label="Role"
                  placeholder="Select"
                  // optionSelected={role}
                  defaultOptions={APPCONFIG.roles}
                  control={control}
                  onChange={val => {
                    setUserRole(val.value)
                  }}
                  // defaultValue={role}
                  rules={{ required: "Role type required!" }}
                />
              </Col>
            </Row>
            {userRole === "storeadmin" && (
              <Select
                divStyle={{ marginBottom: "2.25rem" }}
                name="store"
                label="Store"
                placeholder="Select"
                // optionSelected={role}
                defaultOptions={stores.map(({ name, id }) => ({
                  label: name,
                  value: id
                }))}
                control={control}
              // rules={{ required: "Store is required!" }}
              />
            )}
            <InputField
              label="Username *"
              name="id"
              placeholder="Username"
              control={control}
              trigger={trigger}
              className="applicationScreenInputAdmin d-none"
            />

            <Row className="justify-content-center">
              <Col xs="12" lg="6" className="text-center">
                <SubmitButton
                  type="button"
                  label="Cancel"
                  className="cancelButton"
                  callButton={handleCallButton}
                />
              </Col>
              <Col xs="12" lg="6" className="text-center">
                <SubmitButton
                  type="submit"
                  label={editMode ? "Save Changes" : "Create User"}
                  disabled={isLoading}
                  submitButtonStyle={{ backgroundColor: "#A0A0A0" }}
                />
              </Col>
            </Row>
          </form>
        ) : (
          <></>
        )}
        {/* <Loader isLoading={isLoading} isCentered={true} /> */}
      </Modal.Body>
    </Modal>
  )
}

const userStatusOptions = [
  { label: "Active", value: true },
  { label: "Inactive", value: false }
]
