import { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { useForm } from "react-hook-form"
import { assets, MAPBOX_TOKEN, validation } from "../../utils"
import InputField from "./inputField"
import { Loader } from "./Loader"
import "./storePopup.scss"
import Select from "react-select"
import ReactFlagsSelect from "react-flags-select"
import { useState } from "react"
import { SubmitButton } from "./elements/form/submit-button"
import { Form } from "react-bootstrap"
import { useRef } from "react"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import axios from "axios"
import { useAdminContext } from "../../contexts/AdminContext"

export function CreateNewStore(props) {
  const { control, setValue, reset, trigger, register, handleSubmit } = useForm()
  const { getSuperAdminUsers, superAdminUsers, addStoreAdminToStore } = useAdminContext()
  const {
    isLoading,
    onClickCreateStore,
    currentUser,
    editMode,
    show,
    onClickUpdateStore,
    onClickCancel,
    label,
    onHide,
    deleteStoreAdmin,
  } = props
  const [selected, setSelected] = useState("")
  const hiddenFileInput = useRef(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [selectedFile, setSelectedFile] = useState("")
  const [showLoader, setShowLoader] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedLocation, setSelectedLocation] = useState([])
  const [address, setAddress] = useState("")
  const [roles, setRoles] = useState([]);
  const [shouldTriggerUpdate, setShouldTriggerUpdate] = useState(false)
  const [locationRequiredMesage, setLocationRequiredMesage] = useState('')
  const typeAheadRef = useRef()
  const previewFile = e => {
    const reader = new FileReader()
    const selectedFile = e.target.files[0]
    setSelectedFile(selectedFile)
    if (selectedFile) {
      reader.readAsDataURL(selectedFile)
    }
    reader.onload = readerEvent => {
      if (selectedFile.type.includes("image")) {
        setImagePreview(readerEvent.target.result)
      }
    }
  }
  const handleClick = event => {
    hiddenFileInput.current.click()
  }
  const onSubmit = (vals, e) => {
    if (editMode) {
      onClickUpdateStore(vals, selectedFile, roles, selectedLocation[0], () => {
        reset()
      })
    } else {
      if (selectedLocation[0]) {
        onClickCreateStore(
          vals,
          selectedFile,
          selectedLocation[0],
          () => {
            reset()
          },
          roles
        )
      } else {
        setLocationRequiredMesage('Location Required!')
      }
    }
  }

  useEffect(()=>{
    if(selectedLocation[0]){
      setLocationRequiredMesage('')
    }
  },[selectedLocation])

  useEffect(() => {
    if (editMode) {
      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${parseFloat(
            currentUser.lng
          )},${parseFloat(currentUser.lat)}.json?access_token=${MAPBOX_TOKEN}`
        )
        .then(({ data }) => {
          setSelectedLocation(data.features)
        })
      setImagePreview(currentUser.logo)
      setSelectedFile(currentUser.logo)
      setValue("name", currentUser.name)
      setAddress(currentUser.address)
      setValue("email", currentUser.email)
      setValue("phone_number", currentUser?.phone_number?.slice(2))
      setValue("id", currentUser.id)
      setValue("review_url", currentUser.review_url)
      setValue("nicotine_tax_percent", currentUser.nicotine_tax_percent)
      setValue("sales_tax_percent", currentUser.sales_tax_percent)
    }
    return () => {
      typeAheadRef?.current?.clear()
    }
  }, [editMode])

  useEffect(() => {
    currentUser?.storeadmins &&
      currentUser.storeadmins.map((storeadmin, index) => {
        const existingRole = roles.find(
          role => role.value === storeadmin.user
        )
        if (!existingRole) {
          setRoles([
            ...roles,
            { ...storeadmin, id: index, value: storeadmin.user, label: storeadmin.username, isSaved:true }
          ])
        }
      })
  }, [roles,currentUser])

  useEffect(()=>{
    if(currentUser?.storeadmins?.length!==roles.length){
      const rolesArr = currentUser?.storeadmins?.map(role=>role.user)
      let newRoles = roles.filter(role=>rolesArr.includes(role.user))
      setRoles(newRoles)
    }
  },[currentUser])

  useEffect(() => {
    const config = {
      params: {
        role: 'STAD'
      }
    }
    getSuperAdminUsers(config)
  }, [])

  useEffect(() => {
    return () => {
      typeAheadRef?.current?.clear()
    }
  }, [])

  useEffect(() => {
    if (!show) {
      reset()
      setImagePreview(null)
    }
  }, [show])

  useEffect(() => {
    if (selectedLocation[0]) {
    }
  }, [selectedLocation])

  const handleRoleDelete = id => {
    const roleToBeDeleted = roles.find(role=>role.id===id);
    const storeId = currentUser.storeadmins.find(el=>el.user===roleToBeDeleted.value)?.id || 
    roleToBeDeleted?.storeAdminId
    if(roleToBeDeleted.hasOwnProperty('isSaved') && roleToBeDeleted.isSaved){
      deleteStoreAdmin(storeId)
      setShouldTriggerUpdate(true)
    }
    const updatedRoleFields = roles
      .filter(role => role.id !== id)
      .map((role, index) => ({
        ...role,
        id: index,
        value: role.value,
        label: role.label
      }))
    setRoles(updatedRoleFields)
  }

  const handleAddRoleField = () => {
    const newRoleField = { id: roles.length, value: 0, label: "" }
    setRoles([...roles, newRoleField])
  }

  const handleRoleSelectChange = (index, value, label, storeAdminId) => {
    const updatedRoleFields = [...roles]
    if (index >= 0 && index < updatedRoleFields.length) {
      updatedRoleFields[index].value = value
      updatedRoleFields[index].label = label
      updatedRoleFields[index].storeAdminId = storeAdminId
    }
    // save user role on dropdown change itself
    addStoreToUser(storeAdminId,updatedRoleFields.index)
  }

  const addStoreToUser = (storeAdminId, updatedRoleFields,index) => {
    const payload = {
      user: storeAdminId,
      store: currentUser.id
    }
    addStoreAdminToStore(payload).then(resp=>{
      updatedRoleFields[index]['isSaved'] = true
      setRoles(updatedRoleFields)
      setShouldTriggerUpdate(true)
      console.log('Store Added Successfully!!');

    })
  }

  return (
    <Modal
      // {...props}
      show={show}
      onHide={()=>onHide(shouldTriggerUpdate)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="justify-content-center">
            <Col lg={3}>
              <Form.Group className="mb-4 " controlId="fileUpload.ControlInput">
                <Form.Control
                  type="file"
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                  className="fileUploadField"
                  onChange={previewFile}
                />
                {imagePreview ? (
                  <div className="preview text-center">
                    {imagePreview != null && (
                      <img onClick={handleClick} src={imagePreview} alt="" />
                    )}
                  </div>
                ) : (
                  <div className="text-center">
                    <p>
                      <img
                        onClick={handleClick}
                        src={assets.UploadIcon}
                        alt="uploadPhotoId"
                      />
                    </p>
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <InputField
              label="Store Name"
              name="name"
              placeholder="John Doe"
              rules={{
                required: "Store name required!",
                validate: { ...validation.name }
              }}
              control={control}
              trigger={trigger}
              className="applicationScreenInputAdmin"
            />
            {/* <InputField
              label="Location"
              name="address"
              placeholder="Search"
              rules={{
                required: "Address required!"
              }}
              control={control}
              trigger={trigger}
              className="applicationScreenInputAdmin location"
            /> */}
            <p className="locationbox">Location</p>
            <div className="location-wrapper">
            <AsyncTypeahead
              ref={typeAheadRef}
              filterBy={() => true}
              defaultInputValue={address}
              className={`addressBar ${!!locationRequiredMesage.length?'address-bar-error':''}`}
              style={{
                height: "30px",
                marginBottom: "1.25rem",
                fontSize: "14px"
              }}
              id="mapbox-id"
              isLoading={showLoader}
              labelKey='place_name'
              onSearch={query => {
                setShowLoader(true)
                const encodedQuery = encodeURIComponent(query)
                fetch(
                  `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedQuery}.json?country=us&language=en&access_token=${MAPBOX_TOKEN}`
                )
                  .then(resp => resp.json())
                  .then(json => {
                    setOptions(json.features)
                    setShowLoader(false)
                  })
              }}
              options={options}
              renderMenuItemChildren={(option)=>option.place_name}
              onChange={setSelectedLocation}
            />
            {!!locationRequiredMesage && <span className="location-required">{locationRequiredMesage}</span>}
            </div>
          </Row>
          <Row className="justify-content-center">
            <Col xs="12" lg="6">
              <InputField
                label="Email"
                name="email"
                placeholder="name@company.com"
                rules={{
                  required: "Email required!",
                  validate: { ...validation.email }
                }}
                control={control}
                trigger={trigger}
                className="applicationScreenInputAdmin"
              />
            </Col>
            <Col
              xs="12"
              lg="2"
              className="d-flex justify-content-center align-items-center"
            >
              <ReactFlagsSelect
                className="country-flag"
                style={{ marginTop: "17px" }}
                disabled={true}
                // customLabels={}
                selected={"US"}
                onSelect={code => {
                  setSelected(code)
                }}
                showOptionLabel={false}
                showSelectedLabel={false}
                showSecondarySelectedLabel={false}
              />
            </Col>
            <Col xs="12" lg="4">
              <InputField
                labelClass={"phone-label-store"}
                label="Phone number"
                name="phone_number"
                placeholder="Phone"
                rules={{
                  required: "Phone required!",
                  validate: { ...validation.phone }
                }}
                control={control}
                trigger={trigger}
                className="applicationScreenInputAdmin"
              />
            </Col>
            <Col xs='12' lg='6'>
              <InputField
                label="Sales Tax"
                name="sales_tax_percent"
                placeholder="Sales Tax"
                rules={{
                  validate: { ...validation.number }
                }}
                control={control}
                trigger={trigger}
                className="applicationScreenInputAdmin"
              />
            </Col>
            <Col xs='12' lg='6'>
              <InputField
                label="Nicotine Tax"
                name="nicotine_tax_percent"
                placeholder="Nicotine Tax"
                rules={{
                  validate: { ...validation.number }
                }}
                control={control}
                trigger={trigger}
                className="applicationScreenInputAdmin"
              />
            </Col>
            <Col xs="12">
              <InputField
                label="Review url"
                name="review_url"
                placeholder="https://www.google.com/review"
                rules={{
                  validate: { ...validation.webSiteURL }
                }}
                control={control}
                trigger={trigger}
                className="applicationScreenInputAdmin"
              />
            </Col>
            {roles?.map((role, index) => {
              const selectId = `select-${role.id}` // add unique id for each select field
              return (
                <div key={role.id} style={{ marginBottom: "1rem" }}>
                  <Col xs="12">
                    <Row>
                      <Col xs="10">
                        <label id="aria-label" htmlFor="aria-example-input">
                          {"Store Admin"}
                        </label>
                        <Select
                          id={selectId}
                          style={{ marginBottom: "2.25rem" }}
                          name="role"
                          placeholder="Select"
                          options={superAdminUsers}
                          value={role}
                          onChange={e =>
                            handleRoleSelectChange(role.id, e.value, e.label, e.storeAdminId)
                          }
                        />
                      </Col>
                      <Col xs="2">
                        <img
                          style={{
                            width: "16px",
                            height: "15px",
                            marginTop: "1.5rem"
                          }}
                          src={assets.DeleteIcon}
                          onClick={() => handleRoleDelete(role.id)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </div>
              )
            })}
            <InputField
              label="Username *"
              name="id"
              placeholder="Username"
              control={control}
              trigger={trigger}
              className="applicationScreenInputAdmin d-none"
            />
            {editMode && <Col
              xs="12"
              className="text-start"
              style={{ color: "#CB9F47", fontWeight: 500, cursor: "pointer" }}
              onClick={() => handleAddRoleField()}
            >
              Add store admin
            </Col>}
            <Col xs="12" lg="6" className="text-center">
              <SubmitButton
                label="Cancel"
                type="button"
                callButton={()=>onClickCancel(shouldTriggerUpdate)}
                className="cancelButton"
              />
            </Col>
            <Col xs="12" lg="6" className="text-center">
              <SubmitButton
                label={editMode ? "Save Changes" : "Create Store"}
                disabled={isLoading}
              />
            </Col>
            {/* </Row> */}
          </Row>
        </form>
        {/* <Loader isLoading={isLoading} isCentered={true} /> */}
      </Modal.Body>
    </Modal>
  )
}

const userTypeOptions = [
  { label: "Admin", value: "admin" },
  { label: "Super Admin", value: "superadmin" },
  { label: "Sub Admin", value: "subadmin" },
  { label: "Customer", value: "customer" }
]
const userStatusOptions = [
  { label: "Active", value: true },
  { label: "Inactive", value: false }
]
