import React, { useEffect, useState, useMemo, useRef, useCallback } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { ALLROUTES } from "../../../adminRoutes"
import "./dashboard.scss"
import {
  TableHeader,
  Pagination,
  Search
} from "../../../components/Admin/datatable"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useAdminContext } from "../../../contexts/AdminContext"
import { Table } from "../../../components/Admin/VTPTable"
import axios from "axios"
import { assets, formatPhoneNumber, getUserID, setCountryCode, userRoleProfiles } from "../../../utils"
import { SearchInput } from "../../../components/Admin/SearchInput"
import { SubmitButton } from "../../../components/Admin/elements/form/submit-button"
import { CreateNewUserPopup } from "../../../components/Admin/CreateNewUserPopup"
import { ActionsColumn } from "./ActionsColumn"
import CancelConfirmPopup from "../../../components/Admin/CancelConfirmPopup"
import { DeletePopup } from "../../../components/Admin/deletePopup"
import { Select } from "../../../components/Admin/VTPSelect"
import { useForm } from "react-hook-form"
import VerifyIdIcon from "../../../components/VerifyIdIcon"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import { APPCONFIG } from "../../../app-config"
const Dashboard = () => {
  const nav = useNavigate()
  const {
    isLoading,
    users,//done
    getAllUsers,//done
    addUser,//done
    editUser,//done
    flagUser,//done
    deleteUser,//done
    stores,// will remain in common
    getAllStores,// will remain in common
    addStoreAdmin,//done
    editStoreAdmin,
    userPagination//done
  } = useAdminContext()

  const { control, formState, trigger, handleSubmit } = useForm()
  const [Users, setUsers] = useState(users)
  const { data, isFetching, error } = {}
  const { setContainerClass } = useOutletContext()
  const [show, setShow] = useState(false)
  const [comments, setComments] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sorting, setSorting] = useState({ field: "", order: "" })
  const [editMode, setEditMode] = useState(false)
  const [currentUser, setCurentUser] = useState({})
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [id, setId] = useState("")
  const [userStatus, setUserStatus] = useState({})
  const [userRecord, setUserRecord] = useState({})
  const [searchText, setSearchText] = useState("")
  const [isLoadMore, setIsLoadMore] = useState(0)
  const [hasMoreValues, sethasMoreValues] = useState(true)
  const [usersResponseReceived, setUsersResponseReceived] = useState(true)
  const [selectedUserRole, setSelectedUserRole] = useState("Role")
  const configObj = {
    params: {
      search: "",
      limit: 20,
      offset: 0,
      role: ""
    }
  }
  const [userConfig, setUserConfig] = useState(configObj)
  const [retainPrevious, setRetainPrevious] = useState(false)

  const ITEMS_PER_PAGE = 10

  const onClickCreateUser = payload => {
    const newPayload = {
      phone_number: payload.phone_number,
      role: payload.role.value,
      id_verified: true,
      user: {
        name: payload.name,
        email: payload.email
      }
    }
    addUser(newPayload).then(res => {
      const newUser = { ...newPayload }
      newUser.role = payload.role.value
      newUser.phone_number = res.phone_number
      if (payload.store?.value) {
        addStoreAdmin(res.id, payload.store.value).then(() => {
          setShow(false)
          setRetainPrevious(false)
          newUser.id = res.id
          newUser.flag = false
          newUser.id_verified = true
          newUser.user_photo = null
          const update = [newUser, ...Users]
          setUsers(update)
        })
      } else {
        setShow(false)
        newUser.id = res.id
        newUser.flag = false
        newUser.id_verified = true
        newUser.user_photo = null
        const update = [newUser, ...Users]
        setUsers(update)
      }
    })
  }

  const updateUser = (payload, res) => {
    const update = [...Users]
    const index = update.findIndex(({ id }) => id == payload.id)
    update[index] = { ...update[index], ...res }
    return update
  }

  const onClickUpdateUser = payload => {
    const newPayload = {
      role: payload.role.value,
      // phone_number: setCountryCode(payload.phone_number),
      user:{
        // email:payload.email,
        name:payload.name
      }
    }
    if(currentUser.phone_number!==setCountryCode(payload.phone_number)){
      newPayload.phone_number = setCountryCode(payload.phone_number)
    }
    editUser(payload.id, newPayload).then(res => {
      setShow(false)
      setEditMode(false)
      if (payload.store) {
        addStoreAdmin(res.id, payload.store.value).then(() => {
          setShow(false)
          setRetainPrevious(false)
          setUsers(updateUser(payload, res))
        })
      } else {
        setShow(false)
        setUsers(updateUser(payload, res))
      }
    })
  }

  const onClickEditUser = record => {
    setCurentUser(record)
    setEditMode(true)
    setShow(true)
  }

  const onClickActiveInactiveUser = (id, payload) => {
    setId(id)
    setUserStatus(payload)
    setShowStatusPopup(true)
  }

  const onClickDeleteUser = record => {
    setUserRecord(record)
    setShowDeletePopup(true)
  }

  const onChangeUserStatus = (id1, payload1) => {
    // using below API for any changes to user record
    const [id2, payload] = id1 && payload1 ? [id1, payload1] : [id, userStatus]
    flagUser(id2, payload).then((res) => {
      setShowStatusPopup(false)
      setId("")
      setUserStatus({})
      const config = { ...userConfig }
      config.params.limit = Users.count
      const update = [...Users]
      const index = update.findIndex(({ id }) => id == id2)
      if (payload.hasOwnProperty("flag")) {
        update[index].flag = res.flag
      } else if (payload.hasOwnProperty("id_verified")) {
        update[index].id_verified = res.id_verified
      }
      setUsers(update)
    })
  }

  const onDeleteUser = () => {
    deleteUser(userRecord.id).then(() => {
      setShowDeletePopup(false)
      setUsers(Users.filter(({ id }) => id != userRecord.id))
    })
  }

  const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  useEffect(() => {
    // const config = {
    //   params: {
    //     search: searchText.toLowerCase()
    //   }
    // }
    setUsersResponseReceived(false)
    getAllUsers(userConfig, retainPrevious).then(res => {
      setUsersResponseReceived(true)
      if (userPagination.next) {
        sethasMoreValues(true)
      }
      setRetainPrevious(false)
      setUsers(res)
    })
  }, [userConfig])

  const handleApprove = (id, payload) => {
    setId(id)
    setUserStatus(payload)
    onChangeUserStatus(id, payload)
    // setTimeout(()=>{
    //   onChangeUserStatus()
    // },0)
  }

  const cols = [
    {
      accessor: "user.name",
      Header: "Name"
    },
    {
      accessor: "user.email",
      Header: "Email"
    },
    {
      accessor: "phone_number",
      Header: "Phone Number",
      Cell: ({row}) => formatPhoneNumber(row.original.phone_number),
      disableSortBy: true
    },
    {
      accessor: "",
      Header: "Verified ID",
      disableSortBy: true,
      Cell: ({ row }) => <VerifyIdIcon {...row} approveUser={handleApprove} />
    },
    {
      accessor: "full_role_name",
      Header: "Role"
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ row }) => (
        <ActionsColumn
          record={row.original}
          isFlagOption={true}
          isEditOption={true}
          onClickEditUser={onClickEditUser}
          onClickActiveInactiveUser={onClickActiveInactiveUser}
          onClickDeleteUser={onClickDeleteUser}
        />
      )
    }
  ]

  const columns = useMemo(() => cols, [users])

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
      computedComments = computedComments.filter(
        comment =>
          comment.name.toLowerCase().includes(search.toLowerCase()) ||
          comment.email.toLowerCase().includes(search.toLowerCase())
      )
    }

    setTotalItems(computedComments.length)

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1
      computedComments = computedComments.sort((a, b) => {
        return reversed * a[sorting.field].localeCompare(b[sorting.field])
      })
    }

    //Current Page slice
    return computedComments?.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    )
  }, [comments, currentPage, search, sorting])

  useEffect(() => {
    getAllStores()
    setContainerClass("dashboard-main")
    return () => setContainerClass("")
  }, [])

  useEffect(() => {
    if (error?.response.status == 404) {
      nav(ALLROUTES.dashboardChildren.profile)
    }
  }, [error])

  const onChangeText = debounce(text => {
    // setSearchText(text)
    const config = { ...userConfig }
    config.params.search = text.toLowerCase()
    config.params.limit = ""
    config.params.offset = ""
    setRetainPrevious(false)
    setUserConfig(config)
  }, 300)

  const handleLoadMore = () => {
    sethasMoreValues(false)
    if (userPagination.next && usersResponseReceived) {
      const config = { ...userConfig }
      config.params.offset = userPagination.offset
      config.params.limit = userPagination.limit
      setRetainPrevious(true)
      setUserConfig(config)
      // const loadMoreParams = userPagination.next || ''
      sethasMoreValues(true)
      //sending empty config object to reuse the getAllUsers function
      // getAllUsers({}, loadMoreParams).then(res => {
      //   setUsers(res);
      // })
    } else {
      sethasMoreValues(false)
    }
  }

  const handleRoleChange = e => {
    const { value } = e.target
    if (value === "role") {
      /** quick work around need to fix */
      // window.location.reload()
    }
    const config = {
      ...userConfig,
      params: { ...userConfig.params, limit: 20, offset: 0 }
    }
    config.params.role = value === "role" ? "" : value
    const role =
      value === "role"
        ? "Role"
        : value === userRoleProfiles.SUPERADMIN
        ? "Super Admin"
        : value === userRoleProfiles.STOREADMIN
        ? "Store Admin"
        : "Customer"
    setSelectedUserRole(role)
    setUserConfig(config)
  }



  return (
    <>
      <header className="head gradient-bg d-flex justify-content-center flex-column  position-relative py-3">
        <div className="d-flex justify-content-between w-100 align-items-center px-4">
          <div className="hello-user-card d-flex align-items-center justify-content-start">
            <span>
              <h3>Users</h3>
            </span>
          </div>
        </div>
        <div
          style={{ height: "50px", marginBottom: "10px" }}
          className="d-flex align-items-center px-4"
        >
          <span className="filter-container">
            <SearchInput onChangeText={onChangeText} />
          </span>
          <Col lg="2" className="ms-5">
            <Form.Select
              onChange={handleRoleChange}
              style={{ cursor: "pointer" }}
            >
              <option value="role">Role</option>
              {APPCONFIG.roles.map((role, i) => (
                <option value={role.value} key={i}>
                  {role.label}
                </option>
              ))}
            </Form.Select>
          </Col>

          <Button
            className="createIcon ms-auto"
            onClick={() => {
              const record = {
                email: "",
                id: "",
                name: "",
                flag: false,
                user_phone_number: "",
                user_photo: "",
                role: ""
              }
              setCurentUser(record)
              setUserRecord(record)
              setShow(true)
            }}
            style={{ fontSize: "14px" }}
          >
            <img src={assets.PlusIcon} />
            Create User
          </Button>
        </div>
        <div className="px-3">
          <Table
            columns={columns}
            data={Users}
            isLoading={isLoading}
            height="73vh"
            isPagination={true}
            loadMoreHandler={handleLoadMore}
            hasMorevalues={hasMoreValues}
            initialSortKey="email"
          />
        </div>
        <CreateNewUserPopup
          label={editMode ? "Edit User" : "Create a new user"}
          isFormType={"true"}
          currentUser={currentUser}
          editMode={editMode}
          show={show}
          onHide={() => {
            setShow(false)
            setEditMode(false)
          }}
          onClickCreateUser={onClickCreateUser}
          onClickCancel={() => {
            setShow(false)
            setEditMode(false)
            const record = {
              email: "",
              id: "",
              name: "",
              flag: false,
              user_phone_number: "",
              user_photo: "",
              role: ""
            }
            setCurentUser(record)
            setUserRecord(record)
          }}
          isLoading={isLoading}
          stores={stores}
          onClickUpdateUser={onClickUpdateUser}
        />
        <CancelConfirmPopup
          label={`Are you sure want to ${
            userStatus?.flag ? "flag" : "unflag"
          } the user?`}
          showTitle={false}
          show={showStatusPopup}
          setShow={setShowStatusPopup}
          onClickCancel={onChangeUserStatus}
        />
        <DeletePopup
          show={showDeletePopup}
          setShow={setShowDeletePopup}
          label={"Delete User"}
          currentRecord={userRecord}
          isLoading={isLoading}
          onClickCancel={onDeleteUser}
          featureType="deleteUser"
          heading={"Are you sure you want to delete user?"}
        />
      </header>
    </>
  )
}

const userTypeOptions = [
  { label: "Store Admin", value: "storeadmin" },
  { label: "Super Admin", value: "superadmin" },
  { label: "Sub Admin", value: "subadmin" },
  { label: "Customer", value: "customer" }
]

export default Dashboard
