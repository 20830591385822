import React, { useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import Card from "../../../components/Admin/containers/card"
import { useForm } from "react-hook-form"
import { useAuthContext } from "../../../contexts/AuthContext"
import CenteredContainer, {
  OneHalf
} from "../../../components/Admin/containers/centered-container"
import InputField from "../../../components/Admin/inputField"
import {
  getPayLoadDataFunction,
  LocalStorage,
  validation
} from "../../../utils"
import "./resetPassword.scss"
import { SubmitButton } from "../../../components/Admin/elements/form/submit-button"
import { Button, Col, Row } from "react-bootstrap"

const ResetPasswordScreen = () => {
  const nav = useNavigate()
  const { isLoading, forgotPassword } = useAuthContext()
  const { control, register, formState, trigger, handleSubmit } = useForm()

  const onSubmit = async vals => {
    forgotPassword(vals).then(() =>
      nav("/admin/forgot-password-verify", {
        state: vals
      })
    )
  }

  return (
    <CenteredContainer screenHeight={true} className="gradient-bg">
      <Card whiteBg={false}>
        <h3 className="text-center">Reset Your Password</h3>
        <p className="text-center mb-4">
          We’ll email you a token to reset your password
        </p>
        <form className={"login"} onSubmit={handleSubmit(onSubmit)}>
          {/* <Row className="justify-content-center"> */}
          {/* <Col xs={12}> */}
          <InputField
            label="Email"
            name="email"
            placeholder="name@company.com"
            rules={{
              required: "Email required!",
              validate: { ...validation.email }
            }}
            compType=""
            control={control}
            trigger={trigger}
            className="applicationScreenInputAdmin"
          />
          <Row className="reset-password-btns mt-5">
            <Col>
              <Button
                className="cancel"
                variant="outline-dark"
                onClick={() => nav("/admin/login")}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className="send" variant="dark" type="submit">
                Send
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
    </CenteredContainer>
  )
}

export default ResetPasswordScreen
