import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { convertDateTtime } from "../../../../utils";
require("jspdf-autotable");
const orderColums = [
    'Brand',
    'Product Name',
    'Flavor',
    'Nicotine',
    'Volume',
    'Category',
    'Price',
    'Quantity',
    'Discount',
    'Total'
];
// orderList.brand = orderList.brand.brand
// const orderData = Object.values(orderList) 

export const printInvoice = ({orderList, customer, order}) => {
    const orderData = orderList.map(el=>{
        el.brand = el.brand.brand
        return Object.values(el)
    })
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.setFont("times","bold");
    // pdf.setFontType("bold");
    pdf.setTextColor(203, 159, 71);
    pdf.text(
        105,
        10,
        "VAPE SMOKE UNIVERSE",
        null,
        null,
        "center"
    );
    pdf.setTextColor(0, 0, 0);
    pdf.text("Invoice", 105, 20, 'center');
    // billing details
    pdf.text("Billing Address:",10,40,'left');
    pdf.setFontSize(12)
    pdf.text(customer.name,10,45,'left');
    pdf.text((customer.address||'address not available'),10,50,'left');
    pdf.text((customer.phone_number||'phone not available'),10,55,'left');
    pdf.setFontSize(16)
    // order details
    pdf.text("Order Details:",110,40,'left');
    pdf.setFontSize(10)
    pdf.text(`Order ID ${order.suborder_id}`,110,45,'left');
    pdf.text(`Order Date ${convertDateTtime(order.created_on)}`,110,50,'left');
    pdf.setFontSize(16)
    pdf.line(10,60,195,60)
    pdf.text("Order Summary:",10,70,'left');
    pdf.autoTable({
        html:'#order-list',
        headStyles:{
            valign: 'middle',
            halign : 'center'
          },
        startY:80,
    });
    let finalY = pdf.previousAutoTable.finalY;
    pdf.line(10,finalY+5,195,finalY+5)

    pdf.setFontSize(11)
    pdf.text(`Nicotine Tax:`,180,finalY+12,'right')
    pdf.setFontSize(12)
    pdf.text(`$${order.total_nicotine_tax}`,195,finalY+12,'right')

    pdf.setFontSize(11)
    pdf.text(`Sales tax:`,180,finalY+20,'right')
    pdf.setFontSize(12)
    pdf.text(`$${order.total_sales_tax}`,195,finalY+20,'right')

    pdf.setFontSize(11)
    pdf.text(`Discount:`,180,finalY+28,'right')
    pdf.setFontSize(12)
    pdf.text(`$${order.total_discount}`,195,finalY+28,'right')

    pdf.setFontSize(14)
    pdf.text(`Order Total:`,180,finalY+36,'right')
    pdf.setFontSize(13)
    pdf.text(`$${order.total_price+order.total_nicotine_tax+order.total_sales_tax-order.total_discount}`,195,finalY+36,'right')
    pdf.line(10,finalY+40,195,finalY+40)
    pdf.setProperties({ title: 'Invoice' })
    pdf.output('dataurlnewwindow');
    return {dataUri:pdf.output('datauristring')}
}