import React from "react"
import "./card.scss"

const Card = ({ whiteBg, className = "", ...props }) => {
  return (
    <div
      className={`vape-card mx-auto px-5 ${
        whiteBg ? "white-bg" : ""
      } ${className}`}
    >
      {props.children}
    </div>
  )
}

export default Card
