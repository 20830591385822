import React, { useState } from 'react';
import { API } from '../../api';
import { useAuthContext } from '../AuthContext';

export default function Orders() {
    const [storeAdminOrders, setStoreAdminOrders] = useState([]);
    const [orderDetails, setOrderDetails] = useState({});
    const { setIsLoading } = useAuthContext();
    const getStoreAdminOrders = (store_id, config) => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
            API.getStoreAdminOrders(store_id, config).then(response => {
                setStoreAdminOrders(response.data.payload)
                resolve(response.data.payload)
            })
        })
            .catch(error => console.error(error))
            .finally(() => setIsLoading(false))
    }

    const getOrderDetails = (store_id, order_id) => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
            API.getOrderDetails(store_id, order_id)
                .then(response => {
                    setOrderDetails(response.data.payload);
                    resolve(response.data.payload)
                })
        })
            .catch(error => console.error(error))
            .finally(() => setIsLoading(false))
    }

    const updateOrderStatus = (store_id, order_id, payload) => {
        setIsLoading(true)
        return new Promise((resolve, reject) => {
            API.updateOrderStatus(store_id, order_id, payload)
                .then(response => {
                    resolve(response.data.payload)
                })
        })
            .catch(error => console.error(error))
            .finally(() => setIsLoading(false))
    }

    const orders = { storeAdminOrders, orderDetails, getStoreAdminOrders, getOrderDetails, updateOrderStatus }
    return orders;
}