import React, { useEffect, useState, useMemo } from "react"
import { useNavigate, useOutletContext, Link } from "react-router-dom"
import { ALLROUTES } from "../../../adminRoutes"
import "./stores.scss"
import {
  TableHeader,
  Pagination,
  Search
} from "../../../components/Admin/datatable"
import { Button } from "react-bootstrap"
import { useAdminContext } from "../../../contexts/AdminContext"
import { Table } from "../../../components/Admin/VTPTable"
import axios from "axios"
import { SearchInput } from "../../../components/Admin/SearchInput"
import { assets, formatPhoneNumber } from "../../../utils"
import { CreateNewStore } from "../../../components/Admin/CreateNewStore"
import { ActionsColumn } from "../dashboard/ActionsColumn"
import { DeletePopup } from "../../../components/Admin/deletePopup"
const Stores = () => {
  const nav = useNavigate()
  const {
    isLoading,
    stores,//common
    getAllStores,//common
    addStore,//done
    editStore,//done
    deleteStore,//done
    storePagination,
    addStoreAdmin, //present in usersContext
    getAllUsers, //present in usersContext
    addStoreAdminToStore,
    deleteStoreAdminFromStore
  } = useAdminContext()
  const { data, isFetching, error } = {}
  const { setContainerClass } = useOutletContext()
  const [show, setShow] = useState(false)
  const [comments, setComments] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sorting, setSorting] = useState({ field: "", order: "" })
  const [searchText, setSearchText] = useState("")
  const [currentUser, setCurentUser] = useState({})
  const [editMode, setEditMode] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [userRecord, setUserRecord] = useState({})
  const ITEMS_PER_PAGE = 10

  const onClickCreateStore = (payload, val, location, successCallback, roles) => {
    addStore(payload, val, location).then((res) => {
      if (roles && roles.length) {
        roles.map((role) => {
          if(role.value) {
             addStoreAdmin(role.value, res.id).then(() => {
          setShow(false)
          getAllUsers();
        })
          }
        })
      } else {
      successCallback?.()
      setShow(false)
      getAllStores()
      }
    })
  }
  const onClickEditUser = record => {
    setCurentUser(record)
    setEditMode(true)
    setShow(true)
  }

  const onClickUpdateStore = (payload, val, roles, location, successCallback) => {
    editStore(payload, val, location).then(() => {
      successCallback?.()
      setShow(false)
      getAllStores()
      setEditMode(false)
    })
  }

  const onClickDeleteUser = record => {
    setUserRecord(record)
    setShowDeletePopup(true)
  }
  const onDeleteUser = () => {
    deleteStore(userRecord.id).then(() => {
      setShowDeletePopup(false)
      setUserRecord({})
      getAllStores()
    })
  }

  useEffect(() => {
    const config = {
      params: {
        search: searchText.toLowerCase()
      }
    }
    getAllStores(config)
  }, [searchText])

  const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        timeout = null
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const onChangeText = debounce(text => {
    setSearchText(text)
  }, 300)

  const cols = [
    {
      Header: "Name",
      Cell: tableProps => (
        <div className="d-flex align-items-center">
          <img className="store-logo" src={tableProps.row.original.logo} />
          <p className="mb-0" style={{ textAlign: "left" }}>
            {tableProps.row.original.name}
          </p>
        </div>
      )
    },
    {
      accessor: "address",
      Header: "Location",
      Cell: ({row}) => row.original.address.replace(', United States','')
    },
    {
      accessor: "phone_number",
      Header: "Phone Number",
      Cell: ({row}) => formatPhoneNumber(row.original.phone_number)
    },
    {
      accessor: "storeadmins",
      Header: "Store Admin",
      Cell: ({row}) => row.original.storeadmins.map(el=>el.username).join(', ')
    },
    {
      accessor: "email",
      Header: "Email"
    },
    {
      Header: "Actions",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ row }) => (
        <ActionsColumn
          record={row.original}
          showStoreAdmins={true}
          isFlagOption={false}
          isEditOption={true}
          onClickEditUser={onClickEditUser}
          onClickDeleteUser={onClickDeleteUser}
        />
      )
    }
  ]

  const columns = useMemo(() => cols, [])

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
      computedComments = computedComments.filter(
        comment =>
          comment.name.toLowerCase().includes(search.toLowerCase()) ||
          comment.email.toLowerCase().includes(search.toLowerCase())
      )
    }

    setTotalItems(computedComments.length)

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      )
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    )
  }, [comments, currentPage, search, sorting])

  useEffect(() => {
    setContainerClass("dashboard-main")
    return () => setContainerClass("")
  }, [])

  useEffect(() => {
    if (error?.response.status == 404) {
      nav(ALLROUTES.dashboardChildren.profile)
    }
  }, [error])

  const deleteStoreAdmin = (storeId) => {
    deleteStoreAdminFromStore(storeId).then(resp=>{
      const user = {...currentUser}
      user.storeadmins = user.storeadmins.filter(el=>el.id!==storeId)
      setCurentUser(user)
    })
  }

  return (
    <>
      <header className="head gradient-bg d-flex justify-content-center flex-column position-relative py-3">
        <div className="d-flex justify-content-between w-100 align-items-center px-4">
          <div className="hello-user-card d-flex align-items-center justify-content-start">
            <span className="me-3">
              <h3>Stores</h3>
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center px-4">
          <SearchInput onChangeText={onChangeText} />
          <Button
            onClick={() => {
              setShow(true)
              setCurentUser({})
            }}
            className="createIcon"
            style={{ fontSize: "14px" }}
          >
            <img src={assets.PlusIcon} />
            Create Store
          </Button>
        </div>
        <div className="px-3 has-stores-table">
          <Table
            columns={columns}
            data={stores}
            isLoading={isLoading}
            // onRowClick={onRowClickHandle}
          />
        </div>
        {show && (
          <CreateNewStore
            label={editMode ? "Edit Store" : "Create a new store"}
            currentUser={currentUser}
            editMode={editMode}
            show={show}
            onHide={(shouldUpdate) => {
              setShow(false)
              setEditMode(false)
              if(shouldUpdate){
                getAllStores()
              }
            }}
            onClickCreateStore={onClickCreateStore}
            isLoading={isLoading}
            onClickCancel={(shouldUpdate) => {
              setShow(false)
              if(shouldUpdate){
                getAllStores()
              }
            }}
            onClickUpdateStore={onClickUpdateStore}
            deleteStoreAdmin={deleteStoreAdmin}
          />
        )}
        <DeletePopup
          show={showDeletePopup}
          setShow={setShowDeletePopup}
          label={"Delete Store"}
          currentRecord={userRecord}
          isLoading={isLoading}
          onClickCancel={onDeleteUser}
          featureType="deleteStore"
          heading={"Are you sure you want to delete store?"}
        />
      </header>
    </>
  )
}

export default Stores
