import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import "./inventory.scss"

function InputAction({ row, value, inputChange, isLoading }) {
  const [inputValue, setInputValue] = useState(Number(value))
  const [changeVal, setChangeVal] = useState(false)
  const initialLoadRef = useRef(true)
  useEffect(() => {
    if (!initialLoadRef.current) {
      inputChange(row, inputValue, changeVal)
    }
  }, [inputValue])
  useEffect(()=>{
    setInputValue(value)
  },[value])

  const handleInput = e => {
    setInputValue(e.target.value)
    if (e.target.value !== value) {
      setChangeVal(true)
    } else {
      setChangeVal(false)
    }
    initialLoadRef.current = false
  }
  const handleIncDec = val => {
    if (val === "inc") {
      setInputValue(inputValue + 1)
    } else {
      if (inputValue === 0) {
        return
      }
      setInputValue(inputValue - 1)
    }
    if (value !== inputValue) {
      setChangeVal(true)
    } else {
      setChangeVal(false)
    }
    initialLoadRef.current = false
  }
  return (
    <div className="stock-qty-actions">
      {isLoading ? (
        <span style={{ fontSize: "0.75rem", margin: "auto" }}>loading...</span>
      ) : (
        <>
          <div className="dec-icon" onClick={() => handleIncDec("dec")}>
            -
          </div>
          <div className="input-box">
            <input
              value={inputValue}
              onChange={e => handleInput(e)}
              type="number"
            />
          </div>
          <div className="inc-icon" onClick={() => handleIncDec("inc")}>
            +
          </div>
        </>
      )}
    </div>
  )
}

export default React.memo(InputAction)
