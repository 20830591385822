import React, { useMemo, useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Table } from "../../../components/Admin/VTPTable";
import Modal from "react-bootstrap/Modal";
import { cols } from './orderConstants';
import './orders.scss';
import { useAdminContext } from '../../../contexts/AdminContext';
import { printInvoice } from './pdfs/invoice';
import { assets } from '../../../utils';

export default function OrderPopup({refreshorderdetail, ...props}) {
    const {
        label,
        store_id,
        order_id,
        onHide
    } = props;
    const columnData = cols
    const { isLoading, getOrderDetails, orderDetails, updateOrderStatus, getProductVariationById } = useAdminContext()
    const [selectedOrder, setSelectedOrder] = useState([]);
    const [finalOrderList, setFinalOrderList] = useState([]);
    const [customer, setCustomer] = useState({});
    const [orderDetailsResp, setOrderDetailsResp] = useState({});
    const [isInvoiceGenerated, setIsInvoiceGenerated] = useState(false);
    const [invoiceDataUri, setInvoiceDataUri] = useState(null);
    const [taxInformation, setTaxInformation] = useState({});
    const [variationData, setVariationData] = useState({});
    const columns = useMemo(() => columnData, []);
    const rowData = useMemo(() => finalOrderList, [finalOrderList])

    const generateTableData = (orders) => {
        getVariationData(orders)
        let totalSalesTax = 0
        let totalNicotineTax = 0
        let discount = 0
        let result = []
        orders?.forEach(order => {
            totalSalesTax += Number((order?.product_detail?.total_sales_tax))
            totalNicotineTax += Number((order?.product_detail?.total_nicotine_tax))
            const obj = {};
            obj.id = order.id
            obj.brand = {
                brand: order?.product_detail?.productstock?.variation?.product?.brand?.name,
                image: order?.product_detail?.images[0] || ''
            };
            obj.product_name = order?.product_detail?.productstock?.variation?.product?.name;
            obj.flavor = order?.product_detail?.productstock?.flavour?.name;
            obj.nicotine = order?.product_detail?.productstock?.nicotine?.nicotine || '--';
            obj.volume = order?.product_detail?.productstock?.variation?.product?.milligram?.gram || '--';
            obj.category = order?.product_detail?.productstock?.variation?.product?.category?.name;
            obj.price = order?.item_price;
            obj.quantity = order?.quantity;
            obj.discount = order.discount;
            obj.total = order?.total_price;
            result.push(obj)
        });
        totalSalesTax = Number(totalSalesTax.toFixed(2))
        totalNicotineTax = Number(totalNicotineTax.toFixed(2))
        setTaxInformation({ totalSalesTax, totalNicotineTax })
        setSelectedOrder(result)
    }

    const getVariationData = async (orders) => {
        let obj = {}
        for (let order of orders) {
            let id = order?.product_detail?.productstock?.variation.id
            const resp = await getProductVariationById(id)
            let data = []
            resp.productvariations.map(el => {
                if (el.variationvalue.value !== "DEFAULT") {
                    data.push(el.variationvalue.value)
                }
            })
            obj[order.id] = data.join(' - ')
        }
        setVariationData(obj)
    }

    useEffect(() => {
        if (selectedOrder.length && Object.keys(variationData).length) {
            let newOrderList = selectedOrder.map(el => {
                const obj = { ...el }
                obj.details = variationData[el.id]
                return obj
            })
            setFinalOrderList(newOrderList)
        }
    }, [variationData, selectedOrder])

    useEffect(() => {
        getOrderDetails(store_id, order_id).then(res => {
            generateTableData(res.order_items);
            setCustomer(res.customer);
            setOrderDetailsResp(res.order)
        })
    }, []);

    const generateInvoice = () => {
        const { dataUri } = printInvoice({ order: orderDetailsResp, customer: customer, orderList: selectedOrder });
        setIsInvoiceGenerated(true)
        setInvoiceDataUri(dataUri)
        // console.log('pdf data URI',dataUri)
    }
    const generateReceipt = () => {
        if (orderDetailsResp.status !== 'CO') {
            return
        }
    }
    const readyForPickup = () => {
        if (orderDetailsResp.status !== 'PD') {
            return
        }
        const payload = {
            status: 'RP'
        }
        updateOrderStatus(store_id, order_id, payload).then(res => {
            if (res && res.order && res.order.status === 'RP') {
                refreshorderdetail(res.order)
                setTimeout(()=>{
                    onHide();
                },100)
            }
        })
    }
    const sendInvoiceToEmail = () => {
        if (!isInvoiceGenerated) {
            return
        }
        console.log('sending...')
    }
    return (
        <Modal {...props}
            centered
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='order-popup-body'>
                    <Row className='client-info'>
                        <Col lg="9">
                            <div className='client-heading'>
                                Client
                            </div>
                            <div className='customer-name'>
                                {customer.name}
                            </div>
                            <div className='customer-address'>
                                {customer.address}
                            </div>
                        </Col>
                        <Col className='invoice-btn-container'>
                            <div className='order-popup-invoice-btn'>
                                <Button variant='outline-dark' onClick={generateInvoice}>Generate invoice</Button>
                                <img className={!isInvoiceGenerated ? 'send-disable' : ''} onClick={sendInvoiceToEmail} src={assets.emailIcon} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='products-heading'>
                        <div>
                            Products
                        </div>
                    </Row>
                    <Row className='orders-table'>
                        <Table
                            id='order-list'
                            columns={columns}
                            data={rowData}
                            isLoading={isLoading}
                            height="100%"
                            customClass='custom-order-table'
                        />
                        <div className='show-total'>
                            <div>
                                <span>Sales Tax</span>
                                <span>{`$${taxInformation.totalSalesTax}`}</span>
                            </div>
                        </div>
                        <div className='show-total'>
                            <div>
                                <span>Nicotine Tax</span>
                                <span>{`$${taxInformation.totalNicotineTax}`}</span>
                            </div>
                        </div>
                        <div className='show-total'>
                            <div>
                                <span>Discount</span>
                                <span>{`$${orderDetailsResp.total_discount}`}</span>
                            </div>
                        </div>
                        {orderDetailsResp.total_price && <div className='show-total'>
                            <div>
                                <span>Total</span>
                                <span>{`$${(orderDetailsResp.total_price +
                                    taxInformation.totalSalesTax + taxInformation.totalNicotineTax -
                                    orderDetailsResp.total_discount).toFixed(2)}`}</span>
                            </div>
                        </div>}
                    </Row>
                    <Row className='order-notes-section'>
                        <div className='notes-heading'>
                            Notes
                        </div>
                        <div className='input-box'>
                            <textarea type='text' className='notes-input' placeholder='Write here any note for your customer' />
                        </div>
                    </Row>
                    <Row className='order-action-buttons'>
                        <div>
                            <Button variant='outline-dark' disabled={orderDetailsResp?.status !== 'CO'}
                                onClick={generateReceipt} className='print-receipt'>Print receipt</Button>
                            <Button variant='outline-dark' disabled={orderDetailsResp?.status !== 'PD'}
                                onClick={readyForPickup} className='order-pickup'>Order ready for pick up</Button>
                        </div>

                    </Row>
                </div>
            </Modal.Body>

        </Modal>
    )
}
